import React from 'react'
import RightArrow from "../images/dashboard/outline.png"
import Aeroplane from "../images/dashboard/aeroplane.png"
import './index.css'


function DashboardGetting({onStand}) {

  return (
    <div className='flex flex-col lg:flex-row  mx-auto relative z-[999] h-full'>
        <div className="mt-6 lg:mt-12 w-full mx-auto lg:w-[50%] mb-10 lg:mb-0">
            <div className='flex cursor-pointer' onClick={onStand}>
                <img src={RightArrow} className='w-4 rotate-180' />
                <p className="text-green-500 text-sm font-semibold PoppinsRegular pl-2">Back</p>
            </div>
            <div className=''>
                <h2 className='PoppinsRegular pt-2 lg:pt-5 text-white text-2xl lg:text-4xl'>
                    Getting started
                </h2>
            </div>
            <div className='bg-texturevertical myshades2 rounded-[17px] mt-6'>
                <details className='w-full bg-[#17252D]/90 [&_svg]:open:-rotate-180 rounded-2xl mb-2'>
                    <summary className='list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
                        <p className='PoppinsRegular text-white text-base lg:text-lg'>
                            What is Bitvium?
                        </p>
                        <div>
                            <svg
                                className='rotate-0 transform text-white transition-all duration-300'
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                                fill='currentColor'
                            >
                                <path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
                            </svg>
                        </div>
                    </summary>
                    <p className='text-white/60 PoppinsRegular text-sm lg:text-base px-3 py-3 xl:px-8'>
                        We’re excited to have you join! Your account will be created automatically when you make your first purchase, sale, or swap order with us, just visit us
                    </p>
                </details>
            </div>
            <div className='bg-texturevertical myshades2 rounded-[17px] mt-4'>
                <details className='w-full bg-[#17252D]/90 [&_svg]:open:-rotate-180 rounded-2xl mb-2'>
                    <summary className='list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
                        <p className='PoppinsRegular text-white text-base lg:text-lg'>
                            Which cryptocurrencies do you support?
                        </p>
                        <div>
                            <svg
                                className='rotate-0 transform text-white transition-all duration-300'
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                                fill='currentColor'
                            >
                                <path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
                            </svg>
                        </div>
                    </summary>
                    <p className='text-white/60 PoppinsRegular text-sm px-3 lg:text-base xl:px-8 py-3'>
                        We’re excited to have you join! Your account will be created automatically when you make your first purchase, sale, or swap order with us, just visit us
                    </p>
                </details>
            </div>
            <div className='bg-texturevertical myshades2 rounded-[17px] mt-4'>
                <details className='w-full bg-[#17252D]/90 [&_svg]:open:-rotate-180 rounded-2xl mb-2'>
                    <summary className='list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
                        <p className='PoppinsRegular text-white text-base lg:text-lg'>
                            How can I open an account with Bitvium?
                        </p>
                        <div>
                            <svg
                                className='rotate-0 transform text-white transition-all duration-300'
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                                fill='currentColor'
                            >
                                <path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
                            </svg>
                        </div>
                    </summary>
                    <p className='text-white/60 PoppinsRegular text-base xl:px-8 px-3 py-3'>
                        We’re excited to have you join! Your account will be created automatically when you make your first purchase, sale, or swap order with us, just visit us
                    </p>
                </details>
            </div>
            <div className='bg-texturevertical myshades2 rounded-[17px] mt-4'>
                <details className='w-full bg-[#17252D]/90 [&_svg]:open:-rotate-180 rounded-2xl mb-2'>
                    <summary className='list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
                        <p className='PoppinsRegular text-white text-base lg:text-lg'>
                            How can I get a wallet address?
                        </p>
                        <div>
                            <svg
                                className='rotate-0 transform text-white transition-all duration-300'
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                                fill='currentColor'
                            >
                                <path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
                            </svg>
                        </div>
                    </summary>
                    <p className='text-white/60 PoppinsRegular text-sm lg:text-base xl:px-8 px-3 py-3'>
                        We’re excited to have you join! Your account will be created automatically when you make your first purchase, sale, or swap order with us, just visit us
                    </p>
                </details>
            </div>
            <div className='bg-texturevertical myshades2 rounded-[17px] mt-4'>
                <details className='w-full bg-[#17252D]/90 [&_svg]:open:-rotate-180 rounded-2xl mb-2'>
                    <summary className='list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
                        <p className='PoppinsRegular text-white text-base lg:text-lg'>
                            What age do I need to be to use Bitvium?
                        </p>
                        <div>
                            <svg
                                className='rotate-0 transform text-white transition-all duration-300'
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                                fill='currentColor'
                            >
                                <path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
                            </svg>
                        </div>
                    </summary>
                    <p className='text-white/60 PoppinsRegular text-sm lg:text-base xl:px-8 px-3 py-3'>
                        We’re excited to have you join! Your account will be created automatically when you make your first purchase, sale, or swap order with us, just visit us
                    </p>
                </details>
            </div>
            <div className='bg-texturevertical myshades2 rounded-[17px] mt-4'>
                <details className='w-full bg-[#17252D]/90 [&_svg]:open:-rotate-180 rounded-2xl mb-2'>
                    <summary className='list-none flex justify-between items-center xl:px-8 xl:py-3 p-3 rounded-md cursor-pointer'>
                        <p className='PoppinsRegular text-white text-base lg:text-lg'>
                            What are your business hours?
                        </p>
                        <div>
                            <svg
                                className='rotate-0 transform text-white transition-all duration-300'
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                                fill='currentColor'
                            >
                                <path d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' />
                            </svg>
                        </div>
                    </summary>
                    <p className='text-white/60 PoppinsRegular text-sm lg:text-base xl:px-8 px-3 py-3'>
                        We’re excited to have you join! Your account will be created automatically when you make your first purchase, sale, or swap order with us, just visit us
                    </p>
                </details>
            </div>
		</div>
        <div className='w-[50%] hidden h-full lg:flex justify-end'>
            <img src={Aeroplane} className='w-full h-full mt-16' />
        </div>
    </div>
  );
}

export default DashboardGetting;