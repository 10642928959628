import React, {useEffect, useRef, useState} from 'react'
import './index.css'

import {  createUserWithEmailAndPassword , getAuth} from "firebase/auth";
import { saveuserdata} from '../../utils/firebasefunctions';

import { useNavigate } from 'react-router-dom'

import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";

// import Section2 from '../components/Homesection/section2'
function CreatCustomer() {
    const auth = getAuth();
  const [customername, setcustomername] = useState("");
  const [customeremail, setcustomeremail] = useState("");
  const [customerphonenumber, setcustomerphonenumber] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");




const handlecustomerChange = (event) =>{
    setcustomername(event.target.value)
}
const handlecustomeremailChange = (event) =>{
    setcustomeremail(event.target.value)
}

const handlecustomerphonenumberChange = (event) =>{
    setcustomerphonenumber(event.target.value)
}
const handlecpasswordChange = (event) =>{
    setpassword(event.target.value)
}

const handleconfirmpasswordChange = (event) =>{
    setconfirmpassword(event.target.value)
}


const createtask = async () =>{

    if(customername == ""){
        toast.warn("Please fill out Customer Name")
    }else if (customeremail == ""){
        toast.warn("Please enter your customer email")
    }else if (customerphonenumber == ""){
        toast.warn("Please enter your customer phone number")
    }else if (password == ""){
        toast.warn("Please enter your customer account password")
    }else if (confirmpassword == ""){
        toast.warn("Please enter your customer account confirm password")
    }else if (password != confirmpassword){
        toast.warn("Passwords do not match")
    }else{
        createUserWithEmailAndPassword(auth, customeremail, password)
        .then(async (userCredential) => {

          const user = userCredential.user;

          let data = {
            id: user?.uid,
            name: customername,
            email: customeremail,
            phonenumber: customerphonenumber,
            profilepicture: "",
            designation: "",
            usertype: "Client",

        }
        await saveuserdata(data, user?.uid).then((response) => {
            if(response == "success"){
                toast.success("Customer Created Successfully")
            }else{
                toast.error(response)
            }

           })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          // ..
        });

    }
}
  return (
    <div className='w-[90%] lg:w-[80%] px-3 lg:px-7 rounded-xl py-6 border border-gray-300 mx-auto mt-[80px] lg:mt-[100px] lg:mb-[30px]'>
        <div className='w-full' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Customer Name</p>
            <input type='text'
            value={customername} placeholder='Customer Name'
            onChange={handlecustomerChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div>

        <div className='w-full mt-[20px]' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Customer Email</p>
            <input type='text'
            value={customeremail} placeholder='Customer Email'
            onChange={handlecustomeremailChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div>
        <div className='w-full mt-[20px]' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Customer Phone Number</p>
            <input type='text'
            value={customerphonenumber} placeholder='Customer Phone Number'
            onChange={handlecustomerphonenumberChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div>
        <div className='w-full mt-[20px]' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Password</p>
            <input type="password"
            value={password} placeholder='Password'
            onChange={handlecpasswordChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div>
        <div className='w-full mt-[20px]' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Confirm Password</p>
            <input type="password"
            value={confirmpassword} placeholder='Confirm Password'
            onChange={handleconfirmpasswordChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div>

        <button
        onClick={()=>{
            createtask()
        }}
        type='submit'
        className='text-center py-4 rounded-2xl mt-6 bg-themeColor hover:bg-themeColor/80 w-full lg:w-[40%] mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
            Create Customer
        </button>
    </div>
  );
}

export default CreatCustomer;