import {getApp, getApps, initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import { getMessaging } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyDNrfo18OauFyLj23uQQNWS9y99mN8VenQ",
//   authDomain:"test-project-fd547.firebaseapp.com",
//   projectId:"test-project-fd547",
//   storageBucket: "test-project-fd547.appspot.com",
//   messagingSenderId: "1039906766636",
//   appId: "1:1039906766636:web:84503bafca8449154af689",
//   measurementId: "G-7182EC6T7J"
// };


// const firebaseConfig = {
//   apiKey: "AIzaSyBLz4-whGZPZ54sLgJTYJM3EwMVtW54USo",
//   authDomain: "raftek-ddaa2.firebaseapp.com",
//   projectId: "raftek-ddaa2",
//   storageBucket: "raftek-ddaa2.appspot.com",
//   messagingSenderId: "617132684104",
//   appId: "1:617132684104:web:ca4f32fa3d50341e86797d",
//   measurementId: "G-K28KWWD8RM"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBeFwsX9MIhu_w_8JMp5vFirRy9k-36M6g",
  authDomain: "raftekpro-82bb0.firebaseapp.com",
  projectId: "raftekpro-82bb0",
  storageBucket: "raftekpro-82bb0.appspot.com",
  messagingSenderId: "3132476728",
  appId: "1:3132476728:web:94d3130a62bd617f4f85cd",
  measurementId: "G-GDS2C7VXE6"
};




// const firebaseConfig = {
//   apiKey: import.meta.env.VITE_APP_API_KEY,
//   authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN,
//   projectId: import.meta.env.VITE_APP_PROJECT_ID,
//   storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
//   messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
//   appId: import.meta.env.VITE_APP_APP_ID,
//   measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
// };




  const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig)
  const firestored = getFirestore(app)
  const storage = getStorage(app)
  const messaging = getMessaging()

  export {app, firestored, storage, messaging};




