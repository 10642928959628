import { useEffect, useRef, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";

const StatusPopup=(props) => {
    const popupref = useRef(null)
    const [search, setsearch] = useState("");

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popupref.current && !popupref.current.contains(event.target)) {
        props.activateoutsidecontainerclick()
          }


        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [popupref]);

      const handlemessageChange = (event) => {
        setsearch(event.target.value)
      }
    return(
        props.visible &&
        <div
        ref = {popupref}
        className="w-[250px] max-h-[185px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10  top-[50px] left-[0px]  self-start" style={props.style}>
                <input type='text'
                  value={search}
                  placeholder='Search'
                  onChange={handlemessageChange}
                  className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex mb-[10px]" />
     <div className="w-full overflow-y-scroll max-h-[120px]">
            {props.data.map((item, index) =>
   item.title.toLowerCase().includes(search.toLowerCase())  ?

                <div
                onClick={() => {
                    props.onClickcorrect(item)}}
                    className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-themeColor hover:text-white text-textcolor hover:px-[10px] hover:rounded-xl`}>
                    <p className=' font-medium PoppinsRegular text-sm '>{item.title}</p>
                </div>
            :null)}

            </div>
        </div>
    )
}


export default StatusPopup