import React, {useRef, useState} from 'react'
import '../index.css'
import Googleplaystore from '../../images/googlestore.png'
import Applestore from '../../images/appstore.png'
import Gpay from '../../images/gpay.png'
import Master from '../../images/mastercard.png'
import vissa from '../../images/visa.png'
import applelogo from '../../images/apppaylogo.png';
import formbg from '../../images/grain-Texture.png'
import outline from '../../images/outline.png'
import swap from '../../images/swap.png'
import flag from '../../images/US.png'
import crypto from '../../images/crypto-colored.png'
import downIcon from '../../images/downicon.png'
import { Homeblockchain, Homecuruncy, Homemap, Homesection4, Otp, EmployeeHome, SignInPopup, SignUpPopup } from '../../components'


function LoginScreen() {
    const [currentPopup, setCurrentPopup] = useState("signin");

    const renderPopup = () => {
        switch (currentPopup) {
            case "signin":
                return <SignInPopup name={currentPopup} onClose={handleClose} onNext={handleNext}/>;
            // case "Otppopup":
            //     return <Otp name={currentPopup} onClose={handleClose} onNext={handleNext}/>;
            case "Signup":
                return <SignUpPopup name={currentPopup} onClose={handleClose} onNext={handleNext}/>
            default:
            return null;
        }
    };

    const handleNext = (popupName) => {
        setCurrentPopup(popupName);
    };
    
    const handleClose = () => {
        setCurrentPopup(null);
    };

  return (
      <div className='w-[100%] h-screen  '>
   
        {renderPopup()}
   
      </div>
  );
}

export default LoginScreen;