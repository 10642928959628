import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllUserItems, getAllEmployeeTasks, updateItem, getAllBlogsPosts, getAllData, getAllEmployeeForProject, getAllEmployeeandSupervisors, getAllEmployeeForClient, getAllEmployeeandSupervisorsforclient, getAllEmployeeandSupervisorsforsupervisor, getAllProjectsforSupervisor } from '../../utils/firebasefunctions';
import { useNavigate, useLocation } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';

import { actionType } from '../context/reducer';
import { EmployeeHome, AdminHome } from '../components'
import { getToken } from "firebase/messaging";
import { messaging } from "./../../firebase.config";
import { arrayUnion, } from "firebase/firestore"
import ProjectHome from '../components/ProjectHome';
// import Section2 from '../components/Homesection/section2'
function HomeContainer() {
  const [{ user, tasks, selectedtile }, dispatch] = useStateValue();
  // const { VITE_APP_VAPID_KEY } = import.meta.env;
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      getallemployeedata()
      getallclientdata()

      // getallemployeetasks()
      // getallemployeejobs()
      getallusers()
      // getallprojects()
    }
  }, [selectedtile, user])

  useEffect(() => {
    if (user?.id) {

      if (user?.id != undefined) {
        requestPermission()
      }
      if (user?.usertype == "Client") {
        navigate('/MarketPlace')
      } else if (user?.usertype == "Project") {
        getallprojectemployees()
        getallprojectsupervisors()
      } else if (user?.usertype == "SuperAdmin") {
        getallclients()
      }
    }
  }, [user])

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BBb7l_frsXySelNGhOBb5H1qNI7jOb5qdqpivVz2CPiw3sc9nfg6EW4Ub6oSUPOCbH52Q6TOejfZTExlxHovnCc",
      });

      //We can send token to server
      console.log("Token generated : ", token);
      if (user?.NotificationToken == undefined || user?.NotificationToken?.find(data => data == token) == undefined) {

        updatenotificationtoken(token)
      }

    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }


  const updatenotificationtoken = async (token) => {

    let data = {
      NotificationToken: arrayUnion(token)

    }
    await updateItem("users", user?.id, data).then(async (response) => {

    }).catch((err) => {
      alert(err)
    })

  }


  const getallclients = async () => {
    await getAllUserItems("users", "usertype", "==", "Admin").then(async (response) => {

      dispatch({
        type: actionType.STORE_ALLCLIENTS,
        payload: response
      })
      // toast.success("Signed in Successfully")
      // navigate('/home')
    }).catch((err) => {
      alert(err)
    })
  }
  const getallemployeedata = async () => {
    if (user?.usertype == "Admin") {
      await getAllEmployeeandSupervisorsforclient("users", "usertype", "==", "Employee", "Supervisor", user?.id).then(async (response) => {
        const updatedArray = response.map(obj => {
          return {
            ...obj,          // Spread the existing properties of the object
            selected: false // Add the new parameter with its value
          };
        });


        dispatch({
          type: actionType.STORE_EMPLOYEES,
          payload: updatedArray
        })
        // toast.success("Signed in Successfully")
        // navigate('/home')
      }).catch((err) => {
        alert(err)
      })



    }  else if (user?.usertype == "Supervisor") {

if(user?.projectassigned?.length > 0){

  await getAllEmployeeandSupervisorsforsupervisor("users", "usertype", "==", "Employee", "Supervisor", user?.createdby).then(async (response) => {

    let updatedArray = response.map(obj => {
      return {
        ...obj,          // Spread the existing properties of the object
        selected: false // Add the new parameter with its value
      };
    });


    updatedArray = updatedArray.filter(function (itemdata) {
      return user?.projectassigned?.find(supervisorprojects => itemdata?.projectassigned?.some(employeeprojects => supervisorprojects.project === employeeprojects.project))
    })




    dispatch({
      type: actionType.STORE_EMPLOYEES,
      payload: updatedArray
    })
    // toast.success("Signed in Successfully")
    // navigate('/home')
  }).catch((err) => {

    alert(err)
  })
}





    } else {



      await getAllEmployeeandSupervisors("users", "usertype", "==", "Employee", "Supervisor").then(async (response) => {

        dispatch({
          type: actionType.STORE_EMPLOYEES,
          payload: response
        })
        // toast.success("Signed in Successfully")
        // navigate('/home')
      }).catch((err) => {
        alert(err)
      })
    }
  }

  const getallprojectemployees = async () => {
    await getAllEmployeeForProject("users", "usertype", "==", "Employee", user?.id).then(async (response) => {

      dispatch({
        type: actionType.STORE_PROJECTEMPLOYEES,
        payload: response
      })
      // toast.success("Signed in Successfully")
      // navigate('/home')
    }).catch((err) => {
      alert(err)
    })
  }
  const getallprojectsupervisors = async () => {
    await getAllEmployeeForProject("users", "usertype", "==", "Supervisor", user?.id).then(async (response) => {

      dispatch({
        type: actionType.STORE_PROJECTSUPERVISORS,
        payload: response
      })

    }).catch((err) => {
      alert(err)
    })
  }

  const getallusers = async () => {
    await getAllData("users").then(async (response) => {

      dispatch({
        type: actionType.STORE_USERS,
        payload: response
      })
      // toast.success("Signed in Successfully")
      // navigate('/home')
    }).catch((err) => {
      alert(err)
    })
  }

  // const getallprojects = async () => {
  //   await getAllData("projects").then(async (response) => {

  //     dispatch({
  //       type: actionType.STORE_PROJECTS,
  //       payload: response
  //     })

  //   }).catch((err) => {
  //     alert(err)
  //   })
  // }

  const getallclientdata = async () => {
    if (user?.usertype == "SuperAdmin") {
      await getAllUserItems("users", "usertype", "==", "Project").then(async (response) => {

        dispatch({
          type: actionType.STORE_CLIENTS,
          payload: response
        })
        // toast.success("Signed in Successfully")
        // navigate('/home')
      }).catch((err) => {
        alert(err)
      })
    } else if(user?.usertype == "Admin"){
      await getAllEmployeeForClient("users", "usertype", "==", "Project", user?.id).then(async (response) => {

        dispatch({
          type: actionType.STORE_CLIENTS,
          payload: response
        })
        // toast.success("Signed in Successfully")
        // navigate('/home')
      }).catch((err) => {
        alert(err)
      })

    }else{
      if(user?.projectassigned?.length > 0){


      let ids = []
      for (const key in user?.projectassigned){
        ids.push(user.projectassigned[key].project)
      }
      await getAllProjectsforSupervisor("users", ids).then(async (response) => {

        dispatch({
          type: actionType.STORE_CLIENTS,
          payload: response
        })
        // toast.success("Signed in Successfully")
        // navigate('/home')
      }).catch((err) => {
        alert(err)
      })
    }
    }
  }
  const getallemployeetasks = async () => {
    await getAllEmployeeTasks("tasks", "employeeid", "==", user?.id).then(async (response) => {

      dispatch({
        type: actionType.STORE_TASKS,
        payload: response
      })
    }).catch((err) => {
      alert(err)
    })
  }

  const getallemployeejobs = async () => {
    await getAllEmployeeTasks("jobs", "assigned", "==", user?.id).then(async (response) => {
      dispatch({
        type: actionType.STORE_EMPLOYEE_JOBS,
        payload: response
      })
    }).catch((err) => {
      alert(err)
    })
  }

  return (
    <div className='w-[100%] mx-auto'>

      {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" || user?.usertype == "Supervisor"?
        <div className='w-[100%] mx-auto  flex flex-col items-center justify-center '>
          <AdminHome />
        </div> : null
      }

      {user?.usertype == "Project" &&
        <div className='w-[95%] mx-auto  flex flex-col items-center justify-center '>
          <ProjectHome />
        </div>
      }

      {user?.usertype == "Employee" &&
        <div className='w-[90%] lg:w-[90%] mx-auto flex flex-col items-center justify-center'>
          <EmployeeHome />
        </div>
      }
    </div>
  );
}

export default HomeContainer;