import { useEffect, useRef, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";

const Confirmationpopup=(props) => {

    return(
        props.visible &&
        <div
        className = "position absolute z-10  top-[0] left-[0] w-full h-full bg-[rgba(211,211,211,0.2)] flex items-center justify-center"
        >
        <div
        className="w-[300px]   bg-white border border-gray-300 rounded-lg  gk-scr p-[20px]  flex items-center justify-center flex-col" style={props.style}>
 {props.picture &&
  <img src={props.picture} className='w-[80px] h-[80px] rounded-full object-cover  bg-cardOverlay  drop-shadow-lg mb-[15px]' />
 }
<p className="text-center">{props.text}</p>

<div className="flex w-full justify-between items-center flex-row mt-[10px]">
<button
                    onClick={() => {
props.accept()
                    }}
                    type='submit' className='text-center py-2 rounded-md  bg-themeColor hover:bg-themeColor/80 w-[45%]  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                    Yes
                </button>
                <button
                    onClick={() => {
                        props.cancel()
                    }}
                    type='submit' className='text-center py-2 rounded-md  bg-lighttextGray hover:bg-lighttextGray/80 w-[45%]  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                  No
                </button>
</div>
        </div>
        </div>
    )
}


export default Confirmationpopup