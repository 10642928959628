import React, { useState, useEffect, useRef } from 'react'
import Logo from '../images/logo.png'
import Flag from '../images/flag.png'
import Light from '../images/light.png'
import Signins from '../images/signin.png'
import userlogo from '../images/user.png'
import { getAuth, onAuthStateChanged , signOut} from "firebase/auth";
import SignUp from '../images/signup.png'
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { Route, Routes, Link } from 'react-router-dom';
import './index.css'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import { BiMenu } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import SignInPopup from './Signinpopup'
import Otp from './Otp'
import SignUpPopup from './Signuppopup'
import { useNavigate } from 'react-router-dom'


const Header = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [{ user }, dispatch] = useStateValue();
    const [isMenu, setisMenu] = useState(false);
    const [userpopup, setuserpopup] = useState(false);
    const [currentPopup, setCurrentPopup] = useState("");

    const isDashboardRoute = window.location.pathname === '/dashboard';
    const isMessageRoute = window.location.pathname === '/Messages';
    const isHoursWorked = window.location.pathname === '/hoursworked';
    const isLoginRoute = window.location.pathname === '/';
    const isHomeRoute = window.location.pathname === '/home';

const userpopupref = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (userpopupref.current && !userpopupref.current.contains(event.target)) {
            setuserpopup(false);
          }


        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [userpopupref]);
    const handleNext = (popupName) => {
        setCurrentPopup(popupName);
    };

    const handleClose = () => {
        setCurrentPopup(null);
    };

    const renderPopup = () => {
        switch (currentPopup) {
            case "signin":
                return <SignInPopup name={currentPopup} onClose={handleClose} onNext={handleNext} />;
            case "Otppopup":
                return <Otp name={currentPopup} onClose={handleClose} onNext={handleNext} />;
            case "Signup":
                return <SignUpPopup name={currentPopup} onClose={handleClose} onNext={handleNext} />
            default:
                return null;
        }
    };

    const editprofile = () => {
        navigate('/dashboard', {state: {
            employeedata: user
          }});
          setuserpopup(false)
    }

    return (
        <div className='relative'>
            <header className={`fixed z-50 w-screen`} >
                {/*web*/}
                {!isHomeRoute && !isLoginRoute ||
                 user?.usertype != "SuperAdmin" &&   user?.usertype != "Admin" && user?.usertype != "Supervisor" && !isLoginRoute  ?
                <div className='hidden md:flex w-full h-full bg-white items-center justify-between px-8 py-3'>

                        <div onClick={()=>{
 dispatch({
    type: actionType.STORE_SELECTED_TILE,
    payload: "Dashboard"
  })
                      navigate('/home');
                        }}>
                        <img src={Logo} className="w-[160px] h-[48px] object-contain" alt="logo" />
                        </div>

                    <div className='flex items-center pt-[6px]'>


                        {/* {user.usertype == "Employee" || user.usertype == "Project" ?
                            <Link to={"/MarketPlace"}>
                                <text className="navheadingstyle PoppinsRegular text-lg ">Market Place</text>
                            </Link> : null
                        } */}
                        {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" || user?.usertype == "Supervisor" ?
                            <Link to={"/employees"}>
                                <p className="navheadingstyle PoppinsRegular marginleft20 text-lg ">Employees</p>
                            </Link> : null
                        }
                           { user?.usertype == "Supervisor" ?
                            <div
                            onClick={()=>{
                                dispatch({
                                    type: actionType.STORE_SELECTED_TILE,
                                    payload:"Slots"
                                  })
                                  navigate('/home');
                            }}>
                                <p className="navheadingstyle PoppinsRegular marginleft20 text-lg ">Slots</p>
                            </div> : null
                        }
                        {/* { user.usertype == "Project" ?
                            <Link to={"/MyJobs"}>
                                <text className="navheadingstyle PoppinsRegular marginleft20 text-lg ">My Jobs</text>
                            </Link> : null
                        } */}
                                              {user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?
                        <div onClick={()=>{


                            dispatch({
                                type: actionType.STORE_SELECTED_TILE,
                                payload: "Create Employee"
                              })
                                                  navigate('/home');

                              }}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Create User</p>
                            </div> : null
                        }
                          {user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?
                              <div onClick={()=>{



                                dispatch({
                                    type: actionType.STORE_SELECTED_TILE,
                                    payload: "Create User"
                                  })
                                                      navigate('/home');


                                  }}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Leave Request</p>
                            </div>:null
                        }


                        {/* {user.usertype == "Admin" &&
                            <Link to={"/TaskCreation"}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Create Task</text>
                            </Link>
                        } */}
                                             {user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?
                         <div onClick={()=>{



                            dispatch({
                                type: actionType.STORE_SELECTED_TILE,
                                payload: "Create Project"
                              })
                                                  navigate('/home');

                              }}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Create Project</p>
                            </div> : null
                        }
                        {/* {user.usertype == "Admin" &&
                            <Link to={"/CreatCustomer"}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Create Customer</text>
                            </Link>
                        } */}
                       { user?.usertype == "Project" ?
                            <Link to={"/CreateEmployeeSchedule"}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Employee Schedule</p>
                            </Link> : null
                        }

{user.usertype == "Admin" || user?.usertype == "SuperAdmin" ?
                          <div onClick={()=>{




                            dispatch({
                                type: actionType.STORE_SELECTED_TILE,
                                payload:"Assign Project"
                              })
                                                  navigate('/home');
                              }}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Assign Project</p>
                            </div> : null
                        }


                           { user?.usertype == "Project" ?
                            <Link to={"/ProjectDesignations"}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Project Designations</p>
                            </Link> : null
                        }


                        {user?.usertype == "Admin" || user?.usertype == "Employee"  || user?.usertype == "SuperAdmin"  ?
                            <Link to={"/Messages"}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Support</p>
                            </Link> : null
                        }
                        {/* {user.usertype == "Project" &&
                            <Link to={"/CreateWorkOrder"}>
                                <text className="navheadingstyle marginleft20 PoppinsRegular" >Create Work Order</text>
                            </Link>
                        } */}

                       <div onClick={()=>{



if(user?.usertype == "Project"){
    navigate('/Blogs');
}else{


dispatch({
    type: actionType.STORE_SELECTED_TILE,
    payload:"Blogs"
  })
                      navigate('/home');
}
  }}>
                                <p className="navheadingstyle marginleft20 PoppinsRegular" >Blogs</p>
                            </div>



                    </div>


                    <div className='flex items-center'>


                        <div
                            onClick={() => { setuserpopup(!userpopup) }}
                        >
                            <img src={user?.profilepicture ? user?.profilepicture : user?.logo  ?  user?.logo : userlogo} className='w-[40px] h-[40px] object-cover rounded-full cursor-pointer' />
                        </div>

                    </div>
                </div> : null}
                {/*mobikle*/}
                { !isLoginRoute  &&
                <div className='flex items-center bg-white md:hidden w-full h-full px-3 py-3'>
                    <div className='w-[80%] flex items-center'>

                        <BiMenu
                            onClick={() => {
                                setisMenu(true);
                            }}
                            className=" text-black text-[35px] cursor-pointer hover:text-white"
                        />


                        <div className='ml-4'>
                            <img src={Logo} className="w-[130px] object-contain" alt="logo" />
                        </div>
                    </div>

                    <div className='w-[20%] flex justify-end'>
                        <div
                            onClick={() => { setuserpopup(!userpopup) }}
                        >
                            <img src={user?.profilepicture ? user?.profilepicture : user?.logo ? user?.logo : userlogo} className='w-[40px] h-[40px] object-cover rounded-full cursor-pointer' />
                        </div>
                    </div>
                </div>
}
            </header>
            {isMenu &&
                <div className='bg-white h-screen w-full fixed z-50 flex flex-col items-center'>
                    <div className='flex md:hidden w-[90%] mx-auto h-[15%] items-center'>
                        <div className='w-[70%] flex flex-row items-center'>
                        <IoIosClose
                            onClick={() => {
                                setisMenu(false);
                            }}
                            className=" text-black text-[40px] cursor-pointer hover:text-white"
                        />


                            <img src={Logo} className="w-[150px] object-contain ml-[5px]" alt="logo" />
                    </div>
                        <div className='w-[30%] flex justify-end mt-1'>
                            <div
                                onClick={() => { setuserpopup(!userpopup) }}
                            >
                                <img src={user?.profilepicture ? user?.profilepicture : user?.logo ? user?.logo : userlogo} className='w-[40px] h-[40px] object-cover rounded-full cursor-pointer' />
                            </div>
                        </div>
                    </div>
                    <div className='w-[90%] mx-auto h-[1px] bg-gray-300'></div>

                    <div className='flex flex-col justify-center w-[90%] mt-5 '>
                        {/* {user.usertype == "Employee" || user.usertype == "Project" ?
                            <Link to={"/MarketPlace"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md hover:bg-themeColor text-textcolor hover:text-white'>
                                <text className="PoppinsRegular text-lg  ">Market Place</text>
                            </Link> : null
                        } */}
                   {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" ||  user?.usertype == "Supervisor" ?
                            <Link to={"/employees"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg ">Employees</p>
                            </Link> : null
                        }
                        {/* {user.usertype == "Employee" || user.usertype == "Project" ?
                            <Link to={"/MyJobs"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <text className="PoppinsRegular text-lg">My Jobs</text>
                            </Link> : null
                        } */}
                                        {user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?
                            <div onClick={()=>{

                            dispatch({
                                type: actionType.STORE_SELECTED_TILE,
                                payload: "Create Employee"
                              })
                                                  navigate('/home');

                                setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Create Employee</p>
                            </div> : null
                        }

{ user?.usertype == "Project" ?
                            <Link to={"/CreateEmployeeSchedule"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Employee Schedule</p>
                            </Link>
                            : null
                        }


{user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?
                            <div onClick={()=>{
                                dispatch({
                                    type: actionType.STORE_SELECTED_TILE,
                                    payload:"Assign Project"
                                  })
                                                      navigate('/home');
                                setisMenu(false)}} className='text-center py-[10px]  rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Assign Project</p>
                            </div> : null

                        }


{ user?.usertype == "Project" ?
                            <Link to={"/ProjectDesignations"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]  rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Project Designations</p>
                            </Link>
                            : null
                        }




{user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?
                            <div onClick={()=>{
                                dispatch({
                                    type: actionType.STORE_SELECTED_TILE,
                                    payload: "Leave Request"
                                  })
                                                      navigate('/home');

                                setisMenu(false)}} className='text-center py-[10px] rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Leave Request</p>
                            </div> : null
                        }



{/*
                        {user.usertype == "Admin" &&
                            <Link to={"/TaskCreation"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <text className="PoppinsRegular text-lg" >Create Task</text>
                            </Link>
                        } */}
                   {user?.usertype == "Admin" || user?.usertype == "SuperAdmin" ?
                            <div onClick={()=>{
                                dispatch({
                                    type: actionType.STORE_SELECTED_TILE,
                                    payload: "Create Project"
                                  })
                                                      navigate('/home');
                                setisMenu(false)}} className='text-center py-[10px]  rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Create Project</p>
                            </div> : null
                        }


                        {/* {user.usertype == "Admin" &&
                            <Link to={"/CreatCustomer"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <text className="PoppinsRegular text-lg" >Create Customer</text>
                            </Link>
                        } */}

                        {user?.usertype == "Admin" || user?.usertype == "Employee" || user?.usertype == "SuperAdmin"  ?
                            <Link to={"/Messages"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]  rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Support</p>
                            </Link> : null
                        }
                        {/* {user.usertype == "Project" &&
                            <Link to={"/CreateWorkOrder"} onClick={()=>{ setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <text className="PoppinsRegular text-lg" >Create Work Order</text>
                            </Link>
                        } */}
                            <div onClick={()=>{
                                if(user?.usertype == "Project"){
                                    navigate('/Blogs');
                                }else{


                                dispatch({
                                    type: actionType.STORE_SELECTED_TILE,
                                    payload:"Blogs"
                                  })
                                                      navigate('/home');
                                }
                                setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
                                <p className="PoppinsRegular text-lg" >Blogs</p>
                            </div>
                            {user?.usertype == "Supervisor" &&

<div onClick={()=>{



    dispatch({
        type: actionType.STORE_SELECTED_TILE,
        payload:"Slots"
      })
                          navigate('/home');

    setisMenu(false)}} className='text-center py-[10px]   rounded-md  hover:bg-themeColor text-textcolor hover:text-white'>
    <p className="PoppinsRegular text-lg" >Slots</p>
</div>
}
                    </div>
                </div>
            }
            {userpopup &&
                <div
                ref ={userpopupref}
                className='bg-white border border-gray-300 w-[200px] fixed z-50 right-[40px] top-[60px] rounded-xl flex flex-col items-center py-[20px] px-[15px]'>
                    <img src={user?.profilepicture ? user?.profilepicture : user?.logo ? user?.logo : userlogo} className='w-[45px] h-[45px] object-contain rounded-full' />
                    <p className='text-textcolor PoppinsRegular mt-[5px] text-base pt-1'>{user.usertype == "Project"  ? user?.name : user?.usertype == "Project" ? user?.title : user?.firstname + " " + user?.lastname}</p>
                    <p className='text-lightgrey PoppinsRegular text-[12px]'>{user?.usertype}</p>
                    <div className='mt-[15px] w-[100%]'>
                        {user?.usertype != "Project" &&
                        <div className='pb-[5px] flex items-center w-[100%]' onClick={() => {editprofile()}}>
                            <img src={Signins} className="w-[16px] h-[16px] object-contain" alt="Signout" />
                            <p className="PoppinsRegular text-textcolor text-[12px] ml-[5px] cursor-pointer" >Settings</p>

                        </div>
}
                        <div className='pb-[5px] flex items-center w-[100%]' onClick={() => {
                                  signOut(auth).then(() => {
                                    dispatch({
                                        type: actionType.SET_USER,
                                        payload: []
                                    })
                                    setuserpopup(!userpopup)
                                    navigate("/")
                                    }).catch((error) => {
                                   alert("sign out error")
                                    });
}}>
                            <img src={Signins} className="w-[16px] h-[16px] object-contain" alt="Signout" />
                            <p className=" PoppinsRegular text-textcolor text-[12px] ml-[5px] cursor-pointer" >Log Out</p>

                        </div>
                    </div>
                </div>


            }
        </div>


    )
}


export default Header