import React, { useState, useEffect, useRef } from 'react'
import Google from '../images/google.png'
import Apple from '../images/apple.png'
import userlogo from '../images/user.png'
import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import login from '../images/login-bg (1).png'
import Logo from '../images/logo.png'

import { ToastContainer, toast } from "react-toastify";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable
} from "firebase/storage";
import { storage, app } from '../../firebase.config'
import { saveuserdata } from '../../utils/firebasefunctions';

import './index.css'



const SignUpPopup = (props) => {

    const { name, onClose, onNext } = props;

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");

    const [picturedata, setpicturedata] = useState("");
    const [picture, setpicture] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [phonenumber, setphonenumber] = useState("");
    const [usertype, setusertype] = useState("Employee")
    const [designation, setdesignation] = useState("Project Manager")
    const [designationtypepopup, setdesignationtypepopup] = useState(false)
    const [usertypepopup, setusertypepopup] = useState(false)
    const [usertypelist, setusertypelist] = useState([
        {
            name: "Employee"
        },
        {
            name: "Admin"
        }
    ]);

    const [designationtype, setdesignationtype] = useState([
        {
            name: "Project Manager"
        },
        {
            name: "Mobile App Developer"
        },
        {
            name: "Web Developer"
        },
        {
            name: "Designer"
        },
        {
            name: "Sales Agent"
        }
    ]);


    const fileInput = useRef();
    const auth = getAuth();

    const signuphandler = async () => {
        if (picture == "") {
            toast.warn("Please select a profile picture")
            return
        } else if (firstname == "") {
            toast.warn("Please enter your first name")
            return
        } else if (lastname == "") {
            toast.warn("Please enter your last name")
            return
        } else if (email == "") {
            toast.warn("Please enter your email address")
            return
        } else if (phonenumber == "") {
            toast.warn("Please enter your phone number")
            return
        } else if (password == "") {
            toast.warn("Please enter your password")
            return
        } else if (confirmpassword == "") {
            toast.warn("Please enter your password")
            return
        } else if (password != confirmpassword) {
            toast.warn("Passwords do not match")
            return
        } else {
            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed up
                    const user = userCredential.user;

                    //////////////////////////////////////////
                    const imageFile = picturedata.target.files[0]
                    const storageRef = ref(storage, `profilepicture/${Date.now()}-${imageFile.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, imageFile)
                    uploadTask.on('state_changed', (snapshot) => {
                        //   const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
                    }, (error) => {
                        toast.error("upload error " + error.message);
                    }, () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
                            let data = {
                                id: user?.uid,
                                firstname: firstname,
                                lastname: lastname,
                                email: email,
                                phonenumber: phonenumber,
                                profilepicture: downloadURL,
                                designation: usertype != "Admin" ? designation : "",
                                usertype: usertype

                            }
                            await saveuserdata(data, user?.uid).then((response) => {
                                if (response == "success") {
                                    toast.success("Sign Up Successful")
                                } else {
                                    toast.error(response)
                                }

                            })
                        })
                            .catch((error) => {
                                toast.error(error.message);
                            });
                    })

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(errorMessage)
                    // ..
                });
        }
    }


    const handleNext = (popupname) => {
        onNext(popupname); // Replace "NextPopupName" with the actual name
    };

    const handleemailChange = (event) => {
        setemail(event.target.value)

    }

    const handlepasswordChange = (event) => {
        setpassword(event.target.value)

    }
    const handleconfirmpasswordChange = (event) => {
        setconfirmpassword(event.target.value)

    }

    const handlephonenumberChange = (event) => {
        setphonenumber(event.target.value)

    }

    const handlefirstnameChange = (event) => {
        setfirstname(event.target.value)

    }

    const handlelastnameChange = (event) => {
        setlastname(event.target.value)
    }


    const handleimageChange = (event) => {
        fileInput.current.click();
    }


    const handleimageselected = (e) => {
        setpicturedata(e)
        setpicture(URL.createObjectURL(e.target.files[0]));
    }


    return (
        <main className='w-full flex items-center justify-center'>
            <section className='w-[90%] mx-auto lg:w-full flex justify-between'>
                <div className='w-[95%] mx-auto lg:w-[49%] flex flex-col justify-start'>
                    <div className='w-full lg:w-[90%] flex justify-center lg:justify-start mx-auto mt-5 lg:mt-7'>
                        <img src={Logo} className='w-52 ' />
                    </div>
                    <div className='w-full lg:w-[90%] mx-auto mt-6'>
                        <div className='flex flex-col lg:flex-row justify-start lg:justify-between pt-3 lg:items-center'>
                            <p className="text-textcolor text-[30px] lg:text-[40px] font-bold PoppinsRegular">Sign Up</p>
                            <div className='flex flex-row items-center mt-5 lg:mt-0'>
                                <div className='relative' onClick={() => {
                                    setusertypepopup(!usertypepopup)
                                }}>
                                    <p className='text-textcolor PoppinsRegular font-medium cursor-pointer'>{usertype}</p>
                                    {usertypepopup &&
                                        <div className='w-[150px] bg-white rounded-xl border border-gray-300 flex items-center flex-col p-[5px] absolute z-10 right-[-60px] lg:right-[-40px] top-[30px]'>
                                            {usertypelist.map((item, index) => (
                                                <div
                                                key = {index.toString()}
                                                onClick={() => {
                                                    setusertype(item.name)
                                                    setusertypepopup(!usertypepopup)
                                                }}
                                                    className='hover:bg-[#0989B826] hover:rounded-xl w-full text-center py-2 cursor-pointer'>
                                                    <p className='text-textcolor PoppinsRegular font-medium'>{item.name}</p>
                                                </div>
                                            ))}
                                        </div>}
                                </div>
                                {usertype != "Admin" &&
                                    <div className='ml-[10px] relative'
                                        onClick={() => {
                                            setdesignationtypepopup(!designationtypepopup)
                                        }}>
                                        <p className='text-textcolor PoppinsRegular font-medium cursor-pointer'>{designation}</p>
                                        {designationtypepopup &&
                                            <div className='flex w-[220px] bg-white rounded-xl flex-col z-10 right-[-33px] top-[30px] border border-gray-300 p-[5px] absolute'>
                                                {designationtype.map((item, index) => (
                                                    <div
                                                    key = {index.toString()}
                                                    onClick={() => {
                                                        setdesignation(item.name)
                                                        setdesignationtypepopup(!designationtypepopup)
                                                    }}
                                                        className='hover:bg-[#0989B826] hover:rounded-xl w-full text-center py-2 cursor-pointer'>
                                                        <p className='text-textcolor PoppinsRegular font-medium'>{item.name}</p>
                                                    </div>
                                                ))}
                                            </div>}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='w-full flex justify-center'>
                            <div
                                className='relative flex justify-center my-[10px] border border-white rounded-full items-center mt-5 lg:mt-0'

                            >
                                {picture ? <img src={picture} className='w-[110px] h-[110px] object-cover rounded-full' /> : <FaUser

                                    className="text-lighttextGray/80 p-4 border border-gray-300 rounded-full w-[110px] h-[110px]"
                                />}
                                <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} />

                                <div onClick={handleimageChange} className='absolute right-[-6px] top-[75px] bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer'>
                                    <MdEdit className="text-textcolor w-[20px] h-[20px]" />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>First name</p>
                                <input type='text'
                                    value={firstname} placeholder='First name'
                                    onChange={handlefirstnameChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                            <div className='w-full lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Last name</p>
                                <input type='text' placeholder='Last name'
                                    value={lastname}
                                    onChange={handlelastnameChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px]  rounded-xl border border-themeColor items-center flex" />
                            </div>
                        </div>


                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Email</p>
                                <input type='text' placeholder='Email'
                                    value={email}
                                    onChange={handleemailChange}
                                    className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                            <div className='w-full lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Phone Number</p>
                                <input
                                    value={phonenumber} placeholder='Phone Number'
                                    onChange={handlephonenumberChange}
                                    type="tel" className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                            </div>
                        </div>

                        <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                            <div className='w-full relative lg:w-[48%]' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Password</p>
                                <input
                                    value={password} placeholder='Enter password'
                                    onChange={handlepasswordChange}
                                    type="password" className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                <div className='absolute top-[37px] right-[10px]'>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 cursor-pointer stroke-gray-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 cursor-pointer stroke-gray-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>

                                </div>
                            </div>
                            <div className='w-full relative lg:w-[48%] mt-4 lg:mt-0' >
                                <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Confirm password</p>
                                <input
                                    value={confirmpassword} placeholder='Confirm password'
                                    onChange={handleconfirmpasswordChange}
                                    type="password" className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
                                    <div className='absolute top-[37px] right-[10px]'>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 cursor-pointer stroke-gray-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 cursor-pointer stroke-gray-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>

                                </div>
                            </div>
                        </div>
                        <button type='submit' className="text-center py-4 rounded-2xl mt-6 bg-themeColor hover:bg-themeColor/80 w-full text-white text-sm lg:text-base font-semibold PoppinsRegular uppercase" onClick={() => signuphandler()}>Continue</button>
                        <div className="w-full h-[1px] mt-6 bg-gray-300"></div>
                        <div className='flex justify-center py-6'>
                            <p className="text-center text-textcolor text-sm font-medium PoppinsRegular">
                                Already have an account?
                            </p>
                            <p className="text-center pl-2 cursor-pointer text-textcolor text-sm font-bold PoppinsRegular" onClick={() => handleNext('signin')}>
                                Log In
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w-[49%] hidden lg:block'>
                    <img src={login} className='w-full h-full' />
                </div>
            </section>
        </main>
    )
}


export default SignUpPopup