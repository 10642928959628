import './index.css'

const DashboardContainer =({cardheight, style, innerstyle, color, title, children, count, countlength, showcount }) => {
    return (


    <div className={` ${style}  h-[350px] flex flex-col `}>
        <div className={`drop-shadow-lg h-full bg-white backdrop-blur-md w-[95%] ${cardheight} `}>
            <div className={`w-full border ${color} border-t-4 border-b-1 border-b-gray-300 border-r-0 border-l-0 h-[50px] flex items-center justify-center py-6`}>
                <p className='text-textcolor PoppinsRegular font-bold text-lg'>{title}</p>
              {showcount &&
                <div className={`text-white flex PoppinsRegular ml-2 justify-center text-xs items-center ${count} w-5 h-5 rounded-full`}>
                    {countlength}
                </div>}
            </div>
            <div className={`py-[10px] px-[10px] w-full !h-[80%] flex flex-col text-4xl PoppinsRegular font-bold ${innerstyle}`}>
                {children}
            </div>
        </div>
    </div>

    )
  }



  export default DashboardContainer
