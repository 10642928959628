

import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import { useNavigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged , signOut} from "firebase/auth";
import WhiteLogo from '../images/whitelogo.png'
import userlogo from '../images/user.png'
import Signins from '../images/signin.png'
import { MdWorkHistory } from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";
import { MdCalendarMonth } from "react-icons/md";
import { AiOutlineProject } from "react-icons/ai";
import { MdAssignmentAdd } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { FaBloggerB } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { MdDashboard } from "react-icons/md";
import { MdWorkspaces } from "react-icons/md";
import { actionType } from '../context/reducer';
import './index.css'

const SideMenu = (props) => {

    const [{ user, employees, clients, projects, selectedtile }, dispatch] = useStateValue();
    const auth = getAuth();
    const navigate = useNavigate();

    const listing = [
        {
            label: "Dashboard",
            image: <MdDashboard
            />,
            allowedusertypes: ["SuperAdmin", "Admin", "Supervisor"]
        },
        {
            label: "Slots",
            image: <MdWorkspaces
            />,
            allowedusertypes: ["Supervisor"]
        },
        {
            label: "Employees",
            image: <MdWorkHistory

            />,
            allowedusertypes: ["SuperAdmin", "Admin", "Supervisor"]
        },
        {
            label: "Create User",
            image: <MdPeopleAlt

            />,
            allowedusertypes: ["SuperAdmin", "Admin"]
        },
        {
            label: "Leave Request",
            image: <MdCalendarMonth

            />,
            allowedusertypes: ["SuperAdmin", "Admin"]
        },
        {
            label: "Create Project",
            image: <AiOutlineProject
            />,
            allowedusertypes: ["SuperAdmin", "Admin"]
        },
        {
            label: "Assign Project",
            image: <MdAssignmentAdd

            />,
            allowedusertypes: ["SuperAdmin", "Admin"]
        },
        {
            label: "Support",
            image: <MdSupportAgent
            />,
            allowedusertypes: ["SuperAdmin", "Admin"]
        },
        {
            label: "Blogs",
            image: <FaBloggerB

            />,
            allowedusertypes: ["SuperAdmin", "Admin", "Supervisor"]
        }
    ]

    const editprofile = () => {
        navigate('/dashboard', {
            state: {
                employeedata: user
            }
        });

    }
    function border() {
        return (
            <div className='w-full border border-dashed border-white my-[20px]'></div>
        )
    }

    function tileselection(data) {
        dispatch({
            type: actionType.STORE_SELECTED_TILE,
            payload: data
        })
    }
    return (
        <div className='w-[100%] bg-themeColor h-screen px-[20px]'>
            <div className='h-[30%] overflow-hidden'>
            <div className='w-full flex justify-center items-center pb-[20px] pt-[40px] '>
                <img src={WhiteLogo} className='w-[50%] object-contain' />
            </div>


            {/* <div className='bg-white border border-gray-300 w-[200px] rounded-xl flex flex-col items-center py-[20px] px-[15px]'>
                    <img src={user?.profilepicture ? user?.profilepicture : user?.logo ? user?.logo : userlogo} className='w-[45px] h-[45px] object-cover rounded-full' />
                    <text className='text-textcolor PoppinsRegular mt-[5px] text-base pt-1'>{user.usertype == "Project"  ? user?.name : user.usertype == "Project" ? user?.title : user?.firstname + " " + user?.lastname}</text>
                    <text className='text-lightgrey PoppinsRegular text-[12px]'>{user.usertype}</text>
                    <div className='mt-[15px] w-[100%]'>
                        <div className='pb-[5px] flex items-center w-[100%]' onClick={() => {editprofile()}}>
                            <img src={Signins} className="w-[16px] h-[16px] object-contain" alt="Signout" />
                            <text className="PoppinsRegular text-textcolor text-[12px] ml-[5px] cursor-pointer" >Settings</text>

                        </div>
                        <div className='pb-[5px] flex items-center w-[100%]' onClick={() => navigate("/")}>
                            <img src={Signins} className="w-[16px] h-[16px] object-contain" alt="Signout" />
                            <text className=" PoppinsRegular text-textcolor text-[12px] ml-[5px] cursor-pointer" >Log Out</text>

                        </div>
                    </div>
                </div> */}
            <div className='flex flex-row items-center py-[20px]'>
                <img src={user?.profilepicture ? user?.profilepicture : user?.logo ? user?.logo : userlogo} className='w-[65px] h-[65px] object-contain rounded-full' />
                <div className='flex flex-col ml-[15px]'>
                    <p className='text-white PoppinsRegular  text-base '>{user?.usertype == "Project" ? user?.name : user?.usertype == "Project" ? user?.title : user?.firstname + " " + user?.lastname}</p>
                    <p className='text-lightgrey PoppinsRegular text-[12px]'>{user?.usertype}</p>
                </div>

            </div>
            </div>
            <div className='flex flex-col justify-between h-[70%] overflow-hidden py-[10px]'>
            <div>
            {listing && listing.map((item, index) =>
            (
                item.allowedusertypes.includes(user.usertype) &&
                <div
                key = {index.toString()}
                    onClick={() => {
                        if (item.label == "Support") {

                            navigate('/Messages');
                        } else if (item.label == "Employees") {
                            navigate('/employees');
                        } else {
                            tileselection(item.label)
                        }




                    }}
                    className={`${selectedtile == item.label ? `text-black  bg-white rounded-md` : 'text-white'}  relative hover:rounded-md px-[5px]  cursor-pointer h-[50px] flex items-center justify-start font-medium  text-[16px]  `}  >

                    <div
                        className="  self-center ">
                        {item.image}
                    </div>

                    <p className="whitespace-nowrap PoppinsRegular font-medium   ml-[10px]">{item.label}</p>


                    {user?.newmessage == true && item.label == "Support" || user?.newleaverequest == true && item.label == "Leave Request"  || user?.newblogpost == true && item.label == "Blogs" ?
                    <div className=' bg-red-600 flex items-center justify-center rounded-md mb-[20px] ml-[5px]'>
   <p className=" PoppinsRegular   text-white px-[5px] text-[10px]">NEW</p>
                    </div> : null}


                </div>
            )
            )
            }

</div>


<div>
            <div
                onClick={() => {

                    navigate('/dashboard', {
                        state: {
                            employeedata: user
                        }
                    });
                }}
                className='hover:bg-white hover:rounded-md px-[5px]  cursor-pointer h-[50px] flex items-center justify-center  text-white hover:text-black mt-[20px]' >

                <div
                    className="  self-center ">
                    <TbLogout2
                    />
                </div>
                <p className="whitespace-nowrap PoppinsRegular font-medium  w-full ml-[10px]">Settings</p>
            </div>
            <div
                onClick={() => {
                    // localStorage.removeItem('user');
                    signOut(auth).then(() => {
                        dispatch({
                            type: actionType.SET_USER,
                            payload: []
                        })
                        navigate("/")
                        }).catch((error) => {
                       alert("sign out error")
                        });


                }}
                className='hover:bg-white hover:rounded-md px-[5px]  cursor-pointer h-[50px] flex items-center justify-center  text-white hover:text-black ' >
                <div
                    className="  self-center ">
                    <TbLogout2
                    />
                </div>
                <p className="whitespace-nowrap PoppinsRegular font-medium  w-full ml-[10px]">Logout</p>

            </div>
            </div>
            </div>



        </div>
    )
}


export default SideMenu