import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { saveitem } from '../../utils/firebasefunctions';
import { useFetcher, useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { TbCurrencyDollar } from "react-icons/tb";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
setDefaults({
  key: 'AIzaSyBQITYyVOR2eVeTha4Vzfc2T8S0ZAcENDE', // Your API key here.
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
});

// import Section2 from '../components/Homesection/section2'
function CreateWorkOrder() {
  const [{ user, employees }, dispatch] = useStateValue();
  const [taskdescription, settaskdescription] = useState("");
  const [jobtitle, setjobtitle] = useState("");
  const [jobsalary, setjobsalary] = useState(0);
  const [Location, setLocation] = useState("");
  const [Country, setCountry] = useState("");
  const [LocationLat, setLocationLat] = useState(0);
  const [LocationLong, setLocationLong] = useState(0);
  const [Loading, setLoading] = useState(false);


  useEffect(() => {
    if (Location != "") {
      fromAddress(Location.label)
        .then(({ results }) => {
          const { lat, lng } = results[0].geometry.location;
          setLocationLat(lat)
          setLocationLong(lng)
        })
        .catch(console.error);
    }
  }, [Location])
  const handletaskChange = (event) => {
    setjobtitle(event.target.value)
  }
  const handlesalaryChange = (event) => {
    setjobsalary(event.target.value)
  }




  const handletaskdescriptionChange = (event) => {
    settaskdescription(event.target.value)
  }

  const createtask = async () => {

    if (jobtitle == "") {
      toast.warn("Please fill out a Job Title")
    } else if (taskdescription == "") {
      toast.warn("Please fill out a Job Description")
    } else if (jobsalary == 0) {
      toast.warn("Job salary must be grater than $0")
    } else if (Location == "") {
      toast.warn("Please select Country for Job Posting")
    } else {
      setLoading(true)
      let data = {
        jobcreationdate: Date.now(),
        jobcreator: user?.id,
        title: jobtitle,
        description: taskdescription,
        status: "Pending",
        salary: jobsalary,
        location: Location.label,
        lat: LocationLat,
        long: LocationLong
      }




      await saveitem("jobs", data).then(async (response) => {
        if (response == "success") {
          toast.success("Job created Successfully")
          setjobtitle("")
          setjobsalary(0)
          settaskdescription("")
          setLocation("")
        } else {
          toast.error("Something went wrong while posting your Job. Please try again later.")
        }


        setLoading(false)
      }).catch((err) => {
        alert(err)
        setLoading(false)
      })

    }
  }
  return (
    <div className='wrapper pb-[50px] lg:pt-5'>


      <div className='w-full mt-[20px]' >
        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Job Title</p>
        <input type='text' placeholder='Job Title'
          value={jobtitle}
          onChange={handletaskChange}
          className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
      </div>

      <div className='w-full mt-[20px] relative' >
        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Salary (Monthly)</p>
        <TbCurrencyDollar

          className='text-black  text-[20px] position absolute top-[36px] left-[10px]'
        />
        <input type='number'
          value={jobsalary}
          onChange={handlesalaryChange}
          className=" input pl-8 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex" />
      </div>



      <div className='w-full mt-[20px] PoppinsRegular' >
        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Location</p>
        <GooglePlacesAutocomplete
          apiKey='AIzaSyDL1Kk_B0bkRx9FmM3v-3oRn57_MzFyiM8'
          selectProps={{
            Location,
            onChange: setLocation,
            placeholder: "Select your address",
          }}
          name="address"
          value={Location}
        />
      </div>


      <div className='w-full mt-[20px]' >
        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Job Description</p>
        <textarea type='text' placeholder='Job Description'

          value={taskdescription}
          onChange={handletaskdescriptionChange}
          className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex !h-32" />
      </div>

      <div className='w-full lg:w-[35%]'>
        <button
        disabled = {Loading ? true : false}
          onClick={() => {
            createtask()
          }}
          type='submit' className={`text-center py-4 rounded-2xl mt-6 bg-themeColor ${Loading ? null :  "hover:bg-themeColor/80"} w-full text-white text-sm lg:text-base font-semibold PoppinsRegular uppercase`}>
            {Loading ? "Loading..." :  "Post Job"}
        </button>
      </div>

    </div>
  );
}

export default CreateWorkOrder;