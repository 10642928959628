import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getallemployeesslotsdata, saveuserdata } from '../../utils/firebasefunctions';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable
} from "firebase/storage";
import { storage, app } from '../../firebase.config'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { RotatingLines } from 'react-loader-spinner'
import ProjectPopUp from '../components/ProjectPopUp';
import StatusPopup from '../components/StatusPopup';
import moment from 'moment';
import { FaRegCalendarAlt } from "react-icons/fa";
import DatePicker from "react-horizontal-datepicker";
import { RiPassValidLine } from 'react-icons/ri';
import Confirmationpopup from '../components/Confirmationpopup';
import EmployeeSlotPopUp from '../components/EmployeeSlotPopUp';
import { ImFilesEmpty } from "react-icons/im";

const Slots = (props) => {
    const [{ user, clients, employees }, dispatch] = useStateValue();
    const [checkinstatusfilter, setcheckinstatusfilter] = useState(
        [
          {
            title: "All",

          },
          {
            title: "Pending",

          },
          {
            title: "On The Way",

          },
          {
            title: "Checked In",

          },
          {
            title: "Checked Out",

          }
        ]
      );
      const daysofweek = [
        {
          day: "Sunday"
        },
        {
          day: "Monday"
        },
        {
          day: "Tuesday"
        },
        {
          day: "Wednesday"
        },
        {
          day: "Thursday"
        },
        {
          day: "Friday"
        },
        {
          day: "Saturday"
        },

      ]
      const [selectedproject, setselectedproject] = useState("All")
      const [selectedprojectdata, setselectedprojectdata] = useState("")
        const [selectedstatus, setselectedstatus] = useState("All")
        const [projectpopup, setprojectpopup] = useState(false)
        const [statuspopup, setstatuspopup] = useState(false)
        const [loading, setloading] = useState(false)
        const [selecteddate, setselecteddate] = useState(new Date())
        const [slotdata, setslotdata] = useState([])
        const [selectedslot, setselectedslot] = useState()
        const [empdetailsvisible, setempdetailsvisible] = useState(false)



        useEffect(()=>{
            getallworkerslotsfortoday()
        },[selecteddate, selectedprojectdata])

        const getallworkerslotsfortoday = async () => {
            setloading(true)
            await getallemployeesslotsdata("slots", selectedprojectdata?.id, moment(selecteddate).format("YYYY-MM-DD")).then(async (response) => {

              let dataobj = [...response]
              for (const key in dataobj) {
                dataobj[key]["workerstatus"] = dataobj[key]?.checkoutdatetime ? "Checked Out" : dataobj[key]?.checkindatetime ? "Checked In" : dataobj[key]?.onthewaytime ? "On The Way" : "Pending"
              }

              setslotdata(dataobj)

              setloading(false)
            }).catch((err) => {
                setloading(false)
              console.log("err" + err)
            })
          }

 function filters (title, val, onpress) {
    return(
        <div className='w-full md:w-[30%] mt-[20px]' >
        <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>{title}</p>
     <div className='relative'>
        <div
        onClick={onpress}
          className="input px-4 text-textcolor text-base font-medium PoppinsRegular w-full py-[10px] rounded-md border border-themeColor items-center flex h-[40px]" >
{val}
          </div>
          <ProjectPopUp

visible ={projectpopup && title == "Projects"}
data = {[...[{title: "All"}],  ...clients]}

activateoutsidecontainerclick = {()=>{
    setprojectpopup(false);
}}

            onClickcorrect={(item) => {
                if(item.title == "All"){
                    setselectedproject(item.title)
                    setselectedprojectdata("")
                    setprojectpopup(false);
                }else{
                    setselectedproject(item.title)
                    setselectedprojectdata(item)
                    setprojectpopup(false);
                }

            }}
/>
<StatusPopup

visible ={statuspopup && title == "Status"}
data = {checkinstatusfilter}

activateoutsidecontainerclick = {()=>{
    setstatuspopup(false);
}}

            onClickcorrect={(item) => {
                setselectedstatus(item.title)
                setstatuspopup(false);
            }}
/>
          </div>
      </div>
    )
}

function todaysdate(){
    return(
        <div className='flex flex-row w-full items-center justify-center relative'>
               <div className='flex flex-row w-full items-center justify-start relative'>
    <div>
        <FaRegCalendarAlt
        className='text-[30px] text-themeColor'/>
    </div>
<div className='ml-[5px]'>
<p className='PoppinsRegular font-medium text-black text-sm'>{moment(new Date()).format("DD MMM yyyy")}</p>
<p className='PoppinsRegular font-medium text-lightgrey text-sm'>{daysofweek[moment(new Date()).day()].day}</p>
    </div>
    </div>
    <img src={user.profilepicture} className='position absolute h-[120px] w-[120px] rounded-full object-cover top-[-80px]' />
</div>
    )
}


const selectedDay = (val) =>{

    setselecteddate(val)
};

    return (
        <div className='w-[100%] lg:flex  items-center  flex-col  mt-[70px] md:mt-[0px] '>
             {selectedprojectdata !== "" ?
                <img src={selectedprojectdata.logo} className='w-full h-[300px] rounded-md object-cover' />
:
<div className='w-full h-[300px] rounded-md bg-themeColor flex items-center justify-center'>
<p className='PoppinsRegular font-medium text-white text-[25px]'>Projects</p>
</div>
             }
<div className='w-full flex justify-start mt-[10px]'>
{todaysdate()}
</div>
<div className='flex flex-col md:flex-row w-full justify-evenly mt-[20px] md:mt-[40px]'>
{filters("Projects", selectedproject, ()=>{setprojectpopup(!projectpopup)})}
{filters("Status", selectedstatus,  ()=>{setstatuspopup(!statuspopup)})}


</div>

<div className='w-full pt-[20px]'>
<DatePicker getSelectedDay={selectedDay}
                  selectDate={selecteddate}
                  endDate={180}
                  labelFormat={"MMMM yyyy"}
                  color={"#1F2E5E"}

/>
</div>
<div className='w-full py-[20px] flex flex-col items-center justify-center'>


{loading ?
<div className='py-[80px]'>
   <RotatingLines
   visible={true}
   height="25"
   width="25"
   strokeColor="#218fdb"
   color="grey"
   strokeWidth="5"
   animationDuration="0.75"
   ariaLabel="rotating-lines-loading"
   wrapperStyle={{}}
   wrapperClass=""
   />
   </div>
    :


        slotdata.length == 0 ?
        <div className='flex flex-col w-full py-[80px] items-center justify-center'>

      <p  className='PoppinsRegular font-medium text-lightgrey '>No Slots Avaialble</p>
        <ImFilesEmpty
        className='text-[40px] text-lightgrey pt-[5px]'
        />
        </div> :
    slotdata.map((item, index) =>
        (
            item.status == "Accepted" && (item.workerstatus == selectedstatus || selectedstatus == "All") &&
            employees.filter(function (data) { return data.id == item.employeeid }).map(function (data) {



              return (


                <div
                key= {index.toString()}
                  onClick={() => {
                    setselectedslot({
                      recordid: item.recordid,
                      id: data.id,
                      picture: data.profilepicture,
                      name: data.firstname + " " + data.lastname,
                      designation: item.projectdesignation,
                      phonenumber: data.phonenumber,
                      starttime: item?.starttime,
                      endtime: item?.endtime,
                      selecteddate: moment(selecteddate).format("YYYY-MM-DD")
                    })
                    setempdetailsvisible(true)


                  }}

                  className='w-full  flex flex-row justify-between items-center border border-t-0 border-r-0 border-l-0 border-b-1 border-b-gray-200 px-[10px] py-[10px] hover:cursor-pointer hover:bg-[#0989B826]/10 rounded-md'
                  >


<div className='w-full  flex flex-row items-center'>
    <div className='relative'>
 <img src={  data.profilepicture} className='w-[50px] h-[50px] object-cover rounded-full' />
 {item?.hoursapproved != undefined &&
<div className='position absolute bottom-[-2px] right-[-5px] h-[20px] w-[20px] rounded-full bg-[#F9F9F9] flex items-center justify-center'>
 {item?.hoursapproved == true &&
     <IoMdCheckmark
     className='text-green-500'/>
 }
  {item?.hoursapproved == false &&
     <RxCross2
     className='text-red-500'/>
 }
 </div>}
       </div>
                    <div
                    className='ml-[20px] flex flex-col w-full'
                 >
                    <div   className='flex flex-row'>
                      <p
                     className='PoppinsRegular font-medium text-black text-[15px]'>{data.firstname.toUpperCase() + " " + data.lastname.toUpperCase() + " as  "}</p>
                     <p className='PoppinsRegular font-medium text-themeColor ml-[5px] text-[15px]'>{ item.projectdesignation}</p>
                     </div>
                      <p
                       className={`PoppinsRegular font-medium text-[15px] ${item?.checkoutdatetime ? "text-red-500" : item?.checkindatetime ? "text-themeColor" : "text-orange-500"} `}>{item?.workerstatus}</p>
                      <p  className='PoppinsRegular font-medium text-black text-[15px]'>{item.timeslot}</p>
                    </div>
</div>

                 <IoIosArrowForward
                 className='text-[20px] text-lightgrey'
                 />
                </div>



              )
            })
        ))
}
</div>

<EmployeeSlotPopUp
visible = {empdetailsvisible}
data = {selectedslot}

activateoutsidecontainerclick = {()=>{
 setempdetailsvisible(false)
}}

onapprovalsuccess={(id, bool) => {
    let allempdata = [...slotdata]
    let empindex = allempdata.findIndex(data => {
      return data.recordid == id;
    });
    allempdata[empindex]["hoursapproved"] = bool

    setslotdata(allempdata)

}}



/>

        </div>
    )
}

export default Slots;