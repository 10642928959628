import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllEmployeeTasks, updateItem, getNestedItem, getNestedItemdualcondition, getSumofNestedItem, getSumofAllNestedItems, getgeneralNestedItemdualcondition } from '../../utils/firebasefunctions';
import { arrayUnion, } from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import { RotatingLines } from 'react-loader-spinner'
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { TbCurrencyDollar } from "react-icons/tb";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { MdEditDocument } from "react-icons/md";
import TimePicker from 'react-time-picker';
import { MdDelete } from "react-icons/md";
import 'react-time-picker/dist/TimePicker.css';
import { downloadExcel } from "react-export-table-to-excel";
import Switch from "react-switch";
import userlogo from '../images/user.png'
import moment from 'moment'
import Select from "react-dropdown-select";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { HiArrowDownLeft, HiArrowLeft } from 'react-icons/hi2';
import {  FaPlusCircle } from 'react-icons/fa';
import { FaPlus } from "react-icons/fa6";
import ProjectPopUp from '../components/ProjectPopUp';
import Confirmationpopup from '../components/Confirmationpopup';

setDefaults({
  key: 'AIzaSyBQITYyVOR2eVeTha4Vzfc2T8S0ZAcENDE', // Your API key here.
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
});




// import Section2 from '../components/Homesection/section2'
function HoursWorked() {
  const [{ user, employees, jobs, clients, users }, dispatch] = useStateValue();
  const header = ["Hours Status" , "Emp ID", "Name", "Project Title", 'Designation', 'Check In', 'Lunch In', 'Lunch Out', 'Check Out', 'Total Break Time', 'Total Time Of Day', 'NORMAL HRS', 'EVENING HRS', 'NIGHT HRS', 'HOURLY SALARY NORMAL', 'HOURLY SALARY EVENING', 'HOURLY SALARY NIGHT', 'TOTAL SALARY', 'INVOICING AMOUNT WITH A MULTIPLICATOR', 'Fixed Amount'];
  const [excelBody, setExcelBody] = useState([]);
  const [employeeid, setemployeeid] = useState(0);
  const [employeedata, setemployeedata] = useState();
  const [startdate, setstartdate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [enddate, setenddate] = useState(new Date());
  const [employeeprojects, setemployeeprojects] = useState([]);
  const [projectselectionpopup, setprojectselectionpopup] = useState(false);
  const [projectselectionpopup2, setprojectselectionpopup2] = useState(false);
  const [project, setproject] = useState("");
  const [generalemployeecheckindata, setgeneralemployeecheckindata] = useState([]);


  const [todayshours, settodayshours] = useState(0);
  const [weeklyhours, setweeklyhours] = useState(0);
  const [monthlyhours, setmonthlyhours] = useState(0);
  const [alltimehours, setalltimehours] = useState(0);
  const [designation, setdesignation] = useState([]);
  const [filtertype, setfiltertype] = useState("Active");
  const [employeecheckindata, setemployeecheckindata] = useState([]);
  const [designationselectionpopup, setdesignationselectionpopup] = useState(false);
  const [loading, setloading] = useState(false);
  const [disableloader, setdisableloader] = useState(false);
  const [employeedeletionconfirmation, setemployeedeletionconfirmation] = useState(false);


  const navigate = useNavigate();



  // const designationselectionpopupref = useRef(null)

  useEffect(() => {
    if (employees.length > 0) {
      setemployeeid(employees[0].id)
      setemployeedata(employees[0])
    }

  }, [])

  useEffect(() => {
    if (employeeid != 0) {
      setprojectselectionpopup(false)
      setproject("")
      getallemployeejobs(employeeid)
      getallhoursdisplaydata()
    }
  }, [employeeid])

  useEffect(() => {
    if (filtertype == "Active" || filtertype == "InActive") {
      gethours()
    }

    // else {
    //   getgeneralhours()
    // }

  }, [startdate, enddate, employeeid, project, filtertype])



  // useEffect(() => {
  //   const handleClickOutside = (event) => {


  //     if (designationselectionpopupref.current && !designationselectionpopupref.current.contains(event.target)) {
  //       setdesignationselectionpopup(false);
  //     }





  //   };

  //   // Add event listener
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Cleanup event listener
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [ designationselectionpopupref]);

  const getlocationaddress = (lat, long) => {
    fromLatLng(lat, long)
      .then(({ results }) => {

        alert(results[0].formatted_address)
      })
      .catch(console.error);
  }

  const disableemployeetoggle = async(emp, bool) => {
    setdisableloader(true)
    let data ={
      disabled: bool
    }

if(bool){
  data["diableddate"] = Date.now()
  data["disabledby"] = user.id
}



      await updateItem("users", emp.id, data).then(async(response) => {
        if (bool){
          toast.success(`${emp.firstname + " " + emp.lastname} disabled successfully!`)
        }else{
          toast.success(`${emp.firstname + " " + emp.lastname} enabled successfully!`)
        }
        let employeesdata = [...employees]
        const empindex = employeesdata.findIndex(item => item.id === emp.id);
        employeesdata[empindex] = { ...employeesdata[empindex], ...data }

        dispatch({
          type: actionType.STORE_EMPLOYEES,
          payload: employeesdata
      })
      setemployeeid( employeesdata[empindex].id)
      setemployeedata( { ...employeesdata[empindex], ...data })
        setdisableloader(false)

    }).catch((err)=>{

        toast.warn(err)
        setdisableloader(false)
    })
  }


  const updateemployeedata = async (param, bool, id) =>{
    let data ={ }
    data[param] = bool
    await updateItem("users", id, data).then((response) => {
      if (response == "success") {
        const employeeindex = employees.findIndex(item => item.id === id);
        let dataset = [...employees]
        dataset[employeeindex] = {...dataset[employeeindex], ...data}
        dispatch({
          type: actionType.STORE_EMPLOYEES,
          payload: dataset
      })
      setemployeeid( dataset[employeeindex].id)
      setemployeedata( { ...dataset[employeeindex], ...data })
      } else {
          toast.error("Something went wrong!. Please try again later.")
      }

  }).catch((err) => {
      alert(err)
  })
  }

  const getallhoursdisplaydata = () => {
    const currentDate = new Date();
    const startOfDay = new Date(currentDate);
    startOfDay.setHours(0, 0, 0, 0); // Set to the start of the current day
    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999); // Set to the end of the current day
    gethourssum(startOfDay.getTime(), endOfDay.getTime(), "day")
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - currentDate.getDay()); // Sunday of the current week
    startOfWeek.setHours(0, 0, 0, 0); // Start of the day
    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(endOfWeek.getDate() + (6 - currentDate.getDay())); // Saturday of the current week
    endOfWeek.setHours(23, 59, 59, 999); // End of the day
    gethourssum(startOfWeek.getTime(), endOfWeek.getTime(), "week")
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const startOfMonth = new Date(currentYear, currentMonth, 1); // Start of the month
    const endOfMonth = new Date(currentYear, currentMonth + 1, 0); // End of the month
    gethourssum(startOfMonth.getTime(), endOfMonth.getTime(), "month")
    gethourssum()
  }

  const gethourssum = async (start, end, type) => {
    if (start != undefined && end != undefined && type != undefined) {

      await getSumofNestedItem("slots", employeeid, "checkindatetime", ">=", start, "checkindatetime", "<=", end).then(async (response) => {

        if (type == "week") {
          setweeklyhours(parseInt(response / 60))
        } else if (type == "month") {
          setmonthlyhours(parseInt(response / 60))
        } else if (type == "day") {
          settodayshours(parseInt(response / 60))
        }
      }).catch((err) => {
        return err
      })

    } else {


      await getSumofAllNestedItems("slots", employeeid).then(async (response) => {

        setalltimehours(parseInt(response / 60))
      }).catch((err) => {
        return err
      })

    }
  }

  const gethours = async () => {


    await getNestedItemdualcondition("slots", employeeid, "checkindatetime", ">=", startdate.getTime(), "checkindatetime", "<=", enddate.getTime(), "projectid", "==", project?.id).then(async (response) => {
      setemployeecheckindata(response)

    }).catch((err) => {
      console.log(err)
    })

  }
  function timeDifference(date1, date2) {
    const startTime = new Date(date1);
    const endTime = new Date(date2);
    const differenceInMs = endTime - startTime;
    const totalMinutes = Math.floor(differenceInMs / (1000 * 60));

    // Convert total minutes to hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hrs ${minutes} mins`;

    // Helper function to convert time string (hh:mm) to minutes since midnight
    // function timeToMinutes(time) {
    //   const [hours, minutes] = time.split(':').map(Number);
    //   return hours * 60 + minutes;
    // }

    // // Convert times to minutes
    // const startMinutes = timeToMinutes(startTime);
    // const endMinutes = timeToMinutes(endTime);

    // // Calculate the difference in minutes
    // let differenceInMinutes = endMinutes - startMinutes;

    // // If the difference is negative, it means the end time is on the next day
    // if (differenceInMinutes < 0) {
    //   differenceInMinutes += 24 * 60; // Add 24 hours worth of minutes
    // }

    // // Convert difference to hours and minutes
    // const hours = Math.floor(differenceInMinutes / 60);
    // const minutes = differenceInMinutes % 60;

    // return `${hours + ' hrs ' + minutes + ' mins'}`;
  }
  const getgeneralhours = async () => {
    // employees
    // designation
    // console.log(JSON.stringify(project))
    setloading(true)
    setgeneralemployeecheckindata([])
    let data = []
    if (project == "") {
let dataset = []
for (const key in employees) {
  dataset.push(employees[key].id)
}






        await getgeneralNestedItemdualcondition("slots", dataset, "checkindatetime", ">=", startdate.getTime(), "checkindatetime", "<=", enddate.getTime()).then(async (response) => {
          let responsedata = [...response]
          for (const index in responsedata) {

            responsedata[index]["name"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.firstname + " " + data.lastname })
            responsedata[index]["designation"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.projectdesignation })
            responsedata[index]["profilepicture"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.profilepicture })

          }
          data = [...data, ...responsedata]
        }).catch((err) => {
          console.log(err)
          setloading(false)
        })

    } else {
      let dataset = []
for (const key in project.employees) {
  dataset.push(project.employees[key])
}

        await getgeneralNestedItemdualcondition("slots", dataset, "checkindatetime", ">=", startdate.getTime(), "checkindatetime", "<=", enddate.getTime(), "projectid", "==", project?.id).then(async (response) => {


          let responsedata = [...response]
          for (const index in responsedata) {


            responsedata[index]["name"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.firstname + " " + data.lastname })
            responsedata[index]["designation"] =employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.projectdesignation })
            responsedata[index]["profilepicture"] = employees.filter(function (data) { return data.id == responsedata[index].employeeid }).map(function (data) { return data.profilepicture })

          }
          data = [...data, ...responsedata]
        }).catch((err) => {
          console.log(err)
          setloading(false)
        })

    }

    setgeneralemployeecheckindata(data)
    setloading(false)
  }

  const getallemployeejobs = async (id) => {
    await getAllEmployeeTasks("jobs", "assigned", "==", id).then(async (response) => {

      setemployeeprojects(response)
    }).catch((err) => {
      alert(err)
    })

  }


  const handleDownloadExcelGeneral = () => {
    let newdata = []
    generalemployeecheckindata.map((val, id) => {
      const data = clients.find(project => project.id === val?.projectid);
      const hoursWorked = data?.eveningPercentage || data?.normalPercentage || data?.nightPercentage ? calculateShiftHours(shiftmaker(data), val?.checkintime, val?.checkouttime) : {}
      const normalAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Normal?.hours, hoursWorked?.Normal?.minutes, val?.priceperhour, shifts[0]?.percentage) : 0
      const eveningAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Evening?.hours, hoursWorked?.Evening?.minutes, val?.priceperhour, shifts[1]?.percentage) : 0
      const nightAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Night?.hours, hoursWorked?.Night?.minutes, val?.priceperhour, shifts[2]?.percentage) : 0
      const totalSalary = (normalAmount > 0 ? normalAmount : 0) + (eveningAmount > 0 ? eveningAmount : 0) + (nightAmount > 0 ? nightAmount : 0)
      const mutiplicatorPrice = data?.multiplicatorPercentageStatus ? totalSalary * data.multiplicatorPercentage : 0
      const totalHours = val?.minutes ? parseInt(val?.minutes / 60) + " hrs " + parseInt(val?.minutes % 60) + " mins" : 0 + " mins"
      const fixedPrice = data?.fixedprice > 0 ? calculateFixedAmount(val?.minutes ? parseInt(val?.minutes / 60) : 0, val?.minutes ? parseInt(val?.minutes % 60) : 0, data.fixedprice) : 0
      designation.find(data => data.selected == true) ?
        designation.find(data => data.selected == true) == undefined || designation.find(data => data.selected == true && val.projectdesignation == data.designation) != undefined &&
        newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" ,  val.employeeid, val.name ? val.name : 'NA', project.title ? project.title : data ? data.title : 'NA', val.projectdesignation ? val.projectdesignation : 'NA', val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
        :
        newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" ,val.employeeid, val.name ? val.name : 'NA', project.title ? project.title : data ? data.title : 'NA', val.projectdesignation ? val.projectdesignation : 'NA', val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
    })
    // console.log(newdata);

    downloadExcel({
      fileName: "General Sheet",
      sheet: "General-Sheet",
      tablePayload: {
        header,
        body: newdata,
      },
    });
  }


  const handleDownloadExcelEmployeeData = () => {
    let newdata = []
    employeecheckindata.map((val, id) => {
      if(val?.checkintime != undefined && val?.checkouttime != undefined ){
      const data = clients.find(project => project.id === val?.projectid);
      const hoursWorked = data?.eveningPercentage || data?.normalPercentage || data?.nightPercentage ? calculateShiftHours(shiftmaker(data), val?.checkintime, val?.checkouttime) : {}
      const normalAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Normal?.hours, hoursWorked?.Normal?.minutes, val?.priceperhour, shifts[0]?.percentage) : 0
      const eveningAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Evening?.hours, hoursWorked?.Evening?.minutes, val?.priceperhour, shifts[1]?.percentage) : 0
      const nightAmount = val?.priceperhour ? calculateSalary(hoursWorked?.Night?.hours, hoursWorked?.Night?.minutes, val?.priceperhour, shifts[2]?.percentage) : 0
      const totalSalary = (normalAmount > 0 ? normalAmount : 0) + (eveningAmount > 0 ? eveningAmount : 0) + (nightAmount > 0 ? nightAmount : 0)
      const mutiplicatorPrice = data?.multiplicatorPercentageStatus ? totalSalary * data.multiplicatorPercentage : 0
      const totalHours = val?.minutes ? parseInt(val?.minutes / 60) + " hrs " + parseInt(val?.minutes % 60) + " mins" : 0 + " mins"
      const fixedPrice = data?.fixedprice > 0 ? calculateFixedAmount(val?.minutes ? parseInt(val?.minutes / 60) : 0, val?.minutes ? parseInt(val?.minutes % 60) : 0, data.fixedprice) : 0
      newdata.push([val?.hoursapproved == true ? "Approved" : val?.hoursapproved == false ? "Rejected" : "Pending" ,  employeedata.employeeid, employeedata?.firstname ? employeedata?.firstname + " " + employeedata?.lastname : 'NA', project.title ? project.title : data ? data.title : 'NA', val?.projectdesignation ? val?.projectdesignation : 'NA', val.checkindatetime ? moment(val.checkindatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchstarttime}` : 'NA', val.lunchendtime && val.lunchallowed ? `${val.sheduleddate + ' ' + val.lunchendtime}` : 'NA', val.checkoutdatetime ? moment(val.checkoutdatetime).format('YYYY-MM-DD hh:mm:ss a') : 'NA', val.lunchstarttime && val.lunchendtime && val.lunchallowed ? timeDifference(val.sheduleddate + ' ' + val.lunchstarttime, val.sheduleddate + ' ' + val.lunchendtime) : 'NA', totalHours, hoursWorked?.Normal ? hoursWorked.Normal.hours + ' hrs ' + hoursWorked.Normal.minutes + ' mins ' : 'NA', hoursWorked?.Evening ? hoursWorked.Evening.hours + ' hrs ' + hoursWorked.Evening.minutes + ' mins ' : 'NA', hoursWorked?.Night ? hoursWorked.Night.hours + ' hrs ' + hoursWorked.Night.minutes + ' mins ' : 'NA', normalAmount.toFixed(2), eveningAmount.toFixed(2), nightAmount.toFixed(2), totalSalary ? totalSalary.toFixed(2) : 0, mutiplicatorPrice.toFixed(2), fixedPrice.toFixed(2)])
   } })
    // console.log(newdata);
    downloadExcel({
      fileName: `${employeedata?.firstname ? employeedata?.firstname + " " + employeedata?.lastname : 'Employee Sheet'}`,
      sheet: "Employee-Sheet",
      tablePayload: {
        header,
        body: newdata,
      },
    });
  }
  // Define the shifts with their start and end times
  const shifts = [];
  function shiftmaker(data) {
    console.log(data);
    if (data?.normalstarttime && data?.normalstarttime) {
      shifts.push({ name: 'Normal', start: data?.normalstarttime, end: data?.normalendtime, percentage: data?.normalPercentage })
    }
    if (data?.eveningstarttime && data?.eveningstarttime) {

      shifts.push({ name: 'Evening', start: data?.eveningstarttime, end: data?.eveningendtime, percentage: data?.eveningPercentage })
    }
    if (data?.nightstarttime && data?.nightstarttime) {

      shifts.push({ name: 'Night', start: data?.nightstarttime, end: data?.nightendtime, percentage: data?.nightPercentage })
    }
    return shifts
  }

  // Convert time string to Date object for a given date
  // Convert time string to Date object for a given date
  function parseTime(timeStr, baseDate) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours, minutes);
  }

  // Calculate hours worked within a time range in hours and minutes
  function calculateOverlap(start1, end1, start2, end2) {
    const start = Math.max(start1.getTime(), start2.getTime());
    const end = Math.min(end1.getTime(), end2.getTime());

    if (start >= end) return { hours: 0, minutes: 0 }; // No overlap

    const overlapMillis = end - start;
    const hours = Math.floor(overlapMillis / (1000 * 60 * 60));
    const minutes = Math.floor((overlapMillis % (1000 * 60 * 60)) / (1000 * 60));
    return { hours, minutes };
  }

  // Calculate hours worked in each shift and return in hours and minutes
  function calculateShiftHours(shifts, workStartTime, workEndTime) {
    let shiftHours = {};

    const baseDate = new Date(); // Use current date as base

    const workStart = parseTime(workStartTime, baseDate);
    const workEnd = parseTime(workEndTime, baseDate);

    shifts.forEach(shift => {
      const shiftStart = parseTime(shift.start, baseDate);
      const shiftEnd = parseTime(shift.end, baseDate);

      if (shift.start > shift.end) {
        // Shift spans overnight
        const shiftEndMidnight = new Date(baseDate);
        shiftEndMidnight.setDate(shiftEndMidnight.getDate() + 1);
        shiftEndMidnight.setHours(shiftEnd.getHours());
        shiftEndMidnight.setMinutes(shiftEnd.getMinutes());

        const overlap1 = calculateOverlap(workStart, workEnd, shiftStart, shiftEndMidnight);
        const overlap2 = calculateOverlap(workStart, workEnd, new Date(baseDate), shiftEnd);

        shiftHours[shift.name] = {
          hours: overlap1.hours + overlap2.hours,
          minutes: overlap1.minutes + overlap2.minutes
        };
      } else {
        // Shift within a single day
        const overlap = calculateOverlap(workStart, workEnd, shiftStart, shiftEnd);
        shiftHours[shift.name] = overlap;
      }
    });

    // Convert minutes to hours if greater than 60
    for (const shift in shiftHours) {
      const shiftTime = shiftHours[shift];
      if (shiftTime.minutes >= 60) {
        shiftTime.hours += Math.floor(shiftTime.minutes / 60);
        shiftTime.minutes = shiftTime.minutes % 60;
      }
    }

    return shiftHours;
  }
  function calculateSalary(hoursWorked, minutesWorked, perHourRate, percentage) {
    // console.log(hoursWorked, minutesWorked, perHourRate, percentage);
    // Convert total hours and minutes into total hours
    const totalHours = hoursWorked + (minutesWorked / 60);


    // Apply percentage adjustment
    const adjustmentAmount = perHourRate * (percentage / 100);
    // Calculate base salary

    // const baseSalary = totalHours * perHourRate;
    const finalSalary = totalHours * adjustmentAmount;
    // console.log(totalHours, adjustmentAmount);
    return finalSalary ? finalSalary : 0;
  }
  function calculateFixedAmount(hours, minutes, perHourRate) {
    // Convert minutes to hours
    const minutesInHours = minutes / 60;

    // Total hours including minutes
    const totalHours = hours + minutesInHours;
    // Calculate total amount
    const totalAmount = totalHours * perHourRate;

    return totalAmount ? totalAmount : 0;
  }


  function SupervisorPermissions(){
    return(
      employeedata.usertype == "Supervisor" &&  user.usertype == "Admin"  ||  employeedata.usertype == "Supervisor" && user.usertype == "SuperAdmin"  ?
        <div className='w-full flex flex-row justify-evenly items-center  mt-[40px]'>
        <div className='flex flex-col items-center'>
        <p className='text-textcolor/60 PoppinsRegular font-semibold text-sm pb-[5px]'>Delete Access</p>
        <Switch
        height = {25}
        width = {50}
        checkedIcon = {false} uncheckedIcon = {false} onChange={()=>{updateemployeedata("deleteaccess", employeedata?.deleteaccess ? false : true, employeedata.id )}} checked={employeedata?.deleteaccess}  onColor= {"#1F2E5E"}/>
        </div>
        <div className='flex flex-col items-center'>
        <p className='text-textcolor/60 PoppinsRegular font-semibold text-sm pb-[5px]'>Edit Access</p>
        <Switch

        height = {25}
        width = {50}
        checkedIcon = {false} uncheckedIcon = {false} onChange={()=>{updateemployeedata("editaccess", employeedata?.editaccess ? false : true, employeedata.id)}} checked={employeedata?.editaccess}  onColor= {"#1F2E5E"}/>
        </div>
        <div className='flex flex-col items-center '>
        <p className='text-textcolor/60 PoppinsRegular font-semibold text-sm pb-[5px]'>Worker Role</p>
        <Switch
        height = {25}
        width = {50}
        checkedIcon = {false} uncheckedIcon = {false} onChange={()=>{updateemployeedata("employeerole", employeedata?.employeerole ? false : true, employeedata.id)}} checked={employeedata?.employeerole}  onColor= {"#1F2E5E"}/>
        </div>
        </div>
:null
    )
  }




  function DiabledDate(){
    return(
      employeedata?.disabled &&
        <p className=' text-red-500 PoppinsRegular font-semibold text-sm'>{`Disabled On: ${moment(employeedata.diableddate).format("DD-MM-YYYY")}`}</p>

    )
  }


  // Example usage
  // console.log(clients, 'newd');
  // console.log(employeecheckindata, 'ppp');
// console.log(designation);
  return (
    <div className='w-[100%] mt-[30px] md:mt-[0px] px-[10px]  flex flex-col justify-center relative'>
      <div className=' md:mx-[30px] '>
      <div className='w-full flex flex-row justify-between  md:pt-[20px]  pb-[10px] mt-[80px]  '>

        <div className='flex flex-row items-center'>
          {/* <p
            onClick={() => { setfiltertype("General") }}
            className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${filtertype == "General" ? "text-themeColor font-medium text-[15px]" : "text-black"}`} >General</p>
          <p className='mx-[10px]'>|</p> */}
          <p
            onClick={() => { setfiltertype("Active") }}
            className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${filtertype == "Active" ? "text-themeColor font-medium text-[15px]" : "text-black"} `}>Active</p>
            <p className='mx-[10px]'>|</p>
             <p
            onClick={() => { setfiltertype("InActive") }}
            className={`PoppinsRegular text-[14px] font-weight: 400  hover:cursor-pointer hover:underline  ${filtertype == "InActive" ? "text-themeColor font-medium text-[15px]" : "text-black"}`} >InActive</p>

        </div>
        {filtertype == "Active" &&
        <div>
        <FaPlus

        onClick={() => {
          navigate('/UpdateSlot');


        }} color='green' size={'20'} className={`mt-1.5 hover:cursor-pointer`} />
        </div>
}
      </div>
</div>

<Confirmationpopup
visible = {employeedeletionconfirmation}
picture = {employeedata?.profilepicture}
text = {`Are you sure you want to ${employeedata?.disabled ? "enable" : "disable"} ${employeedata?.firstname + " " + employeedata?.lastname}?`}
accept = {()=>{
  setemployeedeletionconfirmation(false)
  if(employeedata?.disabled){
    disableemployeetoggle(employeedata, false)
  }else{
    disableemployeetoggle(employeedata, true)
  }


}}
cancel = {()=>{
setemployeedeletionconfirmation(false)
}}

/>

      {filtertype == "Active" || filtertype == "InActive" ?
        <>

          <div className='hidden lg:flex flex-row border border-gray-300 rounded-xl  mx-[30px]    bg-white  drop-shadow-lg  '>


            <div className='w-[25%] bg-themeColor rounded-s-xl overflow-y-scroll max-h-screen'>
              {employees.map((item, index) => (
                filtertype == "Active" && item.disabled != true ||  filtertype == "InActive" && item.disabled == true ?
                <div
                key = {index.toString()}
                  onClick={() => {
                    setemployeeid(item.id)
                    setemployeedata(item)

                  }}
                  className='w-[100%] hover:bg-white cursor-pointer py-4 border-b border-gray-300 flex flex-row items-center px-5 text-white  hover:text-textcolor'>
                  <img src={item.profilepicture} className='w-[35px] h-[35px] object-cover rounded-full' />
                  <div className='flex flex-col'>
                    <p className='PoppinsRegular font-bold text-base pl-3 '>{item.firstname + " " + item.lastname}</p>
                  </div>

                </div> : null
              ))}
            </div>
            <div className='w-[75%]'>
              <div className='w-[95%] mx-auto mt-10 '>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:gap-8">

                  <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 lg:p-8  bg-cardOverlay  drop-shadow-lg">
                    <p className="text-xl text-textcolor PoppinsRegular font-semibold sm:text-2xl md:text-lg text-center">Today's Work</p>
                    <div className="text-sm font-semibold sm:text-base text-textcolor/50 PoppinsRegular">{todayshours + " hrs"}</div>
                  </div>

                  <div className="flex flex-col items-center justify-center rounded-lg  p-4 md:p-8 bg-gray-100 bg-cardOverlay  drop-shadow-lg">
                    <div className="text-xl text-textcolor PoppinsRegular font-semibold sm:text-2xl md:text-lg text-center">Week's Work</div>
                    <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular sm:text-base">{weeklyhours + " hrs"}</div>
                  </div>


                  <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
                    <div className="text-xl text-textcolor PoppinsRegular font-semibold sm:text-2xl md:text-lg text-center">Month's Work</div>
                    <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular sm:text-base">{monthlyhours + " hrs"}</div>
                  </div>

                  <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
                    <div className="text-xl text-textcolor PoppinsRegular font-semibold sm:text-2xl md:text-lg text-center">Total Work</div>
                    <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular sm:text-base">{alltimehours + " hrs"}</div>
                  </div>

                </div>
              </div>
              <div className='flex flex-col w-full items-center justify-center my-14'>
              <div className='flex flex-row justify-end w-full px-[25px] mb-[20px] '>
<div
                  className='px-[20px] py-[5px] bg-themeColor rounded-md  hover:cursor-pointer hover:bg-themeColor/80'>
                  <p onClick={() => {
                      navigate('/dashboard', {
                        state: {
                            employeedata: employeedata
                        }
                    });
                  }}
                    className='text-sm font-semibold text-white PoppinsRegular '>Edit</p>
                </div>
              {employeecheckindata?.length > 0 &&
                <div
                className='px-[20px] py-[5px] bg-themeColor rounded-md ml-[10px] hover:cursor-pointer hover:bg-themeColor/80'>
                  <p onClick={() => handleDownloadExcelEmployeeData()}
                    className='text-sm font-semibold text-white PoppinsRegular '>Export</p>
                </div>
              }
              </div>
                <div className='flex flex-col items-center w-full'>
                  <img src={employeedata?.profilepicture} className='w-[130px] h-[130px] rounded-full object-cover  bg-cardOverlay  drop-shadow-lg' />
                  {employeedata ?
                    <div className='flex flex-col text-center pt-2 w-full'>
                      <p className='text-textcolor PoppinsRegular font-bold text-xl'>{employeedata?.firstname + " " + employeedata?.lastname}</p>
                      <p className='text-textcolor/60 PoppinsRegular font-semibold text-sm'>{employeedata?.designation}</p>
                {DiabledDate()}
{SupervisorPermissions()}



                    </div>
                    :
                    <div className='flex flex-col text-center pt-2'>
                      <p className='text-textcolor PoppinsRegular font-bold text-xl'>{"No User Found"}</p>

                    </div>
                  }
                </div>

              </div>


              <div className='w-full px-[10px] flex flex-row justify-between items-center'>

                <div
                  className='w-[32%] relative'>
                  <div
                  className='flex flex-row items-end  pb-[10px]'>
                    <p className='PoppinsRegular font-medium  text-textcolor/60 text-sm'>Select Project</p>
                    {project.title && <p
                      onClick={() => {
                        setprojectselectionpopup2(false)
                        setproject("")

                      }}
                      className='ml-[5px] text-[13px] text-red-600 cursor-pointer hover:underline'>Clear</p>}
                  </div>
                  <div
                    onClick={() => {
                      setprojectselectionpopup2(!projectselectionpopup2)}}
                      className="px-4 text-textcolor text-sm font-medium PoppinsRegular border border-gray-300 w-full py-[10px] rounded-xl items-center flex bg-gray-100 bg-cardOverlay cursor-pointer">{project == "" ? "Select Project" : project.title}</div>



                    <ProjectPopUp
       style= {{top: "80px"}}
visible ={projectselectionpopup2}
data = {clients}

activateoutsidecontainerclick = {()=>{
  setprojectselectionpopup2(false);
}}




            onClickcorrect={(item) => {

              setproject(item)
              let data = { ...item }
              if (data?.designation?.length > 0) {
                for (const key in data.designation) {
                  data.designation[key]["selected"] = false
                }
              }
              setdesignation(data.designation)


              setprojectselectionpopup2(!projectselectionpopup2)
            }}
/>

                </div>

                <div className='w-[32%]'>
                  <p className='PoppinsRegular font-medium text-textcolor/60 text-sm pb-[10px]'>From</p>
                  <DatePicker

                    className='PoppinsRegular font-medium border border-gray-300 bg-gray-100 bg-cardOverlay  cursor-pointer   rounded-xl w-full' selected={startdate} onChange={(date) => setstartdate(new Date(date.setHours(0, 0, 0, 0)))} />
                </div>
                {/* <text className='mx-[50px]'>to</text> */}
                <div className='w-[32%]'>
                  <p className='PoppinsRegular font-medium text-textcolor/60 text-sm pb-[10px]'>To</p>
                  <DatePicker className='PoppinsRegular font-medium border border-gray-300 bg-gray-100 bg-cardOverlay   cursor-pointer  rounded-xl w-full' selected={enddate} onChange={(date) => setenddate(date)} />
                </div>
              </div>

              <div className='flex flex-col w-full px-[10px] mt-[10px]'>


                {/* <div

              //   className='w-[100%] border border-black flex flex-row items-center justify-between px-[5px]'>
              //   <div className='flex flex-col items-center'>
              //     <text className='text-black text-[15px]'>{"Date"}</text>
              //     <text className='text-black text-[15px]'>{item.checkindate}</text>
              //   </div>
              //   <div className='flex flex-col items-center'>
              //     <text className='text-black text-[15px]'>{"Checkin Time"}</text>
              //     <text className='text-black text-[15px]'>{item.checkintime}</text>
              //   </div>

              //   <div className='flex flex-col items-center'>
              //     <text className='text-black text-[15px]'>{"Checkout Time"}</text>
              //     <text className='text-black text-[15px]'>{item.checkouttime ? item.checkouttime : "Not Available"}</text>
              //   </div>
              //   <div className='flex flex-col items-center'>
              //     <text className='text-black text-[15px]'>{"Hours Worked"}</text>
              //     <text className='text-black text-[15px]'>{item?.minutes ? parseInt(item?.minutes / 60) : 0}</text>
              //   </div>
              //   <div className='flex flex-col items-center'>
              //     <text className='text-black text-[15px]'>{"Checkin Location"}</text>
              //     <div
              //       onClick={() => {
              //         getlocationaddress(item?.checkinlatitude, item?.checkinlongitude)
              //       }}
              //     >
              //       <text className='text-black text-[15px]'>View</text>
              //     </div>
              //   </div>
              //   <div className='flex flex-col items-center'>
              //     <text className='text-black text-[15px]'>{"Checkout Location"}</text>
              //     <div
              //       onClick={() => {
              //         getlocationaddress(item?.checkoutlatitude, item?.checkoutlongitude)
              //       }}
              //     >
              //       <text className='text-black text-[15px]'>View</text>
              //     </div>
              //   </div>

              // </div> */}


                <div className="overflow-x-auto rounded-lg border border-gray-300 bg-gray-100 bg-cardOverlay  mb-[10px]  ">
                  <table className="min-w-full divide-y-2 divide-gray-200 text-sm ">
                    <thead className="ltr:text-left rtl:text-right">
                      <tr>

                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Date</th>
                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkin Time</th>
                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkout Time</th>
                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Hours Worked</th>
                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkin Location</th>
                        <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkout Location</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 text-center">
                      {employeecheckindata.map((item, index) => (
                        <tr
                        key = {index.toString()}
                        className={` cursor-pointer ${item?.hoursapproved == false ? "bg-red-500/10" : "hover:bg-[#0989B826]/10"}`}>
                       {user.usertype == "Supervisor" && user?.editaccess == true || user.usertype == "Admin" || user.usertype == "SuperAdmin" ?

                         item?.hoursapproved != false ?
                         <td
                            className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium ">
                              {/* //  <text className=" text-lighttextGray hover:text-themeColor   w-[17px] h-[17px] hover:cursor-pointer">Manual Entry</text> */}


                              {/* //        <MdDelete */}
                              {/* // onClick={()=>{
                              //   navigate('/UpdateSlot', {state: {
                              //     edit: true,
                              //     editdoc: item,
                              //     projectid: item.projectid,
                              //     employeedata: employeedata,
                              //     projectdesignation: item.projectdesignation,

                              //   }});

                              // }}
                              // className=" text-lighttextGray hover:text-themeColor   w-[17px] h-[17px] hover:cursor-pointer"
                              // /> */}

                              <MdEditDocument
                                onClick={() => {
                                  navigate('/UpdateSlot', {
                                    state: {
                                      edit: true,
                                      editdoc: item,
                                      projectid: item.projectid,
                                      employeedata: employeedata,
                                      projectdesignation: item.projectdesignation,

                                    }
                                  });

                                }}
                                className=" text-lighttextGray hover:text-themeColor   w-[17px] h-[17px] hover:cursor-pointer"
                              />

                          </td> : <td></td>: <td></td>}
                          <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                            {moment(item.checkindate).format("DD-MM-YYYY")}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                            {item.checkintime ? item.checkintime : "Not Available"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                            {item.checkouttime ? item.checkouttime : "Not Available"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                            {item?.minutes ? parseInt(item?.minutes / 60) + " hrs " + parseInt(item?.minutes % 60) + " mins" : 0 + " mins"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                            onClick={() => {
                              getlocationaddress(item?.checkinlatitude, item?.checkinlongitude)
                            }}>
                            {item?.checkinlatitude ? "View" : "Not Available"}

                          </td>
                          <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                            onClick={() => {
                              getlocationaddress(item?.checkoutlatitude, item?.checkoutlongitude)
                            }}>
                            {item?.checkoutlatitude ? "View" : "Not Available"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>


              </div>
              {employeedata &&
              <div className=' w-full px-[10px] mt-[10px] mb-[10px]'>
              {disableloader ?
                <div
                className=" py-4  mt-6  w-full flex justify-center  "
                >
                    <RotatingLines
                        visible={true}
                        height="25"
                        width="25"
                        color="grey"
                        strokeColor="#FF0000"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                :
                <button
                    onClick={() => {

setemployeedeletionconfirmation(true)
                    }}
                    type='submit' className={`text-center py-4 rounded-lg  ${employeedata?.disabled ? "bg-green-600 hover:bg-green-600/80" : "bg-red-600 hover:bg-red-600/80"} w-full  mx-auto text-white text-sm font-semibold PoppinsRegular `}>
                   {employeedata?.disabled ? "Enable Employee" : "Disable Employee"}
                </button>
            }
            </div>
}

            </div>

          </div>

          {/* mobile view */}
          <div className='lg:hidden border border-gray-300 rounded-xl mb-20   bg-white  drop-shadow-lg'>

            {!employeeid ?
              <div>
                {employees.map((item, index) => (
                  filtertype == "Active" && item.disabled != true ||  filtertype == "InActive" && item.disabled == true ?
                  <div
                  key = {index.toString()}
                    onClick={() => {
                      setemployeeid(item.id)
                      setemployeedata(item)
                    }}
                    className='w-[100%] text-textcolor hover:text-white hover:bg-themeColor cursor-pointer py-4 border-b border-gray-300 flex flex-row items-center px-5'>
                    <img src={item.profilepicture} className='w-[35px] h-[35px] object-cover rounded-full' />
                    <div className='flex flex-col'>
                      <p className=' PoppinsRegular font-bold text-base pl-3'>{item.firstname + " " + item.lastname}</p>
                    </div>

                  </div> : null
                )
                )}
              </div>
              :
              <div className='mt-[30px] lg:mt-10'>
                <div
                  className=' cursor-pointer w-[95%] mx-auto  flex '
                  onClick={() => {

                    setemployeeid(0)
                    setemployeedata("")
                  }}>
                  <HiArrowLeft
                    className={` text - themeColor text - [25px]`}
                  />
                </div>
                <div className='w-[95%] mx-auto mt-5 lg:mt-10'>
                  <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:gap-8">

                    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 lg:p-8  bg-cardOverlay  drop-shadow-lg">
                      <p className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Today's Work</p>
                      <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{todayshours + " hrs"}</div>
                    </div>

                    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
                      <div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Weeks's Work</div>
                      <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{weeklyhours + " hrs"}</div>
                    </div>


                    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
                      <div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Months's Work</div>
                      <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{monthlyhours + " hrs"}</div>
                    </div>

                    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
                      <div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Total Work Time</div>
                      <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{alltimehours + " hrs"}</div>
                    </div>

                  </div>
                </div>
                <div className='flex flex-col w-full items-center justify-center my-12'>
                <div className='flex flex-row justify-end w-full px-[25px] mb-[40px] '>
<div
                  className='px-[20px] py-[5px] bg-themeColor rounded-md  hover:cursor-pointer hover:bg-themeColor/80'>
                  <p onClick={() => {
                      navigate('/dashboard', {
                        state: {
                            employeedata: employeedata
                        }
                    });
                  }}
                    className='text-sm font-semibold text-white PoppinsRegular '>Edit</p>
                </div>
              {employeecheckindata?.length > 0 &&
                <div
                className='px-[20px] py-[5px] bg-themeColor rounded-md ml-[10px] hover:cursor-pointer hover:bg-themeColor/80'>
                  <p onClick={() => handleDownloadExcelEmployeeData()}
                    className='text-sm font-semibold text-white PoppinsRegular '>Export</p>
                </div>
              }
              </div>
                  <div className='flex flex-col items-center w-full'>
                    <img src={employeedata?.profilepicture} className='w-[130px] h-[130px] rounded-full object-cover  bg-cardOverlay  drop-shadow-lg' />
                    <div className='flex flex-col text-center pt-2 w-full'>
                      <p className='text-textcolor PoppinsRegular font-bold text-xl'>{employeedata?.firstname + " " + employeedata?.lastname}</p>
                      <p className='text-textcolor/60 PoppinsRegular font-semibold text-sm'>{employeedata?.designation}</p>
                      {DiabledDate()}
{SupervisorPermissions()}
                    </div>
                  </div>
                </div>
                <div className='w-[90%] lg:w-[95%] mx-auto flex flex-row flex-wrap justify-between items-center'>
                  <div

                    className='w-full lg:w-[32%] relative'>
                    <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Select Project</p>
                    <div
                        onClick={() => setprojectselectionpopup(!projectselectionpopup)}
                      className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex">{project == "" ? "Select Project" : project.title}</div>


                      <ProjectPopUp
    style= {{top: "80px"}}

visible ={projectselectionpopup}
data = {clients}

activateoutsidecontainerclick = {()=>{
  setprojectselectionpopup(false);
}}



            onClickcorrect={(item) => {
              setproject(item)
              let data = { ...item }
              if (data?.designation?.length > 0) {
                for (const key in data.designation) {
                  data.designation[key]["selected"] = false
                }
              }

              setdesignation(data.designation)


            }}
/>
                  </div>

                  <div className='w-full lg:w-[32%] mt-4 lg:mt-0'>
                    <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>From</p>
                    <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={startdate} onChange={(date) => setstartdate(new Date(date.setHours(0, 0, 0, 0)))} />
                  </div>
                  <div className='w-full lg:w-[32%] mt-4 lg:mt-0'>
                    <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>To</p>
                    <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={enddate} onChange={(date) => setenddate(date)} />
                  </div>
                </div>
                <div className='w-[90%] lg:w-[95%] mx-auto flex flex-row flex-wrap justify-between items-center'>
                  <div className="overflow-x-auto rounded-lg border border-gray-200 my-5 w-full ">
                    <table className="max-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
                      <thead className="ltr:text-left rtl:text-right">
                        <tr>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Date</th>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkin Time</th>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkout Time</th>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Hours Worked</th>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkin Location</th>
                          <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Checkout Location</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 text-center">
                        {employeecheckindata.map((item, index) => (
                          <tr
                          key = {index.toString()}
                          className='hover:bg-[#0989B826]/10 cursor-pointer'>
                            <td
                              className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium ">
                              <MdEditDocument
                                onClick={() => {

                                  navigate('/UpdateSlot', {
                                    state: {
                                      edit: true,
                                      editdoc: item,
                                      projectid: item.projectid,
                                      employeedata: employeedata,
                                      projectdesignation: item.projectdesignation,

                                    }
                                  });

                                }}
                                className=" text-lighttextGray hover:text-themeColor   w-[17px] h-[17px] hover:cursor-pointer"
                              />
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {moment(item.checkindate).format("DD-MM-YYYY")}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.checkintime ? item.checkintime : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item.checkouttime ? item.checkouttime : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
                              {item?.minutes ? parseInt(item?.minutes / 60) : 0}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                              onClick={() => {
                                getlocationaddress(item?.checkinlatitude, item?.checkinlongitude)
                              }}>
                              {item?.checkinlatitude ? "View" : "Not Available"}
                            </td>
                            <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
                              onClick={() => {
                                getlocationaddress(item?.checkoutlatitude, item?.checkoutlongitude)
                              }}>
                              {item?.checkoutlatitude ? "View" : "Not Available"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {employeedata &&
              <div className='w-[90%] lg:w-[95%] mx-auto  mb-[10px]'>
              {disableloader ?
                <div
                className=" py-4  mt-6  w-full flex justify-center  "
                >
                    <RotatingLines
                        visible={true}
                        height="25"
                        width="25"
                        color="grey"
                        strokeColor="#FF0000"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                :
                <button
                    onClick={() => {

setemployeedeletionconfirmation(true)
                    }}
                    type='submit' className={`text-center py-4 rounded-lg  ${employeedata?.disabled ? "bg-green-600 hover:bg-green-600/80" : "bg-red-600 hover:bg-red-600/80"} w-full  mx-auto text-white text-sm font-semibold PoppinsRegular `}>
                   {employeedata?.disabled ? "Enable Employee" : "Disable Employee"}
                </button>
            }
            </div>
}

              </div>
            }
          </div>


        </>
        :
        null
//         <div className=' border border-gray-300 rounded-xl mb-20   bg-white  drop-shadow-lg md:mx-[30px] '>
//           {generalemployeecheckindata?.length > 0 &&
//             <div
//               className='download-table-xls-button'>
//               <p
//                 onClick={() => handleDownloadExcelGeneral()}
//                 className='text-sm font-semibold download-text PoppinsRegular  bg-themeColor'>Export</p>
//             </div>
//           }

//           <div className='mt-[30px] lg:mt-10'>

//             {/* <div className='w-[95%] mx-auto mt-5 lg:mt-10'>
// <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:gap-8">

// <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 lg:p-8  bg-cardOverlay  drop-shadow-lg">
// <p className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Today's Work</p>
// <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{todayshours + " hrs"}</div>
// </div>

// <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
// <div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Weeks's Work</div>
// <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{weeklyhours + " hrs"}</div>
// </div>


// <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
// <div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Months's Work</div>
// <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{monthlyhours + " hrs"}</div>
// </div>

// <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-4 md:p-8  bg-cardOverlay  drop-shadow-lg">
// <div className="text-base text-textcolor PoppinsRegular font-semibold md:text-lg">Total Work Time</div>
// <div className="text-sm font-semibold text-textcolor/50 PoppinsRegular">{alltimehours + " hrs"}</div>
// </div>

// </div>
// </div> */}
//             {/* <div className='flex w-full items-center justify-center my-12'>
// <div className='flex flex-col items-center'>
// <img src={employeedata?.profilepicture} className='w-[130px] h-[130px] rounded-full object-cover  bg-cardOverlay  drop-shadow-lg' />
// <div className='flex flex-col text-center pt-2'>
// <text className='text-textcolor PoppinsRegular font-bold text-xl'>{employeedata?.firstname + " " + employeedata?.lastname}</text>
// <text className='text-textcolor/60 PoppinsRegular font-semibold text-sm'>{employeedata?.designation}</text>
// </div>
// </div>
// </div> */}

//             <div className='w-[90%] lg:w-[95%] mx-auto flex flex-row flex-wrap justify-between items-center'>

//               <div
//                 className='w-full lg:w-[24%]'>

//                 <div
//                   className='flex flex-row items-end justify-between pb-1'>
//                   <p className='PoppinsRegular font-medium text-textcolor/80 text-sm '>Select Project</p>
//                   {project != "" && <p
//                     onClick={() => {

//                       setproject("")
//                       setdesignation([])

//                     }}
//                     className='ml-[5px] text-[13px] text-red-600 cursor-pointer hover:underline'>Clear</p>}
//                 </div>
//                 <div
//                   onClick={() => setprojectselectionpopup(!projectselectionpopup)}
//                   className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex">{project == "" ? "Select Project" : project.title}</div>
//                 {projectselectionpopup &&
//                   <div
//                   ref ={projectselectionpopupref}
//                   className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
//                     {clients.map((item, index) =>
//                     (

//                       <div
//                       key = {index.toString()}
//                         onClick={() => {
//                           setproject(item)
//                           let data = { ...item }
//                           if (data?.designation?.length > 0) {
//                             for (const key in data.designation) {
//                               data.designation[key]["selected"] = false
//                             }
//                           }


//                           setdesignation(data.designation)
//                           setprojectselectionpopup(false)
//                         }}
//                         className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-themeColor hover:text-white text-textcolor hover:px-[10px] hover:rounded-xl`}>
//                         <img src={item.logo} className='w-[25px] h-[25px] rounded-full object-cover' />
//                         <p className=' font-medium PoppinsRegular text-sm ml-[10px]'>{item.title}</p>
//                       </div>
//                     ))}
//                   </div>}
//               </div>
//               {project != "" && designation?.length > 0 &&
//                 <div

//                   className='w-full lg:w-[24%]'>


//                   <div

//                     className='flex flex-row items-end justify-between pb-1 mt-4 lg:mt-0'>
//                     <p className='PoppinsRegular font-medium text-textcolor/80 text-sm '>Designation</p>
//                     {designation?.length > 0 && <p
//                       onClick={() => {

//                         setdesignation([])

//                       }}
//                       className='ml-[5px] text-[13px] text-red-600 cursor-pointer hover:underline'>Clear</p>}
//                   </div>

//                   <div

//                     onClick={() => setdesignationselectionpopup(!designationselectionpopup)}
//                     className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-[10px] rounded-xl border border-themeColor items-center flex flex-wrap" >{designation?.length == 0 || designation.find(data => data.selected == true) == undefined ? "Select Designation" : designation.filter(function (data) { return data.selected == true }).map(function (data) {
//                       return <div
//                         onClick={() => {
//                           let dataset = [...designation]
//                           const designationindex = dataset.findIndex(item => item.designation === data.designation);
//                           dataset[designationindex].selected = false
//                           setdesignation(dataset)
//                         }}
//                         className='bg-themeColor px-[5px] py-[1px] mr-[5px] rounded-md flex flex-row items-center mb-[2px]'><text className='text-white '>{data.designation}</text>
//                         <IoIosCloseCircle

//                           className=" text-white ml-[3px]  w-[15px] h-[15px] hover:cursor-pointer"
//                         /></div>
//                     })}


//                   </div>
//                   {designationselectionpopup &&
//                     <div
//                     ref={designationselectionpopupref}
//                     className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
//                       {designation.map((item, index) =>
//                       (

//                         <div
//                         key = {index.toString()}
//                           onClick={() => {
//                             let data = [...designation]
//                             data[index].selected = !data[index].selected
//                             //         data.designation[index].selected = !data.designation[index].selected
//                             // setproject(data)

//                             setdesignation(data)
//                           }}
//                           className={` flex flex-row items - center justify - between w - full py - [5px] cursor - pointer hover: bg - themeColor hover: text - white text - textcolor hover: px - [10px] hover: rounded - xl`}>
//                           <p className=' font-medium PoppinsRegular text-sm ml-[10px]'>{item.designation}</p>
//                           {item.selected &&
//                             <IoCheckmarkCircle

//                               className="   w-[20px] h-[20px]"
//                             />


//                           }

//                         </div>
//                       ))}
//                     </div>}


//                 </div>
//               }
//               <div className='w-full lg:w-[24%] mt-4 lg:mt-0'>
//                 <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>From</p>
//                 <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={startdate} onChange={(date) => setstartdate(new Date(date.setHours(0, 0, 0, 0)))} />
//               </div>
//               <div className='w-full lg:w-[24%] mt-4 lg:mt-0'>
//                 <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>To</p>
//                 <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={enddate} onChange={(date) => setenddate(date)} />
//               </div>
//             </div>
//             <div className='w-[90%] lg:w-[95%] mx-auto flex flex-row flex-wrap justify-between items-center'>
//               <div className="overflow-x-auto rounded-lg border border-gray-200 my-5 w-full ">
//                 <table className="max-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
//                   <thead className="ltr:text-left rtl:text-right">
//                     <tr>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 "></th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Date</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-10 "></th>
//                       {/* <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Employee ID</th> */}
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Employee Name</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Designation</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkin Time</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkout Time</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Hours Worked</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkin Location</th>
//                       <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor px-4 ">Checkout Location</th>
//                     </tr>
//                   </thead>
//                   <tbody className="divide-y divide-gray-200 text-center w-full">
//                     {loading ?
//                       <div
//                         className=' py-10  w-full   flex items-center justify-center absolute'>
//                         <RotatingLines
//                           visible={true}
//                           height="25"
//                           width="25"
//                           strokeColor="#218fdb"

//                           strokeWidth="5"
//                           animationDuration="0.75"
//                           ariaLabel="rotating-lines-loading"
//                           wrapperStyle={{}}
//                           wrapperClass=""
//                         />
//                       </div>
//                       :

//                       generalemployeecheckindata.map((item, index) => (
//                         designation.find(data => data.selected == true) == undefined || designation.find(data => data.selected == true && item.projectdesignation == data.designation) != undefined ?
//                           <tr
//                           key = {index.toString()}
//                           className='hover:bg-[#0989B826]/10 cursor-pointer'>
//                             <td
//                               className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium ">
//                               <MdEditDocument
//                                 onClick={() => {

//                                   navigate('/UpdateSlot', {
//                                     state: {
//                                       edit: true,
//                                       editdoc: item,
//                                       projectid: item.projectid,
//                                       employeedata: employeedata,
//                                       projectdesignation: item.projectdesignation,

//                                     }
//                                   });

//                                 }}
//                                 className=" text-lighttextGray hover:text-themeColor   w-[17px] h-[17px] hover:cursor-pointer"
//                               />
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
//                               {moment(item.checkindate).format("DD-MM-YYYY")}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor flex justify-center ">
//                               <img src={item.profilepicture} className='w-[35px] h-[35px]  object-cover rounded-full' />
//                             </td>
//                             {/* <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
//       {item.employeeid}
//     </td> */}


//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
//                               {item.name}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor  ">
//                               {item.projectdesignation}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
//                               {item.checkintime ? item.checkintime : "Not Available"}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
//                               {item.checkouttime ? item.checkouttime : "Not Available"}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">
//                               {item?.minutes ? parseInt(item?.minutes / 60) : 0}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
//                               onClick={() => {
//                                 getlocationaddress(item?.checkinlatitude, item?.checkinlongitude)
//                               }}>
//                               {item?.checkinlatitude ? "View" : "Not Available"}
//                             </td>
//                             <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor"
//                               onClick={() => {
//                                 getlocationaddress(item?.checkoutlatitude, item?.checkoutlongitude)
//                               }}>
//                               {item?.checkoutlatitude ? "View" : "Not Available"}
//                             </td>
//                           </tr>
//                           : null
//                       ))
//                     }
//                   </tbody>
//                 </table>
//               </div>
//             </div>

//           </div>

//         </div>
      }


    </div>
  );
}

export default HoursWorked;