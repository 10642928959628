import React, {useEffect, useRef, useState} from 'react'
import './index.css'
import { getAllEmployeeTasks, getuserinformation, saveitem, updateItem, updateNestedItem} from '../../utils/firebasefunctions';
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { CiCirclePlus } from 'react-icons/ci';
import { FaCircleMinus } from 'react-icons/fa6';
import { RotatingLines } from 'react-loader-spinner'

function ProjectDesignations() {
  const [{user, employees}, dispatch] = useStateValue();
  const location = useLocation()

  const [projectid, setprojectid] = useState(location.state?.projectid ? location.state.projectid : "");

  const [selectedemployee, setselectedemployee] = useState("");

  const [loading, setloading] = useState(false);
  const [slots, setslots] = useState([]);






useEffect(()=>{
  getallprojectdesignations()
}, [])

const createdesignation = async () =>{

    //  if (slots.filter(function (data) { return data.designation == ""})){
    //     toast.warn("A designation field can not be empty!")
    // }else{
        setloading(true)

let data = {
    designation: slots,
}

await updateItem("users", projectid != "" ? projectid : user?.id, data).then(async(response) => {
  let object = {...user}
  object["designation"] = slots

 dispatch({
  type: actionType.UPDATEUSER,
  payload: object
})

  toast.success("Updates Successfully!")
  setloading(false)

   }).catch((err)=>{
       toast.warn(err)
       setloading(false)
   })

}

const handledesignationChange = (event, index) => {

  let data = [...slots]
  data[index].designation = event.target.value
  setslots(data)


}
const getallprojectdesignations = async () => {
  await getuserinformation("users", projectid != "" ? projectid : user?.id).then(async (response) => {
    if(response.designation != undefined){
      setslots(response.designation)
    }



    }).catch((err) => {
      alert(err)
    })
  }


  return (
    <div className='min-h-screen'>


    <div className='w-[90%]  px-3 lg:px-7 rounded-xl mt-[80px] py-[20px]   bg-white  drop-shadow-lg mx-auto  lg:mt-[100px] lg:mb-[30px] '>







<div className='mt-[60px]'>

{slots.map((item, index) =>
                 (
         <div
         key = {index.toString()}
         className='flex flex-row justify-start items-center mb-[10px]'>
         <FaCircleMinus
onClick={()=>{
    let data = [...slots]
    data.splice(index, 1)
    setslots(data)
}}
className=" text-red-500 w-[20px] h-[20px] hover:cursor-pointer"
/>
             <div

             className='mx-[10px] w-full' >

             <input type='text' placeholder= "Enter Designation"
                    value={item.designation}
                    onChange={(event)=>{handledesignationChange(event, index)}}
                    className={`relative px-4 ${item.designation == "" ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full h-[45px] rounded-xl border border-themeColor items-center flex`}/>

             </div>





             </div>
                 ))}
</div>

<div
onClick={()=>{
    let object ={
        designation: ""
    }

    let data  = [...slots]
    data.push(object)
setslots(data)
}}
className='w-full flex justify-center my-[100px] flex-col items-center hover:cursor-pointer'>
        <CiCirclePlus

className="text-lighttextGray/80 w-[60px] h-[60px]"
/>
<p className='font-semibold PoppinsRegular text-lighttextGray/80'>Add Designations</p>
</div>

{loading ?
     <div
     className=' py-4  w-full   flex items-center justify-center'>
<RotatingLines
                           visible={true}
                           height="25"
                           width="25"
                           strokeColor="#218fdb"

                           strokeWidth="5"
                           animationDuration="0.75"
                           ariaLabel="rotating-lines-loading"
                           wrapperStyle={{}}
                           wrapperClass=""
                           />
                           </div>
                           :
        <button
        onClick={()=>{
            createdesignation()
        }}
        type='submit' className='text-center py-4 rounded-2xl  bg-themeColor hover:bg-themeColor/80 w-full  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
           Update
        </button>
}
    </div>
    </div>
  );
}

export default ProjectDesignations;