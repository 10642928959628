import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllBlogsPosts, getAllBlogsPostsAdmin, getAllEmployeeTasks, saveitem, updateItem } from '../../utils/firebasefunctions';
import { arrayUnion, } from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { TbCurrencyDollar } from "react-icons/tb";
import userlogo from '../images/user.png'
import moment from 'moment'
import { RotatingLines } from 'react-loader-spinner'

// import Section2 from '../components/Homesection/section2'
function LeaveRequest() {
  const [{ user, leaves, users}, dispatch] = useStateValue();
  const [blogpost, setblogpost] = useState("");
  const [Loading, setLoading] = useState(false);
  const [updatestatusloader, setupdatestatusloader] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringindex, setisHoveringindex] = useState(0);
const statuses = [
    {
        status: "Pending"
    },
    {
        status: "Accepted"
    },
    {
        status: "Rejected"
    }
]

  useEffect(() => {
    getallleaves()
    markread()
  }, [])

  const markread = async () =>{
    if(user?.newleaverequest == true){
    let data = {
      newleaverequest: false
    }


    await updateItem("users", user.id, data).then((response) => {
      let dataobj = {...user}
        dataobj["newleaverequest"] = false
        dispatch({
          type: actionType.SET_USER,
          payload: dataobj
      })
  }).catch((err) => {
console.log("err " + err)
  })



  }
  }
  const getallleaves = async () => {
    if (user?.usertype == "SuperAdmin"){
      await getAllBlogsPosts("LeaveApplication", "creationdate", "desc" ).then(async (response) => {

        dispatch({
          type: actionType.STORE_LEAVES,
          payload: response
        })
      }).catch((err) => {
        alert(err)
      })
    }else{

      await getAllBlogsPostsAdmin("LeaveApplication", "creationdate", "desc", user?.id ).then(async (response) => {

        dispatch({
          type: actionType.STORE_LEAVES,
          payload: response
        })
      }).catch((err) => {
        console.log(err)
      })
    }

  }

  const handleMouseOver = (index) => {
    setIsHovering(true);
    setisHoveringindex(index)
  };

  const handleMouseOut = (index) => {
    setIsHovering(false);
  };






  const updatestatus = async (status, id, index, empid, dates) => {

setIsHovering(false)
setupdatestatusloader(true)
    let data = {
      status: status

    }
    await updateItem("LeaveApplication", id, data).then(async (response) => {

      if(status == "Accepted"){
        for (const key in dates){
        let leavedata = {
          leaves:  arrayUnion(dates[key])
        }
        await updateItem("ApprovedLeaves", empid, leavedata).then(async (response) => {
        }).catch((err) => {
          setupdatestatusloader(false)
        alert(err)
      })
    }
      }
      let dataarray = [...leaves]

      dataarray[index].status = status


      dispatch({
        type: actionType.UPDATE_LEAVES,
        payload: dataarray
      })

      setupdatestatusloader(false)

    }).catch((err) => {
        setupdatestatusloader(false)
      alert(err)
    })

  }

  return (
    <div className='w-[100%] mx-auto  min-h-screen  mt-[50px] md:mt-[0px] '>
      <div className='flex flex-wrap justify-between'>

        {leaves.map((item, index) => (

          <div
          key = {index.toString()}
          className=" px-5 lg:px-8 py-4 w-full flex flex-col items-start bg-white rounded-xl mt-5  bg-cardOverlay  drop-shadow-lg">



      <div className='flex w-full justify-between items-start'>
            <div
              onClick={() => {
              }}
              className=' flex justify-between  py-[15px] '>

              <div className='flex flex-row items-center '>
                <img src={users?.filter(function (data) { return data.id == item.employeeid }).map(function (data) { return data.profilepicture ? data.profilepicture : userlogo })} className='w-[35px] h-[35px] object-cover rounded-full' />
           <div className='ml-[10px] flex flex-col'>
                <p className='text-textcolor PoppinsRegular text-sm '>{users?.filter(function (data) { return data.id == item.employeeid }).map(function (data) { return  data.firstname + " " + data.lastname })}</p>

            <p className='PoppinsRegular  text-textcolor/60 text-[12px] '>{moment(item.fromdate.seconds * 1000  + item.fromdate.nanoseconds/1000000).format('DD-MM-YYYY') + " - " + moment(item.todate.seconds * 1000  + item.todate.nanoseconds/1000000).format('DD-MM-YYYY')} </p>


            </div>
              </div>



            </div>
            <p className='PoppinsRegular font-medium text-textcolor/60 text-sm '>{ moment(item.creationdate).fromNow()}</p>
          </div>
            <div className='w-full h-[1px] bg-gray-300'></div>


            <p className='PoppinsRegular font-medium text-textcolor/60 text-sm mt-[5px]'>Leave Reason:</p>
            <p className="PoppinsRegular font-sm text-textcolor text-sm">
              {item.reason}
            </p>

<div

onMouseOver={()=>{
  if(item.status != "Accepted"){
    handleMouseOver(index)
}
}}
onMouseOut={()=>{handleMouseOut(index)}}

className='relative flex flex-row items-center self-end mt-[5px]'>
            <p

            className={`PoppinsRegular font-medium text-textcolor text-sm `}>{item.type}</p>
            <p

            className={`PoppinsRegular font-medium text-textcolor text-sm mx-[3px]`}>-</p>
                      {updatestatusloader && isHoveringindex == index ?
        <RotatingLines
        visible={true}
        height="25"
        width="25"
        strokeColor="#218fdb"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
        />

:
                        <p

                        className={`PoppinsRegular font-medium ${item.status == "Pending" ? " text-orange-600" : item.status == "Rejected" ? " text-red-600": " text-green-600" } text-sm hover:cursor-pointer`}>{item.status}</p>}


{isHovering && isHoveringindex == index && !updatestatusloader &&
                <div className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll p-[10px] position absolute z-50 bottom-[0px] right-0'>
                {statuses.map((inneritem, innerindex) =>
                    (
                        <div
                        key = {innerindex.toString()}
                        onClick={()=>{updatestatus(inneritem.status, item.creationdate, index, item.employeeid, item.alleaves)}}
                        className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-themeColor hover:rounded-md px-[5px] text-textcolor hover:text-white`}>

                            <p className=' font-medium PoppinsRegular text-sm '>{inneritem.status}</p>
                        </div>
                    ))}
                </div>}
          </div>




          </div>


        ))}

        {leaves.length < 1 &&
        <div className='w-full h-screen flex justify-center items-center'>
        <p className='text-20px text-lightgrey'>No Leave Requests Available</p>
        </div>}
      </div>
    </div>
  );
}

export default LeaveRequest;