import React, {useEffect, useRef, useState} from 'react'
import './index.css'
import { saveitem} from '../../utils/firebasefunctions';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";

// import Section2 from '../components/Homesection/section2'
function TaskCreation() {
  const [{user, employees}, dispatch] = useStateValue();
  const [taskdescription, settaskdescription] = useState("");
  const [taskname, settaskname] = useState("");
  const [selectedemployee, setselectedemployee] = useState("");
  const [duedate, setduedate] = useState(new Date());
  const [startdate, setstartdate] = useState(new Date());
  const [employeepopup, setemployeepopup] = useState(false);
useEffect(()=>{

},[])
const handletaskChange = (event) =>{
    settaskname(event.target.value)
}

const handletaskdescriptionChange = (event) =>{
    settaskdescription(event.target.value)
}

const createtask = async () =>{
    if(taskname == ""){
        toast.warn("Please fill out a task name")
    }else if (selectedemployee == ""){
        toast.warn("Please select an employee to assign the task to")
    }else{
let data = {
taskcreationdate: Date.now(),
   title: taskname,
   startdate: startdate,
   duedate: duedate,
   employeeid: selectedemployee.id,
   description: taskdescription,
   status: "Pending"
}




    await saveitem("tasks", data).then(async(response) => {
     if(response == "success"){
     toast.success("Task created Successfully")
     }else{
        toast.error("Something went wrong while posting your task. Please try again later.")
     }
  }).catch((err)=>{
      alert(err)
  })

    }
}
  return (
    <div className='w-[90%] lg:w-[80%] px-3 lg:px-7 rounded-xl mt-[80px] py-[20px] border border-gray-300 mx-auto  lg:mt-[100px] lg:mb-[30px]'>
        <div className='flex flex-col md:flex-row justify-between'>
            <div className='w-full md:w-[49%]'>
                <div className='w-full'>
                    <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Task Name</p>
                    <input type='text'
                    value={taskname} placeholder='Task Name'
                    onChange={handletaskChange}
                    className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
                </div>
            </div>
            <div className='w-full md:w-[49%] mt-5 md:mt-0'>
                <div
                onClick={()=>setemployeepopup(!employeepopup)}
                className='w-full' >
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Select Employee</p>
                <div
                className={`px-4 ${selectedemployee?.firstname == undefined ? " text-gray-500 " : "text-textcolor"} text-sm font-medium PoppinsRegular w-full h-9 rounded-xl border border-themeColor items-center flex`}>{selectedemployee?.firstname == undefined ? "Click to select" : selectedemployee?.firstname + " " + selectedemployee?.lastname}</div>
                {employeepopup &&
                <div className='w-[250px] max-h-[150px] bg-white border border-gray-300 rounded-md overflow-y-scroll gk-scr p-[10px] position absolute z-10 mt-1'>
                {employees.map((item, index) =>
                    (
                        <div
                        key = {index.toString()}
                        onClick={()=>{setselectedemployee(item)}}
                        className={`flex flex-row items-center  w-full py-[5px] cursor-pointer hover:bg-[#0989B826] hover:rounded-xl`}>
                            <img src={item.profilepicture} className='w-[25px] h-[25px] rounded-full object-cover' />
                            <p className='text-textcolor font-medium PoppinsRegular text-sm ml-[10px]'>{item.firstname} {item.lastname}</p>
                        </div>
                    ) )}
                </div>}
                </div>
            </div>
        </div>


        <div className='flex flex-col md:flex-row justify-between mt-5'>
            <div className='w-full md:w-[49%]'>
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Start Date</p>
                <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={startdate} onChange={(date) => setstartdate(date)} />
            </div>
            <div className='w-full md:w-[49%] mt-5 md:mt-0'>
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Due Date</p>
                <DatePicker className='PoppinsRegular font-medium border border-themeColor rounded-xl w-full' selected={duedate} onChange={(date) => setduedate(date)} />
            </div>
        </div>

        <div className='w-full mt-5' >
            <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Task Description</p>
            <textarea type='text' placeholder='Task Description'

            value={taskdescription}
            onChange={handletaskdescriptionChange}
            className="px-4 text-black text-base font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex h-[200px]"/>
        </div>

        <button
        onClick={()=>{
            createtask()
        }}
        type='submit' className='text-center py-4 rounded-2xl mt-6 bg-themeColor hover:bg-themeColor/80 w-full lg:w-[40%] mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
            Create Task
        </button>
    </div>
  );
}

export default TaskCreation;