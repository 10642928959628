import React, {useRef, useState, useEffect } from 'react'
import Arrow from '../images/dashboard/outline.png'
import { useNavigate } from 'react-router-dom';
import './index.css'

function PrivacyPolicy() {

    useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        // Go back to the previous page
        navigate(-1);
    };

  return (
    <div className='my-16 flex justify-center'>

        <div className='w-[90%]   pt-2 '>
            <div>
                <h1 className="text-textcolor text-3xl lg:text-[40px] PoppinsRegular font-bold leading-[48px]">
                    Privacy Policy
                </h1>
            </div>

            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            This Privacy Policy explains how Raftek Group Oy and its subsidiaries and a􏰀iliates (hereinafter “Raftekgroup.com”,”The Company”, “we,” or “us”) collects, uses, and discloses information about you when you use our Raftekpro website, mobile application, and services that link to this Privacy Policy (collectively, our “Services”. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. “You” means any user of the Site, App, Platform and/or Services.
            </p>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
            Types of Data Collected
            </h2>
            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Personal Data
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
Email address
First name and last name
Phone number
Photos for profile and Facial Recognition use
Usage Data
            </p>
            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Usage Data
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            Usage Data is collected automatically when using the Service.
Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
            </p>
            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Geo-location
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            When you use specific functionalities in our App, you may be asked to share your mobile device’s precise (GPS) geo-location information. Such geo-location information may include physical locations

visited (latitude & longitude). For example, We may only allow you to clock-in or clock out if We are able to verify your location via your device. If you do not wish to allow the collection of your precise location, in most cases you will be able to turn o􏰀 such data collection at any time by accessing the privacy settings of your mobile device and/or adjusting the permissions for the App. Please note, however, that some of the App’s features may not work properly if You turn this o􏰀, and We are not responsible for any implication of turning o􏰀 such geo-location tracking.
            </p>
            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Use of Your Personal Data
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            The Company may use Personal Data for the following purposes:
To provide and maintain our Service, including to monitor the usage of our Service.
To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to di􏰀erent functionalities of the Service that are available to You as a registered user.
For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
To provide You with news, special o􏰀ers and general information about other goods, services and events which we o􏰀er that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
To manage Your requests: To attend and manage Your requests to Us.
For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the e􏰀ectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
We may share Your personal information in the following situations:
<ul className="list-disc PoppinsRegular text-textcolor/60 text-[15px] w-[96%] ml-auto">
                <li className='pt-4'>
                With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
                </li>
                <li className='pt-4'>
                With business partners: We may share Your information with Our business partners to o􏰀er You certain products, services or promotions.
                </li>
                <li className='pt-4'>
                With Your consent: We may disclose Your personal information for any other purpose with Your consent.
                </li>

            </ul>

            </p>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
            Retention of Your Personal Data
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
            Subject to applicable law, we retain Personal Data as necessary to maintain and expand our relationship with you and provide you with our Services and o􏰀erings; in order to comply with our legal
and contractual obligations; or to protect ourselves from any potential disputes (i.e. as required by laws applicable to log-keeping, records and bookkeeping, and in order to have proof and evidence concerning our relationship, should any legal issues arise following your discontinuance of use), all in accordance with our data retention policy and at our reasonable discretion. We may delete information from our systems without notice to you once we deem it is no longer necessary for
these purposes.
The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
            Disclosure of Your Personal Data
            </h2>

            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Business Transactions
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            Should The Company or any of its subsidiaries or a􏰀iliates undergo any change in control or ownership, including by means of merger, acquisition or purchase of substantially all or part of its assets, your Personal Data may be disclosed to or transferred to the parties involved in such an event. Personal Data may also be disclosed in the event of insolvency, bankruptcy or receivership. In addition, we may disclose your Personal Data where such disclosure is required to protect our legitimate business interests, including the security or integrity of our products and services. We will provide notice before Your Personal Data is transferred and becomes subject to a di􏰀erent Privacy Policy.
            </p>
            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Law enforcement
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>
            <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
            Other legal requirements
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ul className="list-disc PoppinsRegular text-textcolor/60 text-[15px] w-[96%] ml-auto">
            <li className='pt-4'>
            Comply with a legal obligation
            </li>
            <li className='pt-4'>
Protect and defend the rights or property of the Company
</li>
<li className='pt-4'>
 Prevent or investigate possible wrongdoing in connection with the Service · Protect the personal safety of Users of the Service or the public
 </li>
 <li className='pt-4'>
Protect against legal liability
</li>
</ul>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
            Security and Safeguarding of Your Personal Data
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
We are committed to protecting Your Personal Data, but unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security of your personal information transmitted through our Services or Applications. We are not responsible for circumvention of any privacy settings or security measures we provide.
            </p>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
            Your Rights under the GDPR
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
            The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.

            You have the right under this Privacy Policy, and by law if You are within the EU, to:
            </p>
            <ul className="list-disc PoppinsRegular text-textcolor/60 text-[15px] w-[96%] ml-auto">
            <li className='pt-4'>
 Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
 </li>
 <li className='pt-4'>
 Request correction of the Personal Data that We hold about You. You may request that we update, complete, correct or delete inaccurate, incomplete, or outdated Personal Data.
 </li>
 <li className='pt-4'>
 Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.
 </li>
 <li className='pt-4'>
 Request deletion of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
 </li>
 <li className='pt-4'>
 Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.
</li>
 <li className='pt-4'>
 Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.
 </li>
 </ul>

 <h2 className="text-textcolor text-lg PoppinsRegular leading-loose pt-7">
 Children’s Privacy
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
We do not knowingly collect Personal Data from children under the age of sixteen. In the event that you become aware that an individual under the age of sixteen has enrolled without parental permission, please advise us immediately. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
            </p>


            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
            Contact Us
            </h2>

            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
            If you have any questions regarding this Privacy Policy, please do not hesitate to contact us:
            </p>
            <p className='PoppinsRegular text-themeColor text-[15px] pt-4 underline'>
info@raftekgroup.com
</p>
<p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
Raftek Group Oy FI33663193 Vuohikallio 1 As 1 Espoo, 02860 Finland
</p>


        </div>
    </div>
  );
}

export default PrivacyPolicy;