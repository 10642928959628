import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllEmployeeTasks, getAllEmployeeTasksByOrder, updateItem } from '../../utils/firebasefunctions';
import { arrayUnion, } from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { TbCurrencyDollar } from "react-icons/tb";
import userlogo from '../images/user.png'
import moment from 'moment'

// import Section2 from '../components/Homesection/section2'
function MyJobs() {
  const [{ user, employees, jobs, clients }, dispatch] = useStateValue();
  const [taskdescription, settaskdescription] = useState("");

  useEffect(() => {
    getmyjobs()
  }, [])


  const getmyjobs = async () => {
    await getAllEmployeeTasksByOrder("jobs", "jobcreator", "==", user?.id,  "jobcreationdate", "desc").then(async (response) => {

      dispatch({
        type: actionType.STORE_JOBS,
        payload: response
      })
    }).catch((err) => {
      console.log(err)
    })
  }


  const assign = async (employeeid, index, jobid) => {

    let data = {
      assigned: employeeid,
      status: "InProgress"

    }
    await updateItem("jobs", jobid, data).then(async (response) => {

      let dataarray = [...jobs]
      dataarray[index]["assigned"] = employeeid
      dataarray[index].status = "InProgress"


      dispatch({
        type: actionType.UPDATE_JOBS,
        payload: dataarray
      })

    }).catch((err) => {
      alert(err)
    })

  }
  return (
    <div className='w-[90%] mx-auto mt-[80px] mb-20'>
      <div className="flex flex-wrap justify-between">
        {jobs.map((item, index) => (
          <div
          key = {index.toString()}
          className="p-8 lg:p-12 w-full lg:w-[49%] flex flex-col items-start bg-[#ebf4fb] rounded-xl mt-5">
            <span className={`${item.status == "Pending" ? "bg-red-600" : item.status == "InProgress" ? "bg-orange-600" : "bg-green-600"} inline-block py-2 px-3 rounded-md PoppinsRegular font-semibold text-white text-xs  tracking-widest`}>
              {item.status}
            </span>
            <h2 className="sm:text-3xl text-2xl title-font PoppinsRegular font-medium text-textcolor mt-4">
              {item.title}
            </h2>
            <p className='PoppinsRegular font-medium text-sm text-textcolor/60 pt-3'>{"Location: " + item.location}</p>
            <p className='PoppinsRegular font-medium text-sm text-textcolor/60 pt-1'>{"Salary: $" + item.salary}</p>
            <p className="leading-relaxed mb-8 PoppinsRegular font-medium text-textcolor pt-4">
              {item.description}
            </p>
            <div className={`flex items-center flex-wrap pb-4 justify-between mb-4 ${item.status == "Pending" ? "border-red-600" : item.status == "InProgress" ? "border-orange-600" : "border-green-600"} border-b-2  mt-auto w-full`}>
              <div className="PoppinsRegular font-medium text-textcolor/60 inline-flex items-center">{item.assigned != undefined ? "Assigned" : "Applicants"}
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </div>
              <div>
                <p className='PoppinsRegular text-textcolor/60 text-sm'>{ moment(item.jobcreationdate).fromNow()}</p>
              </div>
            </div>
            {item.assigned != undefined ?
              <div
                onClick={() => {
                  // setchatid(item.id)
                }}
                className='w-[100%] flex flex-row items-center mt-[10px] '>

                <img src={employees.filter(function (data) { return data.id == item.assigned }).map(function (data) { return data.profilepicture ? data.profilepicture : userlogo })} className='w-[35px] h-[35px] object-cover rounded-full' />
                <div className='flex flex-col'>
                  <p className='PoppinsRegular font-semibold text-textcolor text-sm ml-[10px]'>{employees.filter(function (data) { return data.id == item.assigned }).map(function (data) { return data.firstname + " " + data.lastname })}</p>
                  <p className='PoppinsRegular font-medium text-textcolor/50 text-xs ml-[10px]'>{employees.filter(function (data) { return data.id == item.assigned }).map(function (data) { return data.designation })}</p>
                </div>
              </div>

              :

              item?.applicants != undefined ?
                item?.applicants.map((itemdata, indexdata) => (
                  <div className='flex flex-row justify-between w-full py-[10px]'>


                    <div
                      onClick={() => {
                        // setchatid(item.id)
                      }}
                      className='w-[100%] flex flex-row items-center  '>

                      <img src={employees.filter(function (data) { return data.id == itemdata }).map(function (data) { return data.profilepicture ? data.profilepicture : userlogo })} className='w-[35px] h-[35px] object-cover rounded-full' />
                      <div className='flex flex-col'>
                        <p className='PoppinsRegular font-semibold text-textcolor text-sm ml-[10px]'>{employees.filter(function (data) { return data.id == itemdata }).map(function (data) { return data.firstname + " " + data.lastname })}</p>
                        <p className='PoppinsRegular font-medium text-textcolor/50 text-xs ml-[10px]'>{employees.filter(function (data) { return data.id == itemdata }).map(function (data) { return data.designation })}</p>
                      </div>

                    </div>


                    <button
                      onClick={() => { assign(itemdata, index, item.jobcreationdate) }}
                      type='submit' className="text-center PoppinsRegular  bg-themeColor  py-[2px] px-3 rounded-md text-white text-sm">
                      Assign
                    </button>
                  </div>

                ))
                :
                <p className='PoppinsRegular font-medium text-textcolor mt-2'>No Applications Available</p>


            }
          </div>
        ))}
      </div>

    </div>
  );
}

export default MyJobs;