import React, { useState, useEffect } from 'react'
import './index.css'
import { useStateValue } from '../context/StateProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import DashboardContainer from './Dashboard-container'
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import { IoIosRadioButtonOn } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import { updateItem, updateNestedItem, getNestedItem, updateNestedItemcheckout } from '../../utils/firebasefunctions';
import { actionType } from '../context/reducer';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Loader from "react-js-loader";


const EmployeeHome = (props) => {
  // const ValuePiece = Date | null;
  // const Value = ValuePiece | [ValuePiece, ValuePiece];
  const [{ user, employees, tasks, assignedjobs, checkinstatus }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [employeedata, setemployeedata] = useState("");
  const [statuspopup, setstatuspopup] = useState(false);
  const [statuspopupindex, setstatuspopupindex] = useState(0);
  const [jobstatuspopup, setjobstatuspopup] = useState(false);
  const [jobstatuspopupindex, setjobstatuspopupindex] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [currentselectedproject, setcurrentselectedproject] = useState("Select Project");
  const [value, onChange] = useState(new Date());
  const [laoding, setlaoding] = useState(false);

  const [options, setoptions] = useState([
    {
      name: "Pending",
    },
    {
      name: "InProgress",
    },
    {
      name: "Completed",
    },
    {
      name: "Closed",
    }
  ]);

  const [joboptions, setjoboptions] = useState([

    {
      name: "InProgress",
    },
    {
      name: "Completed",
    },
    {
      name: "Closed",
    }
  ]);



  useEffect(() => {
    if (checkinstatus[0]?.type != undefined) {
      if (checkinstatus[0]?.type == "Project") {

        let data = assignedjobs.filter(function (doc) {
          return doc.jobcreationdate == checkinstatus[0]?.projectid
        })

        setcurrentselectedproject(data[0])
      } else {
        let data = tasks.filter(function (doc) {
          return doc.taskcreationdate == checkinstatus[0]?.projectid
        })

        setcurrentselectedproject(data[0])
      }
    }
    if (!checkinstatus[0]?.checkoutdate && checkinstatus[0]?.checkindate) {
      const interval = setInterval(() => getTime(checkinstatus[0]?.checkindatetime), 1000);


      return () => clearInterval(interval);
    } else if (checkinstatus[0]?.checkoutdate && checkinstatus[0]?.checkindate) {
      const time = checkinstatus[0]?.checkoutdatetime - checkinstatus[0]?.checkindatetime
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));


      setTimeout(() => {
        dispatch({
          type: actionType.STORE_CHECKIN,
          payload: []
        })
        setcurrentselectedproject("Select Project")
      }, 3000);
    }
  }, [checkinstatus]);

  useEffect(() => {
    if (user?.id) {
      getcheckintime()
    }
  }, [user])





  const getTime = (checkintime) => {
    const time = Date.now() - checkintime

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const getcheckintime = async () => {
    await getNestedItem("users", user?.id, "checkin").then(async (response) => {
      dispatch({
        type: actionType.STORE_CHECKIN,
        payload: response
      })
    }).catch((err) => {
      alert(err)
    })



  }



  const updatestatus = async (taskcreationdate, status, index) => {
    let taskdata = [...tasks]
    taskdata[index]["status"] = status


    dispatch({
      type: actionType.STORE_TASKS,
      payload: taskdata
    })

    let data = {
      status: status
    }
    await updateItem("tasks", taskcreationdate, data).then(async (response) => {
    }).catch((err) => {
      alert(err)
    })
  }



  const updatejobstatus = async (id, status, index) => {
    let jobdata = [...assignedjobs]
    jobdata[index]["status"] = status


    dispatch({
      type: actionType.UPDATE_ASSIGNEDJOBS,
      payload: jobdata
    })

    let data = {
      status: status
    }
    await updateItem("jobs", id, data).then(async (response) => {
    }).catch((err) => {
      alert(err)
    })
  }


  const clockin = async () => {
    setlaoding(true)
    let id = moment(new Date()).format("YYYY-MM-DD")
    let docid = Date.now()
    let time = moment(new Date()).format("HH:mm:ss")

    let data = {}
    if (checkinstatus[0]?.checkindate) {

      navigator.geolocation.getCurrentPosition(
        (position) => {
          let diff = Math.abs(new Date(checkinstatus[0]?.checkindatetime) - Date.now());


          data = {
            checkouttime: time,
            checkoutdate: id,
            checkoutdatetime: docid,
            checkoutlatitude: position.coords.latitude,
            checkoutlongitude: position.coords.longitude,
            minutes: diff / 1000 / 60
          }


          updatecheckoutdata(data, checkinstatus[0]?.checkindatetime)
        },
        (error) => {
          // display an error if we cant get the users position
          console.error('Error getting user location:', error);
        }
      );

    } else {

      navigator.geolocation.getCurrentPosition(
        (position) => {

          data = {
            checkintime: time,
            checkindate: id,
            checkindatetime: docid,
            checkinlatitude: position.coords.latitude,
            checkinlongitude: position.coords.longitude,
            type: currentselectedproject?.jobcreationdate ? "Project" : "Task",
            projectid: currentselectedproject?.jobcreationdate ? currentselectedproject.jobcreationdate : currentselectedproject.taskcreationdate
          }

          updatecheckindata(data, docid)
        },
        (error) => {
          // display an error if we cant get the users position
          console.error('Error getting user location:', error);
        }
      );

    }



  }


  async function updatecheckindata(data, checkindatetime) {
    await updateNestedItem("users", user?.id, "checkin", data, checkindatetime).then(async (response) => {
      await getNestedItem("users", user?.id, "checkin").then(async (response) => {
        dispatch({
          type: actionType.STORE_CHECKIN,
          payload: response
        })
        setlaoding(false)
      }).catch((err) => {
        alert(err)
        setlaoding(false)
      })

    }).catch((err) => {
      alert(err)
      setlaoding(false)
    })
  }




  async function updatecheckoutdata(data, innerid) {
    await updateNestedItemcheckout("users", user?.id, "checkin", data, innerid).then(async (response) => {
      await getNestedItem("users", user?.id, "checkin").then(async (response) => {
        dispatch({
          type: actionType.STORE_CHECKIN,
          payload: response
        })
        setlaoding(false)
      }).catch((err) => {
        alert(err)
        setlaoding(false)
      })

    }).catch((err) => {
      alert(err)
      setlaoding(false)
    })
  }


  return (
    <div className='w-[100%] h-full mx-auto flex flex-col lg:flex-row mt-28 items-start'>
      <div className='w-full lg:w-[70%] flex flex-col justify-center items-center '>
        <div className='w-[100%] flex flex-col lg:flex-row lg:items-center lg:justify-center h-[350px]'>
          <div className='w-full lg:w-[60%] pr-10'>
            <div className='flex items-center '>
              {/* <div className="horizontalline "></div> */}
              <p className='PoppinsRegular text-sm lg:text-[20px] font-400 text-textcolor lg:leading-[32px]'>
                Peoples Portal
              </p>
            </div>
            <div className='pt-1'>
              <h1 className='PoppinsRegular w-[80%] font-semibold text-4xl lg:text-6xl text-textcolor'>
                {user?.firstname} {user?.lastname}
              </h1>
            </div>
            <p className='PoppinsRegular text-textcolor/60 py-3 lg:py-6 text-sm lg:text-base'>Monitor your work hours and grasp exciting new oppertunities to win jobs posted by our list of international clients.</p>
          </div>
          <DashboardContainer
            title={"Work Status"}
            showcount={false}
            color='border-themeColor'
            cardheight='h-[90%]'
            style='w-full lg:w-[40%] mt-12 lg:mt-0'
            innerstyle='items-center '>
            <div className='flex flex-col w-full h-full items-center justify-start lg:justify-between'>
              {/* <text>{user.starttime}</text> */}

              <div className={`py-[2px] w-full ${currentselectedproject?.title == undefined ? "bg-gray-300" : "bg-green-500"} flex justify-center items-center rounded-md`}>
                <p className='text-[15px] text-white'>{currentselectedproject?.title == undefined ? currentselectedproject : currentselectedproject?.title}</p>
              </div>
              {!checkinstatus[0]?.checkoutdate && checkinstatus[0]?.checkindate || checkinstatus[0]?.checkoutdate && checkinstatus[0]?.checkindate ?
                <div className='flex flex-row justify-center text-center items-center text-4xl'>
                  <p className='text-[15px] lg:text-[30px]'>{hours + ":"}</p>
                  <p className='text-[15px] lg:text-[30px]'>{minutes + ":"}</p>
                  <p className='text-[15px] lg:text-[30px]'> {seconds}</p>
                </div> :
                <div className='flex flex-row justify-center text-center items-center text-4xl'>
                  <p className='text-[15px] lg:text-[30px]'>{"00" + ":"}</p>
                  <p className='text-[15px] lg:text-[30px]'>{"00" + ":"}</p>
                  <p className='text-[15px] lg:text-[30px]'> {"00"}</p>
                </div>
              }
              {/* {checkinstatus[0]?.checkoutdate && checkinstatus[0]?.checkindate &&
                <div className='flex flex-row justify-center text-center items-center text-4xl pt-3'>
                  <text>{hours + ":"}</text>
                  <text>{minutes + ":"}</text>
                  <text> {seconds}</text>
                </div>
              } */}


              {/* {laoding ?
<div >
<Loader type="spinner-cub" bgColor={"#218fdb"}  size={30}  />
</div>
 : */}
              <div
                disabled={true}
                onClick={() => {
                  if (!laoding) {
                    if (currentselectedproject?.title == undefined) {
                      toast.warn("Please select the project you want to clock in for.")
                    } else {
                      clockin()
                    }
                  }
                }}

                className={`py-[2px] w-full bg-themeColor ${laoding ? null : "hover:bg-themeColor/80"} cursor-pointer text-white flex justify-center items-center rounded-md`}>
                <p className='text-[15px] text-white'>{laoding ? "Loading..." : checkinstatus[0]?.checkindate && checkinstatus[0]?.checkoutdate ? "Last Shift Ended" : checkinstatus[0]?.checkindate ? "Clock Out" : "Clock In"}</p>
              </div>


            </div>
          </DashboardContainer>
        </div>
        <div className='w-[100%] flex flex-col lg:flex-row items-center my-12'>
          <DashboardContainer
            title={"Projects"}
            showcount={true}
            countlength={assignedjobs.length}
            color='border-red-600'
            cardheight='h-[90%]'
            count='bg-red-600'
            style='w-full lg:w-[40%] justify-end mt-16 lg:mt-0'
            innerstyle='items-center justify-start'>
            {assignedjobs && assignedjobs.map((item, index) =>

            (

              <div
              key = {index.toString()}
                onClick={() => {
                  if (currentselectedproject == "Select Project") {
                    setcurrentselectedproject(item)
                  } else {
                    toast.warn("Please Clock Out of your previous task before starting a new one!")
                  }

                }}
                className='w-full flex flex-row items-center relative pl-3 py-3 cursor-pointer hover:bg-[#0989B826] hover:rounded-md'>
                <IoIosRadioButtonOn
                  onClick={() => {
                    if (index != statuspopupindex) {
                      setjobstatuspopup(true)
                    } else {
                      setjobstatuspopup(!jobstatuspopup)
                    }

                    setjobstatuspopupindex(index)

                  }}
                  className={` ${item.status == "Pending" ? 'text-[#616864]' : item.status == "InProgress" ? 'text-[#f79946]' : item.status == "Completed" ? 'text-[#57ef5c]' : 'text-[#d13939]'} text-lg cursor-pointer`}
                />
                <p className='text-textcolor PoppinsRegular font-medium text-lg pl-2'>{item.title}</p>


                {jobstatuspopup == true && jobstatuspopupindex == index &&
                  <div className='w-[200px] bg-white border border-gray-300 rounded-md absolute overflow-y-scroll gk-scr p-[5px] z-10 top-[35px]'>
                    {joboptions && joboptions.map((itemdata, indexdata) =>
                    (
                      <div
                      key = {indexdata.toString()}
                        onClick={() => {
                          setjobstatuspopup(!jobstatuspopup)

                          updatejobstatus(item.jobcreationdate, itemdata.name, index)

                        }}
                        className='hover:bg-[#0989B826] hover:rounded-md w-full flex items-center text-center py-2 cursor-pointer pl-2'>
                        <IoIosRadioButtonOn

                          className={` ${itemdata.name == "Pending" ? 'text-[#616864]' : itemdata.name == "InProgress" ? 'text-[#f79946]' : itemdata.name == "Completed" ? 'text-[#57ef5c]' : 'text-[#d13939]'} text-sm`}
                        />
                        <p className='text-textcolor PoppinsRegular font-medium text-sm pl-2'>{itemdata.name}</p>
                      </div>
                    ))}

                  </div>}
              </div>
            ))}
          </DashboardContainer>
          <DashboardContainer
            title={"Calendar"}
            color='border-stone-900'
            cardheight='h-[90%]'
            style='w-full lg:w-[60%] justify-end mt-12 lg:mt-0'
            innerstyle='items-center justify-center'>
            <div className='w-full h-full flex items-center'>
              <Calendar className='text-base w-full' onChange={onChange} value={value} />
            </div>
            {/* {tasks && tasks.map((item, index) =>

            (

              <div
                onClick={() => {

                }}
                className={`flex flex-row items-center justify-between w-full py-[10px]`}>

                <IoIosRadioButtonOn
                  onClick={() => {
                    if (index != statuspopupindex) {
                      setstatuspopup(true)
                    } else {
                      setstatuspopup(!statuspopup)
                    }

                    setstatuspopupindex(index)

                  }}
                  className={` ${item.status == "Pending" ? 'text-[#616864]' : item.status == "InProgress" ? 'text-[#f79946]' : item.status == "Completed" ? 'text-[#57ef5c]' : 'text-[#d13939]'}   text-[35px] cursor-pointer`}
                />
                <text className='text-black'>{item.title}</text>
                <text className='text-black'>{item.duedate.toString()}</text>
                {statuspopup == true && statuspopupindex == index &&
                  <div className='w-[100px]  bg-black position absolute mt-[80px] overflow-scroll p-[5px] rounded-sm'>
                    {options && options.map((itemdata, indexdata) =>
                    (
                      <div
                        onClick={() => {
                          setstatuspopup(!statuspopup)

                          updatestatus(item.taskcreationdate, itemdata.name, index)

                        }}
                        className='flex flex-row items-center hover:bg-lightgrey'>
                        <IoIosRadioButtonOn

                          className={` ${itemdata.name == "Pending" ? 'text-[#616864]' : itemdata.name == "InProgress" ? 'text-[#f79946]' : itemdata.name == "Completed" ? 'text-[#57ef5c]' : 'text-[#d13939]'}   text-[10px]`}
                        />
                        <text className='text-white text-[10px] ml-[5px]'>{itemdata.name}</text>
                      </div>
                    ))}

                  </div>}

              </div>


            ))} */}
          </DashboardContainer>
          {/* <DashboardContainer
            title={"Khurram"}
            color='border-green-400'
            cardheight='h-[90%]'
            style='w-full lg:w-[40%] justify-end mt-12 lg:mt-0'
            innerstyle='items-center justify-center '>


          </DashboardContainer> */}
        </div>
      </div>
      <div className='w-full lg:w-[30%] flex items-center h-[750px] '>
        <DashboardContainer
          title={"Tasks"}
          showcount={true}
          countlength={tasks.length}
          color='border-yellow-400'
          cardheight='h-[100%]'
          style='w-full h-full'
          count='bg-yellow-400'
          innerstyle=' justify-start '>


          {tasks && tasks.map((item, index) =>

          (

            <div
            key = {index.toString()}
              onClick={() => {
                if (currentselectedproject == "Select Project") {
                  setcurrentselectedproject(item)
                } else {
                  toast.warn("Please Clock Out of your previous task before starting a new one!")
                }
              }}
              className={`flex flex-row items-center w-full py-[10px] pl-3 pt-3 cursor-pointer hover:bg-[#0989B826] hover:rounded-md `}>

              <IoIosRadioButtonOn
                onClick={() => {
                  if (index != statuspopupindex) {
                    setstatuspopup(true)
                  } else {
                    setstatuspopup(!statuspopup)
                  }

                  setstatuspopupindex(index)

                }}
                className={` ${item.status == "Pending" ? 'text-[#616864]' : item.status == "InProgress" ? 'text-[#f79946]' : item.status == "Completed" ? 'text-[#57ef5c]' : 'text-[#d13939]'} text-lg cursor-pointer`}
              />
              <p className='text-textcolor text-lg PoppinsRegular font-medium pl-2'>{item.title}</p>
              {statuspopup == true && statuspopupindex == index &&
                <div className='w-[200px] bg-white border border-gray-300 rounded-xl absolute overflow-y-scroll p-[5px] z-10 top-[100px] gk-scr'>
                  {options && options.map((itemdata, indexdata) =>
                  (
                    <div
                    key = {indexdata.toString()}
                      onClick={() => {
                        setstatuspopup(!statuspopup)
                        updatestatus(item.taskcreationdate, itemdata.name, index)
                      }}
                      className='hover:bg-[#0989B826] hover:rounded-xl w-full flex items-center text-center py-2 cursor-pointer  pl-2'>
                      <IoIosRadioButtonOn
                        className={` ${itemdata.name == "Pending" ? 'text-[#616864]' : itemdata.name == "InProgress" ? 'text-[#f79946]' : itemdata.name == "Completed" ? 'text-[#57ef5c]' : 'text-[#d13939]'} text-sm`}
                      />
                      <p className='text-textcolor PoppinsRegular font-medium text-sm pl-2'>{itemdata.name}</p>
                    </div>
                  ))}
                </div>}
            </div>
          ))}
        </DashboardContainer>
      </div>
    </div>
  )
}


export default EmployeeHome