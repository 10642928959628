import React, {useRef, useState, useEffect} from 'react'
import Arrow from '../images/dashboard/outline.png'
import { useNavigate } from 'react-router-dom';
import './index.css'

function TermsUse() {

    useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        // Go back to the previous page
        navigate(-1);
    };
 
  return (
    <div className='my-32 lg:my-44'>
        
        <div className='w-[90%] lg:w-[60%] mx-auto pt-3 lg:pt-4'>
            <div>
                <h1 className="text-textcolor font-bold text-3xl lg:text-[40px] PoppinsRegular leading-[48px]">
                    Terms of Use
                </h1>
            </div>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
                1. Introduction
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
                This is a contract between you and each of:
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                MP Technology Services Limited (“Bitvium Ltd”), a company organised and existing under the laws of the Seychelles and having its registered office at Suite 108, Premier Building, Victoria, Maha, The Republic of Seychelles; and
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                Bitvium Technology Services Limited (“Bitvium Ireland”), a company organised and existing under the laws of Ireland and having its principal place of of business at 3rd Floor , Waterways House, Grand Canal Quay, Dublin, Ireland, with company number 697233, together “Bitvium”.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                1.1 In this document, references to MoonPay/we/our/us are to MoonPay Ltd and/or MoonPay Ireland depending on the services being discussed. At all times, any handling or delivery of Cryptocurrency will be carried out by MoonPay Ltd, and any handling or dealing in any fiat currency will be carried out by MoonPay Ireland (including any receipt of payments for purchases you make with MoonPay Ltd). At no time will MoonPay Ireland be responsible for the handling or delivery of, or provision of services in relation to, any Cryptocurrency. Any references to Customer/you/your are references to you as a customer of MoonPay and user of our services. Any references to the "Service" includes websites, APIs, or mobile applications.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                1.2 You agree that the liability of each of MoonPay Ltd and MoonPay Ireland under this Agreement is several and not joint, and each of MoonPay Ltd and MoonPay Ireland shall be liable only for their own respective obligations under this Agreement, and any breaches by them of those obligations. This means that each of them are responsible to you for their own breaches of this Agreement, and not for each other’s breaches.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                1.3 Please read this document carefully, as it sets out the terms and conditions on which MoonPay will provide services to you through our websites, Application Programming Interfaces (“APIs”), or mobile applications (together our “Site”).
            </p>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
                2. Eligibility
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
                2.1 To be eligible to use any of the MoonPay Services, you confirm that you are:
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                2.1.1 at least 18 years old;
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                2.1.2 have sufficient capacity to enter into legally binding contracts;
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                2.1.3 reside in a country in which the relevant MoonPay Services are accessible; and
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                2.1.4 willing to provide to us any current valid personal identification documents that we request
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                2.2 The list of non-supported countries can be found here.
            </p>
            <h2 className="text-textcolor text-xl PoppinsRegular leading-loose pt-7">
                3. Services provided to you
            </h2>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-3'>
                3.1 Once you have completed the registration process (as set out at clause 4 below), MoonPay will provide the Services to you.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                3.2 When using the Services, you are buying Cryptocurrency from, or selling Cryptocurrency to, MoonPay directly. MoonPay does not act as an intermediary or marketplace between other buyers and sellers of Cryptocurrency.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                3.3 MoonPay will send / deliver Cryptocurrency to the Wallet address indicated at the time of the Order subject to the conditions of this Agreement;
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                3.4 At no point during the purchase or sale, will MoonPay be in possession or in control of client funds.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                3.5 MoonPay does not host Wallets, and is not a custodian of your funds or assets; and
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                3.6 Orders through MoonPay are executed individually, one by one.
            </p>
            <p className='PoppinsRegular text-textcolor/60 text-[15px] pt-4'>
                3.7 MoonPay DOES NOT facilitate or provide trading or investment or brokerage accounts or facilities, nor does MoonPay provide investment or any other financial advice.
            </p>
        </div>
    </div>
  );
}

export default TermsUse;