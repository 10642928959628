import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllEmployeeTasks, updateItem } from '../../utils/firebasefunctions';
import { arrayUnion, } from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { TbCurrencyDollar } from "react-icons/tb";
import userlogo from '../images/user.png'
import moment from 'moment'

// import Section2 from '../components/Homesection/section2'
function MarketPlace() {
  const [{ user, employees, jobs, clients }, dispatch] = useStateValue();
  const [taskdescription, settaskdescription] = useState("");

  useEffect(() => {
    getalljobs()
  }, [])


  const getalljobs = async () => {
    await getAllEmployeeTasks("jobs", "status", "==", "Pending").then(async (response) => {

      dispatch({
        type: actionType.STORE_JOBS,
        payload: response
      })
    }).catch((err) => {
      alert(err)
    })
  }


  const apply = async (id, index) => {

    let data = {
      applicants: arrayUnion(user?.id)
    }
    await updateItem("jobs", id, data).then(async (response) => {

      let dataarray = [...jobs]
      if (dataarray[index].applicants == undefined) {
        let arrayval = []
        arrayval.push(user?.id)
        dataarray[index]["applicants"] = arrayval
      } else {
        dataarray[index].applicants.push(user?.id)
      }

      dispatch({
        type: actionType.UPDATE_JOBS,
        payload: dataarray
      })

    }).catch((err) => {
      alert(err)
    })

  }
  return (
    <div className='w-[90%] mx-auto mt-[80px] mb-20'>
      <div className='flex flex-wrap justify-between'>
        {jobs.map((item, index) => (
          // <div
          //   onClick={() => {
          //   }}
          //   className='w-[100%] flex flex-col items-start justify-between px-[20px] py-[15px] '>
          //   <text className='text-black text-[11px]  w-full text-right'>{moment(item.jobcreationdate).format('YYYY-MM-DD hh:mm:ss a')}</text>
          //   <div className='flex flex-row items-center '>
          //     <img src={clients.filter(function (data) { return data.id == item.jobcreator }).map(function (data) { return data.profilepicture ? data.profilepicture : userlogo })} className='w-[30px] h-[30px] object-cover rounded-full' />
          //     <text className='text-black text-[12px] ml-[5px]'>{clients.filter(function (data) { return data.id == item.jobcreator }).map(function (data) { return data.name })}</text>
          //   </div>

          //   <text className='text-black text-[15px] mt-[10px]'>{item.title}</text>
          //   <text className='text-black text-[11px]'>{"Location: " + item.location}</text>
          //   <text className='text-black text-[11px]'>{"Salary: $" + item.salary}</text>
          //   <text className='text-black text-[11px] my-[10px]'>{item.description}</text>

          //   {user.usertype == "Employee" &&
          //     <>
          //       {item?.applicants?.find(data => data == user.id) == undefined ?
          //         <button
          //           onClick={() => apply(item.jobcreationdate, index)}
          //           type='submit' className="text-center  bg-blue-500  py-[2px] px-[5px] rounded-md text-white text-[12px]   ">
          //           Apply
          //         </button>
          //         :
          //         <div className="text-center  bg-green-500   px-[5px] rounded-md   ">
          //           <text className='text-white text-[12px] '>Applied!</text>
          //         </div>}
          //     </>
          //   }

          // </div>
          <div
          key = {index.toString()}
          className="px-5 lg:px-8 py-4 w-full flex flex-col items-start bg-[#ebf4fb] rounded-xl mt-5">
            <div className='text-right w-full'>
              <p className='PoppinsRegular font-medium text-textcolor/60 text-sm w-full'>{ moment(item.jobcreationdate).fromNow()}</p>
            </div>
            <h2 className="sm:text-3xl text-2xl title-font PoppinsRegular font-medium text-textcolor mt-3 lg:mt-1">
              {item.title}
            </h2>
            <p className='PoppinsRegular font-medium text-sm text-textcolor/60 pt-3'>{"Location: " + item.location}</p>
            <p className='PoppinsRegular font-medium text-sm text-textcolor/60 pt-1'>{"Salary: $" + item.salary}</p>
            <p className="leading-relaxed mb-8 PoppinsRegular font-medium text-textcolor pt-4">
              {item.description}
            </p>

            <div className='w-full h-[1px] bg-gray-300'></div>

            <div
              onClick={() => {
              }}
              className='w-[100%] flex justify-between px-[20px] py-[15px] '>
              <div className='flex flex-row items-center '>
                <img src={clients.filter(function (data) { return data.id == item.jobcreator }).map(function (data) { return data.profilepicture ? data.profilepicture : userlogo })} className='w-[35px] h-[35px] object-cover rounded-full' />
                <p className='text-textcolor PoppinsRegular text-sm ml-[10px]'>{clients.filter(function (data) { return data.id == item.jobcreator }).map(function (data) { return data.name })}</p>
              </div>
              {user.usertype == "Employee" &&
              <>
                {item?.applicants?.find(data => data == user?.id) == undefined ?
                  <button
                    onClick={() => apply(item.jobcreationdate, index)}
                    type='submit' className="text-center PoppinsRegular bg-themeColor hover:bg-themeColor/80 font-medium py-[2px] px-3 rounded-md text-white text-[12px]   ">
                    Apply
                  </button>
                  :
                  <div className="text-center  bg-green-500 flex items-center justify-center py-2 px-3 rounded-md   ">
                    <p className='text-white text-[12px] PoppinsRegular font-medium'>Applied!</p>
                  </div>}
              </>
              }
            </div>
          </div>
        ))}

        {jobs.length < 1 &&
        <div className='w-full h-screen flex justify-center items-center'>
        <p className='text-20px text-lightgrey'>No Jobs Available</p>
        </div>}
      </div>
    </div>
  );
}

export default MarketPlace;