import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { saveitem, saveuserdata } from '../../utils/firebasefunctions';
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { RotatingLines } from 'react-loader-spinner'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable
} from "firebase/storage";
import { storage, app } from '../../firebase.config'
import TimePicker from 'react-time-picker';
import { FaDollarSign } from 'react-icons/fa';
import { FaPercent } from 'react-icons/fa';


// import Section2 from '../components/Homesection/section2'
function CreateProject() {
    const [{ user, employees }, dispatch] = useStateValue();
    const [taskdescription, settaskdescription] = useState("");
    const [taskname, settaskname] = useState("");
    const [selectedemployee, setselectedemployee] = useState("");
    const [duedate, setduedate] = useState(new Date());
    const [startdate, setstartdate] = useState(new Date());
    const [employeepopup, setemployeepopup] = useState(false);
    const [loader, setloader] = useState(false)
    const [picturedata, setpicturedata] = useState("");
    const [picture, setpicture] = useState("");
    const [customeremail, setcustomeremail] = useState("");
    const [customerphonenumber, setcustomerphonenumber] = useState("");
    const [password, setpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [Location, setLocation] = useState("");
    const [normalstarttime, setnormalstarttime] = useState("00:00")
    const [normalendtime, setnormalendtime] = useState("13:00")
    const [normalPer, setnormalPer] = useState("");
    const [eveningstarttime, seteveningstarttime] = useState("13:01")
    const [eveningendtime, seteveningendtime] = useState("19:00")
    const [eveningPer, seteveningPer] = useState("");
    const [nightstarttime, setnightstarttime] = useState("19:01")
    const [nightendtime, setnightendtime] = useState("23:59")
    const [nightPer, setnightPer] = useState("");
    const [selected, setSelected] = useState('option1');
    const [multiplicatorPer, setmultiplicatorPer] = useState(0);
    const [fixedprice, setfixedprice] = useState(0);



    const auth = getAuth();
    const fileInput = useRef();
    const handleToggle = (option) => {
        setSelected(option);
    };
    const handletaskChange = (event) => {
        settaskname(event.target.value)
    }

    const handletaskdescriptionChange = (event) => {
        settaskdescription(event.target.value)
    }
    const handlenormalPer = (event) => {
        setnormalPer(event.target.value)
    }
    const handleeveningPer = (event) => {
        seteveningPer(event.target.value)
    }
    const handlenightPer = (event) => {
        setnightPer(event.target.value)
    }
    const createtask = async () => {
        if (taskname == "") {
            toast.warn("Please fill out a project name")
        } else if (taskdescription == "") {
            toast.warn("Please fill out a project description")
        } else if (customeremail == "") {
            toast.warn("Please enter your project email")
        } else if (customerphonenumber == "") {
            toast.warn("Please enter your project contact number")
        }
        else if (selected === 'option1' && multiplicatorPer == 0) {
            toast.warn("Please fill the Multiplicator Percentage")
        }
         else if (Location == "") {
            toast.warn("Please select a work location!")
          }
        else if (selected === 'option2' && fixedprice == 0) {
            toast.warn("Please enter the Fixed Price")
        }
        else if (normalstarttime == '' || normalstarttime == null) {
            toast.warn("Please Enter Normal Start Time")
        }
        else if (normalendtime == '' || normalendtime == null) {
            toast.warn("Please Enter Normal End Time")
        }
        else if (normalPer == '') {
            toast.warn("Please Enter Normal Percentage")
        }
        else if (eveningstarttime == '' || eveningstarttime == null) {
            toast.warn("Please Enter Evening Start Time")
        }
        else if (eveningendtime == '' || eveningendtime == null) {
            toast.warn("Please Enter Evening End Time")
        }
        else if (eveningPer == '') {
            toast.warn("Please Enter Evening Percentage")
        }
        else if (nightstarttime == '' || nightstarttime == null) {
            toast.warn("Please Enter Night Start Time")
        }
        else if (nightendtime == '' || nightendtime == null) {
            toast.warn("Please Enter Night End Time")
        }
        else if (nightPer == '') {
            toast.warn("Please Enter Night Percentage")
        }
        else if (doThreeTimesNotCollide() == false) {
            toast.warn("Time Slots can not overlap")
        }
        else {


            setloader(true)
            const res = await fetch('https://us-central1-raftekpro-82bb0.cloudfunctions.net/api/createaccount', {
                // const res = await fetch('http://localhost:3002/createaccount', {

                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: customeremail,
                  password: "abcd1234"
                })
              });
        if(res.status == 200){
        const dataresponse = await res.json();
        if (picture == "") {
            let data = {
                id:  dataresponse.data.uid,
                logo: "https://firebasestorage.googleapis.com/v0/b/raftekpro-82bb0.appspot.com/o/profilepicture%2F1719855616981-user.png?alt=media&token=d4fb1df5-e98e-47ea-b695-7467d20e0cfb",
                creationdate: Date.now(),
                title: taskname,
                createdby: user.id,
                description: taskdescription,
                email: customeremail,
                location: Location,
                phonenumber: customerphonenumber,
                usertype: "Project",
                multiplicatorPercentage: multiplicatorPer,
                multiplicatorPercentageStatus: selected === 'option1' ? true : false,
                fixedprice: fixedprice,
                fixedpriceStatus: selected === 'option2' ? true : false,
                normalstarttime: normalstarttime,
                normalendtime: normalendtime,
                normalPercentage: normalPer,
                eveningstarttime: eveningstarttime,
                eveningendtime: eveningendtime,
                eveningPercentage: eveningPer,
                nightstarttime: nightstarttime,
                nightendtime: nightendtime,
                nightPercentage: nightPer
            }



            await saveuserdata(data,  dataresponse.data.uid).then((response) => {
                if (response == "success") {
                    toast.success("Project created Successfully")
                    settaskdescription("")
                    settaskname("")
                    setpicture("")
                    setpicturedata("")
                    setcustomeremail("")
                    setcustomerphonenumber("")
                    setpassword("")
                    setconfirmpassword("")
                } else {
                    toast.error("Something went wrong while posting your task. Please try again later.")
                }

                setloader(false)
            }).catch((err) => {
                alert(err)
                setloader(false)
            })
        } else {


            const imageFile = picturedata.target.files[0]
            const storageRef = ref(storage, `profilepicture/${Date.now()}-${imageFile.name}`)
            const uploadTask = uploadBytesResumable(storageRef, imageFile)
            uploadTask.on('state_changed', (snapshot) => {
                //   const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
            }, (error) => {
                setloader(false)
                toast.error("upload error " + error.message);
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {




                    let data = {
                        id:  dataresponse.data.uid,
                        logo: downloadURL,
                        creationdate: Date.now(),
                        title: taskname,
                        createdby: user.id,
                        description: taskdescription,
                        email: customeremail,
                        phonenumber: customerphonenumber,
                        usertype: "Project",
                        location: Location,
                        multiplicatorPercentage: multiplicatorPer,
                        multiplicatorPercentageStatus: selected === 'option1' ? true : false,
                        fixedprice: fixedprice,
                        fixedpriceStatus: selected === 'option2' ? true : false,
                        normalstarttime: normalstarttime,
                        normalendtime: normalendtime,
                        normalPercentage: normalPer,
                        eveningstarttime: eveningstarttime,
                        eveningendtime: eveningendtime,
                        eveningPercentage: eveningPer,
                        nightstarttime: nightstarttime,
                        nightendtime: nightendtime,
                        nightPercentage: nightPer
                    }



                    await saveuserdata(data,  dataresponse.data.uid).then((response) => {
                        if (response == "success") {
                            toast.success("Project created Successfully")
                            settaskdescription("")
                            settaskname("")
                            setpicture("")
                            setpicturedata("")
                            setcustomeremail("")
                            setcustomerphonenumber("")
                            setpassword("")
                            setconfirmpassword("")
                        } else {
                            toast.error("Something went wrong while posting your task. Please try again later.")
                        }

                        setloader(false)
                    }).catch((err) => {
                        alert(err)
                        setloader(false)
                    })


                })
                    .catch((error) => {
                        setloader(false)
                        toast.error(error.message);
                    });
            })
        }

        }else{
            let error = await res.json()
                    toast.error(error.error)
                    setloader(false)
                    }
            // createUserWithEmailAndPassword(auth, customeremail, "abcd1234")
            //     .then(async (userCredential) => {
            //         const createduser = userCredential.user;




                // })
                // .catch((error) => {
                //     const errorCode = error.code;
                //     const errorMessage = error.message;
                //     toast.error(errorMessage)
                //     setloader(false)
                //     // ..
                // });



        }
    }

    const handleimageChange = (event) => {
        fileInput.current.click();
    }


    const handleimageselected = (e) => {
        setpicturedata(e)
        setpicture(URL.createObjectURL(e.target.files[0]));
    }

    const handlecustomeremailChange = (event) => {
        setcustomeremail(event.target.value)
    }

    const handlecustomerphonenumberChange = (event) => {
        setcustomerphonenumber(event.target.value)
    }
    const handlemultiplicatorPer = (event) => {
        setmultiplicatorPer(event.target.value)
    }
    const handlefixedprice = (event) => {
        setfixedprice(event.target.value)
    }
    const handlecpasswordChange = (event) => {
        setpassword(event.target.value)
    }

    const handleconfirmpasswordChange = (event) => {
        setconfirmpassword(event.target.value)
    }


    function doThreeTimesNotCollide() {
        // Convert time strings to Date objects
        function toDate(time) {
            const [hour, minute] = time.split(':').map(Number);
            return new Date(2000, 0, 1, hour, minute);
        }

        // Convert all time strings to Date objects
        const startTime1 = toDate(normalstarttime);
        const endTime1 = toDate(normalendtime);
        const startTime2 = toDate(eveningstarttime);
        const endTime2 = toDate(eveningendtime);
        const startTime3 = toDate(nightstarttime);
        const endTime3 = toDate(nightendtime);

        // Check if two intervals do not collide
        function doIntervalsNotCollide(startA, endA, startB, endB) {
            return endA <= startB || endB <= startA;
        }

        // Check non-collision between all pairs
        const notCollide12 = doIntervalsNotCollide(startTime1, endTime1, startTime2, endTime2);
        const notCollide13 = doIntervalsNotCollide(startTime1, endTime1, startTime3, endTime3);
        const notCollide23 = doIntervalsNotCollide(startTime2, endTime2, startTime3, endTime3);

        // If all pairs do not collide, return true; otherwise, return false
        return notCollide12 && notCollide13 && notCollide23;
    }

    return (
        <div className='w-[100%]  px-3 lg:px-7 rounded-xl  py-[20px] border border-gray-300 mx-auto overflow-scroll  bg-cardOverlay  drop-shadow-lg bg-white  mt-[70px] md:mt-[0px] '>
            <div className='w-full flex justify-center'>
                <div
                    className='flex justify-center my-[10px] border border-white rounded-full items-center relative mt-5 lg:mt-0'

                >
                    {picture ? <img src={picture} className='w-[110px] h-[110px] object-cover rounded-full  bg-cardOverlay  drop-shadow-lg' /> : <FaUser

                        className="text-lighttextGray/80 p-4 border border-gray-300 rounded-full w-[110px] h-[110px]  bg-white  drop-shadow-lg"
                    />}
                    <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleimageselected} />
                    <div
                        onClick={handleimageChange}

                        className='absolute bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full right-[-10px] top-[70px] cursor-pointer'>
                        <MdEdit

                            className="text-textcolor w-[20px] h-[20px]"
                        />
                    </div>

                </div>
            </div>

            <div className='w-full'>
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Project Name</p>
                <input type='text'
                    value={taskname} placeholder='Project Name'
                    onChange={handletaskChange}
                    className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex" />
            </div>

            <div className='w-full mt-5' >
                <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Project Description</p>
                <textarea type='text' placeholder='Project Description'

                    value={taskdescription}
                    onChange={handletaskdescriptionChange}
                    className="px-4 text-black text-base font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex h-[200px]" />
            </div>

            <div className='w-full mt-[20px]' >
                <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Project Email</p>
                <input type='text'
                    value={customeremail} placeholder='Project Email'
                    onChange={handlecustomeremailChange}
                    className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex" />
            </div>
            <div className='w-full mt-[20px]' >
                <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Project Contact Number</p>
                <input type='text'
                    value={customerphonenumber} placeholder='Project Contact Number'
                    onChange={handlecustomerphonenumberChange}
                    className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex" />
            </div>
            <div className='w-full mt-[20px] mb-[30px] PoppinsRegular' >
          <p className='PoppinsRegular text-textcolor text-sm font-medium pb-1'>Location</p>

          <GooglePlacesAutocomplete
            apiKey='AIzaSyBQITYyVOR2eVeTha4Vzfc2T8S0ZAcENDE'

            selectProps={{
              Location,
              onChange: setLocation,
              placeholder: "Select your address",

            }}
            name="address"
            value={Location}
          />


        </div>
            <div>
                <div className='w-full  flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>

                    <button
                        className={`className= w-full lg:w-[48%] rounded-xl border h-[45px] border-themeColor ${selected === 'option1' ? 'bg-themeColor text-white' : ''}`}
                        onClick={() => {
                            setmultiplicatorPer(0)
                            handleToggle('option1')
                        }
                        }
                    >
                        Multiplicator Price
                    </button>
                    <button
                        className={`className= mt-4 lg:mt-0 w-full lg:w-[48%] rounded-xl border h-[45px] border-themeColor  ${selected === 'option2' ? 'bg-themeColor text-white' : ''}`}
                        onClick={() => {
                            setfixedprice(0)
                            handleToggle('option2')
                        }}
                    >
                        Fixed Price
                    </button>
                </div>
                <div className='w-full mt-[20px]' >
                    <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>{selected === 'option1' ? 'Multiplicator' : 'Price'}</p>
                    <div className='w-full py-[10px] rounded-xl border border-themeColor items-center flex flex-row'>
                        {selected === 'option2' &&
                            <div className='pl-5'>
                                <FaDollarSign />
                            </div>
                        }
                        <input type='text'
                            inputMode='numeric'
                            maxLength={selected === 'option1' && 4}
                            value={selected === 'option1' ? multiplicatorPer : fixedprice}
                            placeholder={selected === 'option1' ? 'Multiplicator' : 'Price'}
                            onChange={selected === 'option1' ? handlemultiplicatorPer : handlefixedprice}
                            className="p-[0px] text-textcolor text-sm font-medium PoppinsRegular  w-[95%] lg:w-[97%]  px-[17px] lg:px-4 border-none items-center flex"
                        />
                        {selected === 'option1' ?
                            null:
                            <p className='text-textcolor  text-lg pr-2 '>/hr</p>
                        }
                    </div>
                </div>
                {/* <div className="input-container">
                    {selected === 'option1' && (
                        <input type="text" placeholder="Text input for Heading 1" />
                    )}
                    {selected === 'option2' && (
                        <input type="text" placeholder="Text input for Heading 2" />
                    )}
                </div> */}
            </div>
            <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                <div className='w-full lg:w-[32%]' >
                    <p className='PoppinsRegular font-medium  text-sm pb-1'>Noraml Start Time</p>
                    <TimePicker
                    format = "HH:mm"
                        className='rounded-xl border-themeColor w-full h-[45px]'
                        onChange={setnormalstarttime}
                        value={normalstarttime}
                    />
                </div>
                <div className='w-full lg:w-[32%] mt-4 lg:mt-0' >
                    <p className='PoppinsRegular font-medium text-sm pb-1'>Normal End Time</p>
                    <TimePicker
                    format = "HH:mm"
                    className='rounded-xl  border-themeColor w-full   h-[45px] '
                        onChange={setnormalendtime}
                        value={normalendtime} />
                </div>

                <div className='w-full lg:w-[32%] mt-4 lg:mt-0' >
                    <p className='PoppinsRegular font-medium  text-sm pb-1'>Percentage</p>
                    <div className='w-full py-[10px] rounded-xl border border-themeColor items-center flex flex-row'>
                        <input type='text'
                            value={normalPer}
                            placeholder='Normal Percentage'
                            onChange={handlenormalPer}
                            inputMode='numeric'
                            maxLength={3}
                            className="input text-textcolor text-base font-medium PoppinsRegular border-none p-[0px] w-[95%] lg:w-[90%] px-[17px] " />
                        <div>
                            <FaPercent />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                <div className='w-full lg:w-[32%]' >
                    <p className='PoppinsRegular font-medium  text-sm pb-1'>Evening Start Time</p>
                    <TimePicker
                    format = "HH:mm"
                    className='rounded-xl  border-themeColor w-full   h-[45px] '
                        onChange={seteveningstarttime
                            //     (time) => {
                            //     doTwoTimesNotCollide(normalstarttime, normalendtime, time, eveningendtime, 'start')
                            // }
                        }
                        value={eveningstarttime} />
                </div>
                <div className='w-full lg:w-[32%] mt-4 lg:mt-0' >
                    <p className='PoppinsRegular font-medium  text-sm pb-1'>Evening End Time</p>
                    <TimePicker
                    format = "HH:mm"
                    className='rounded-xl  border-themeColor w-full   h-[45px] '
                        onChange={seteveningendtime
                            //     (time) => {
                            //     doTwoTimesNotCollide(normalstarttime, normalendtime, eveningstarttime, time, 'end')
                            // }
                        }
                        value={eveningendtime} />
                </div>

                <div className='w-full lg:w-[32%] mt-4 lg:mt-0' >
                    <p className='PoppinsRegular font-medium  text-sm pb-1'>Percentage</p>
                    <div className='w-full py-[10px] rounded-xl border border-themeColor items-center flex flex-row'>
                        <input type='text'
                            value={eveningPer}
                            placeholder='Evening Percentage'
                            onChange={handleeveningPer}
                            inputMode='numeric'
                            maxLength={3}
                            className="input  text-textcolor text-base font-medium PoppinsRegular border-none p-[0px] w-[95%] lg:w-[90%] px-[17px] " />
                        <div>
                            <FaPercent />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-center lg:justify-between mt-[20px]'>
                <div className='w-full lg:w-[32%]' >
                    <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Night Start Time</p>
                    <TimePicker
                    format = "HH:mm"
                    className='rounded-xl  border-themeColor w-full   h-[45px] ' onChange={setnightstarttime} value={nightstarttime} />
                </div>
                <div className='w-full lg:w-[32%] mt-4 lg:mt-0' >
                    <p className='PoppinsRegular font-medium text-textcolor/80 text-sm pb-1'>Night End Time</p>
                    <TimePicker
                    format = "HH:mm"
                    className='rounded-xl  border-themeColor w-full   h-[45px] ' onChange={setnightendtime} value={nightendtime} />
                </div>
                <div className='w-full lg:w-[32%] mt-4 lg:mt-0' >
                    <p className='PoppinsRegular font-medium  text-sm pb-1'>Percentage</p>
                    <div className='w-full py-[10px] rounded-xl border border-themeColor items-center flex flex-row'>
                        <input type='text'
                            value={nightPer}
                            placeholder='Night Percentage'
                            onChange={handlenightPer}
                            inputMode='numeric'
                            maxLength={3}
                            className="input  text-textcolor text-base font-medium PoppinsRegular border-none p-[0px] w-[95%] lg:w-[90%] px-[17px] " />
                        <div>
                            <FaPercent />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='w-full mt-[20px]' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Password</p>
            <input type="password"
            value={password} placeholder='Password'
            onChange={handlecpasswordChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div>
        <div className='w-full mt-[20px]' >
            <p className='PoppinsRegular text-textcolor font-medium text-sm pb-1'>Confirm Password</p>
            <input type="password"
            value={confirmpassword} placeholder='Confirm Password'
            onChange={handleconfirmpasswordChange}
            className="px-4 text-textcolor text-sm font-medium PoppinsRegular w-full py-2 rounded-xl border border-themeColor items-center flex"/>
        </div> */}

            {loader ?
                <div
                className=" py-4  mt-6 w-full flex justify-center  "
                >
                    <RotatingLines
                        visible={true}
                        height="25"
                        width="25"
                        color="grey"
                        strokeColor="#218fdb"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                :
                <button
                    onClick={() => {
                        createtask()
                    }}
                    type='submit' className='text-center py-4 rounded-2xl mt-6 bg-themeColor hover:bg-themeColor/80 w-full  mx-auto text-white text-sm font-semibold PoppinsRegular uppercase'>
                    Create Project
                </button>
            }
        </div>
    );
}

export default CreateProject;