

import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import { useNavigate } from 'react-router-dom'

import userlogo from '../images/user.png'
import './index.css'

const ProjectHome=(props) => {

    const [{user, employees, clients, projects, projectemployees, projectsupervisors}, dispatch] = useStateValue();

    const navigate = useNavigate();

    return(
        <div className='w-[100%] mt-[100px]'>




<div className='w-full bg-themeColor px-[25px] py-[25px] rounded-lg'>
<div className=' flex flex-col justify-center md:justify-start md:flex-row items-center'>
<img src={user?.logo ? user?.logo : userlogo } className='w-[80px] h-[80px] lg:w-[120px] lg:h-[120px] rounded-full' />

              <h1 className='PoppinsRegular  font-semibold text-2xl lg:text-4xl text-white mt-[10px] md:mt-[0px] md:ml-[20px]'>
                {user?.title}
              </h1>
            </div>
            <p className='PoppinsRegular text-white pt-3 lg:pt-6 text-sm lg:text-base mt-[10px] mb-[30px]'>{user?.description}</p>
          </div>

          <h1 className='text-[22px] text-textcolor PoppinsRegular font-semibold mt-[30px]'>Supervisor's</h1>

    <div className="overflow-x-auto rounded-lg bg-white  drop-shadow-lg mt-5">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
              <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Name</th>
              <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Designation</th>
              <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Email</th>
              <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Phone Number</th>
              {/* <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor pr-[15px]">Time Slot</th> */}

            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 text-center">
            {projectsupervisors && projectsupervisors.map((item, index) =>
              (
              <tr
              key = {index.toString()}
              className='hover:bg-[#0989B826]/10 '
              //  onClick={()=>{

              //   navigate('/dashboard', {state: {
              //     employeedata: item
              //   }});
              // }}
              >
                <td className="whitespace-nowrap px-4 py-2 flex justify-center font-medium PoppinsRegular text-textcolor">
                  <img src={item.profilepicture != "" ? item.profilepicture : userlogo } className='w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] rounded-full' />
                </td>
                <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.firstname} {item.lastname}</td>
                <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.designation ? item.designation : "N/A"}</td>
                <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.email}</td>
                <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item?.phonenumber}</td>

                {/* <td className='pr-[15px]'>
                {item?.starttime && item?.endtime ?
                  <text className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{`${item.starttime} - ${item.endtime}`}</text>
              :
                <button
                onClick={() =>{
                  navigate('/CreateEmployeeSchedule', {state: {
                    employeedata: item
                  }});
                }}
                type='submit' className="text-center w-full py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Assign</button>
}
</td> */}

              </tr>
              )
              )
            }
          </tbody>
      </table>
    </div>
          <h1 className='text-[22px] text-textcolor PoppinsRegular font-semibold mt-[30px]'>Employees</h1>

          <div className="overflow-x-auto rounded-lg bg-white  drop-shadow-lg mt-5">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor"></th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Name</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Designation</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Email</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor">Phone Number</th>
                    <th className="whitespace-nowrap py-2 font-medium PoppinsRegular text-textcolor pr-[15px]">Time Slot</th>

                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 text-center">
                  {projectemployees && projectemployees.map((item, index) =>
                    (
                    <tr
                    key = {index.toString()}
                    className='hover:bg-[#0989B826]/10 '
                    //  onClick={()=>{

                    //   navigate('/dashboard', {state: {
                    //     employeedata: item
                    //   }});
                    // }}
                    >
                      <td className="whitespace-nowrap px-4 py-2 flex justify-center font-medium PoppinsRegular text-textcolor">
                        <img src={item.profilepicture != "" ? item.profilepicture : userlogo } className='w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] rounded-full' />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.firstname} {item.lastname}</td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.projectdesignation ? item.projectdesignation : "N/A"}</td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item.email}</td>
                      <td className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{item?.phonenumber}</td>

                      <td className='pr-[15px]'>
                      {item?.starttime && item?.endtime ?
                        <p className="whitespace-nowrap px-4 py-2 PoppinsRegular font-medium text-textcolor">{`${item.starttime} - ${item.endtime}`}</p>
                    :
                      <button
                      onClick={() =>{
                        navigate('/CreateEmployeeSchedule', {state: {
                          employeedata: item
                        }});
                      }}
                      type='submit' className="text-center w-full py-[5px] rounded-md bg-themeColor text-white text-sm PoppinsRegular uppercase font-medium ">Assign</button>
}
</td>

                    </tr>
                    )
                    )
                  }
                </tbody>
            </table>
          </div>


        </div>
    )
}


export default ProjectHome