import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { getAllBlogsPosts, getAllBlogsPostsClient, getAllEmployeeTasks, saveitem, updateItem } from '../../utils/firebasefunctions';
import { getFirestore, collection, getDocs, writeBatch, doc } from "firebase/firestore";

import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { TbCurrencyDollar } from "react-icons/tb";
import userlogo from '../images/user.png'
import moment from 'moment'
import Loader from "react-js-loader";
import { BsEmojiSurpriseFill } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import wow from '../images/wow.gif'
import heart from '../images/heart.gif'
import thumbsup from '../images/thumbsup.gif'
// import Section2 from '../components/Homesection/section2'
function Blogs() {
  const [{ user, employees, jobs, clients , blogs, users}, dispatch] = useStateValue();
  const [blogpost, setblogpost] = useState("");
  const [blogtype, setblogtype] = useState("General News");
  const [Loading, setLoading] = useState(false);




  useEffect(() => {
    getallblogs()
  }, [blogtype])

useEffect(()=>{
  markread()
},[])
  const markread = async () =>{
    if(user?.newblogpost == true){
    let data = {
      newblogpost: false
    }


    await updateItem("users", user.id, data).then((response) => {
      let dataobj = {...user}
        dataobj["newblogpost"] = false
        dispatch({
          type: actionType.SET_USER,
          payload: dataobj
      })
  }).catch((err) => {
console.log("err " + err)
  })



  }
  }


  const getallblogs = async () => {
    if(user?.usertype == "SuperAdmin"){
      await getAllBlogsPosts(blogtype == "General News" ? "blogsgeneralnews" : "blogsnews", "blogcreationdate", "desc").then(async (response) => {
        let data = [...response]
        for (const key in data){
            data[key]["viewmore"] = false
        }

        dispatch({
          type: actionType.STORE_BLOGS,
          payload: data
        })
      }).catch((err) => {
        alert(err)
      })
    }else{
      await getAllBlogsPostsClient(blogtype == "General News" ? "blogsgeneralnews" : "blogsnews", "blogcreationdate", "desc",  user?.usertype == "Admin"   ?   user?.id : user?.createdby).then(async (response) => {
        let data = [...response]
        for (const key in data){
            data[key]["viewmore"] = false
        }

        dispatch({
          type: actionType.STORE_BLOGS,
          payload: data
        })
      }).catch((err) => {
        console.log(err)
      })
    }

  }




  const postblog = async () =>{
    if(blogpost == ""){
        toast.warn("Post can not be empty!")
} else {
    setLoading(true)
    let data = {
      admin: user?.usertype == "Admin" || user?.usertype == "SuperAdmin"  ?   user?.id : user?.createdby,
      blogcreationdate: Date.now(),
      blogcreator: user?.id,
      description: blogpost,
      wow: [],
      like: [],
      heart: []
    }




    await updateItem(blogtype == "General News" ? "blogsgeneralnews" : "blogsnews",data.blogcreationdate, data).then(async (response) => {
      if (response == "success") {
        toast.success("Blog posted Successfully")
        setblogpost("")
        let blogpost = []
        blogpost.push(data)
        dispatch({
            type: actionType.ADD_BLOG,
            payload: blogpost
          })



          const db = getFirestore();
          const batch = writeBatch(db);
        let updateData = {
          newblogpost: true
        }
          // Loop through each document ID and add it to the batch
          employees.forEach((item) => {
            const docRef = doc(db, "users", item.id);
            batch.update(docRef, updateData);
          });

          // Commit the batch
          await batch.commit();

          console.log("Batch update completed for specified documents.");

      } else {
        toast.error("Something went wrong while posting your blog. Please try again later.")
      }


      setLoading(false)
    }).catch((err) => {
      alert(err)
      setLoading(false)
    })

  }
  }


  return (
    <div className={`w-[100%] mb-20 min-h-screen  mt-[50px] ${user?.usertype == "Project" ? "md:mt-[90px]" : "md:mt-[0px]"}  ${user.usertype == "Project" ? "px-[40px]" : null}`}>
      <div className='flex flex-wrap justify-between'>
<div className='w-full flex flex-row justify-center  md:justify-start pt-[20px]  pb-[10px]'>
      <p
      onClick={()=>{setblogtype("General News")}}
      className={`PoppinsRegular text-[14px] font-weight:400  cursor-pointer hover:underline  ${blogtype == "General News" ? "text-themeColor" : "text-black"}`} >General News</p>
      <p className='mx-[10px]'>|</p>
      <p
        onClick={()=>{setblogtype("News")}}
      className={`PoppinsRegular text-[14px] font-weight:400  cursor-pointer hover:underline  ${blogtype == "News" ? "text-themeColor" : "text-black"}`}>News</p>
      </div>
       { user?.usertype == "Client" || user?.usertype == "Project" || user?.usertype == "Admin" || user?.usertype == "SuperAdmin" ?
        <div className='w-full py-[20px] rounded-md  '>
        <div className='flex  sm:flex-col h-50px items-start mt-[5px] bg-white  drop-shadow-lg  rounded-xl px-5 lg:px-8 py-4 w-full'>
          <div className='flex flex-row justify-start items-center mb-[10px] border-b  border-lightgrey w-full py-[15px]'>
        <img src={user?.profilepicture ? user?.profilepicture : user?.logo ? user?.logo : userlogo } className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] object-cover rounded-full' />
        <div className='flex flex-col ml-[15px]'>
                    <p className='text-black PoppinsRegular  text-base '>{user?.usertype == "Project"  ? user?.name : user?.usertype == "Project" ? user?.title : user?.firstname + " " + user?.lastname}</p>
                    <p className='text-lightgrey PoppinsRegular text-[12px]'>{user?.usertype}</p>
                    </div>
                    </div>
     <textarea
        value={blogpost}
        placeholder='Whats on your mind...' onChange={(event)=>{setblogpost(event.target.value)}} className=" text-black text-base font-medium PoppinsRegular w-full py-3 rounded-2xl  placeholder:text-black/60 border-white/20 focus:border-0 items-center flex bg-white" rows={1} />


  <div className='w-full flex justify-end'>
            {Loading ?
            <Loader type="spinner-cub" bgColor={"textcolor/60"}  size={30} /> :
        <div
        onClick={()=>{postblog()}}
        className="flex items-center justify-center  w-[100px] rounded-md bg-themeColor  h-[30px] mt-[10px]">
            <p className='text-white text-base font-semibold PoppinsRegular uppercase'>Post</p>
            </div>
}
            </div>

        {/* <div className=' rounded-xl overflow-hidden border border-stone-900 w-full ml-[10px]'>
        <ReactQuill
    style={{
      // height: '120px', // Set the desired height here
      resize: 'none', // Disable resizing
      overflow: "scroll",
      width:"100%",
      // backgroundColor:"red",
      color:"black",
      // borderRadius:"20px",
   }}
      ref={quillRef}
      theme="snow"
      // value={editorHtml}
      onKeyPress={handleKeyPress}
      onChange={(value) => {

        // setEditorHtml(value)
      }}
      modules={modules}
      formats={formats}

      placeholder="Your message"
    />
    </div> */}
         </div>

            </div>


            : null}
        {blogs.map((item, index) => (

          <div

          key = {index.toString()}
          className={`px-5 lg:px-8 py-4 w-full flex flex-col items-start bg-white  drop-shadow-lg rounded-xl mt-5 min-h-[200px] `}>



      <div className='flex w-full justify-between items-start'>
            <div
              onClick={() => {
              }}
              className=' flex justify-between  py-[15px] '>
              <div className='flex flex-row items-center '>
                <img src={users.filter(function (data) { return data.id == item.blogcreator }).map(function (data) { return data.profilepicture ? data.profilepicture : data.logo ? data.logo : userlogo })} className='w-[35px] h-[35px] object-cover rounded-full' />
                <p className='text-textcolor PoppinsRegular text-sm ml-[10px]'>{users.filter(function (data) { return data.id == item.blogcreator }).map(function (data) { return data.usertype == "Client" ? data.name : data.usertype == "Project" ?  data.title : data.firstname + " " + data.lastname })}</p>
              </div>

            </div>
            <p className='PoppinsRegular font-medium text-textcolor/60 text-sm '>{ moment(item.blogcreationdate).fromNow()}</p>
          </div>
            <div className='w-full h-[1px] bg-gray-300'></div>

            <p

className={`mb-8 PoppinsRegular font-medium text-[14px] text-textcolor pt-4 w-full overflow-hidden ${item.viewmore ? null : "line-clamp-2"} `}>
              {item.description}
            </p>
            {(item.like.length + item.wow.length + item.heart.length) > 0 &&
            <div className='flex flex-row items-center justify-start relative w-full pb-[15px]'>
         {item.like.length > 0 &&
            <AiFillLike

className="text-themeColor  w-[15px] "
/>}
{item.heart.length > 0 &&
<FaHeart

className="text-red-500  w-[15px]   "
/>}
{item.wow.length > 0 &&
<BsEmojiSurpriseFill
className="text-yellow-500  w-[15px] "
/>}

            <p
className={` PoppinsRegular font-medium text-[14px] text-textcolor  ml-[5px] `}>
              {item.like.length + item.wow.length + item.heart.length}
            </p>
            </div>}

<div className='flex relative justify-end w-full '>
{item?.showiconsuggessions &&
            <div className='flex flex-row items-center bg-white  bg-cardOverlay  drop-shadow-lg border rounded-full px-[10px] position absolute left-[20px] top-[-40px] hover:cursor-pointer h-[40px]'>
            <img
            onClick={async()=>{
              let data = [...blogs]
           let item = data[index].wow.find((element) => element === user.id);
       if(item == undefined ){
        data[index].wow.push(user.id)
       }else{
        data[index].wow =   data[index].wow.filter((item) => item !== user.id);
       }
       data[index].like =   data[index].like.filter((item) => item !== user.id);
       data[index].heart =   data[index].heart.filter((item) => item !== user.id);
       data[index]["showiconsuggessions"] = false
       dispatch({
        type: actionType.STORE_BLOGS,
        payload: data
      })
      await updateItem(blogtype == "General News" ? "blogsgeneralnews" : "blogsnews", data[index].blogcreationdate,  data[index]).then(async (response) => {
        if (response == "success") {


        } else {
          toast.error("Something went wrong while posting your blog. Please try again later.")
        }


        // setLoading(false)
      }).catch((err) => {
        alert(err)
        // setLoading(false)
      })
            }}
            src={wow} className='w-6 hover:w-8'/>
            <img
              onClick={async()=>{
                let data = [...blogs]
             let item = data[index].heart.find((element) => element === user.id);
         if(item == undefined ){
          data[index].heart.push(user.id)
         }else{
          data[index].heart =   data[index].heart.filter((item) => item !== user.id);
         }
         data[index].like =   data[index].like.filter((item) => item !== user.id);
         data[index].wow =   data[index].wow.filter((item) => item !== user.id);
         data[index]["showiconsuggessions"] = false
         dispatch({
          type: actionType.STORE_BLOGS,
          payload: data
        })
        await updateItem(blogtype == "General News" ? "blogsgeneralnews" : "blogsnews", data[index].blogcreationdate,  data[index]).then(async (response) => {
          if (response == "success") {


          } else {
            toast.error("Something went wrong while posting your blog. Please try again later.")
          }


          // setLoading(false)
        }).catch((err) => {
          alert(err)
          // setLoading(false)
        })
              }}
            src={heart} className='w-10 hover:w-12'/>
            <img
              onClick={async()=>{
                let data = [...blogs]
             let item = data[index].like.find((element) => element === user.id);
         if(item == undefined ){
          data[index].like.push(user.id)
         }else{
          data[index].like =   data[index].like.filter((item) => item !== user.id);
         }
         data[index].wow =   data[index].wow.filter((item) => item !== user.id);
         data[index].heart =   data[index].heart.filter((item) => item !== user.id);
         data[index]["showiconsuggessions"] = false
         dispatch({
          type: actionType.STORE_BLOGS,
          payload: data
        })
        await updateItem(blogtype == "General News" ? "blogsgeneralnews" : "blogsnews", data[index].blogcreationdate,  data[index]).then(async (response) => {
          if (response == "success") {


          } else {
            toast.error("Something went wrong while posting your blog. Please try again later.")
          }


          // setLoading(false)
        }).catch((err) => {
          alert(err)
          // setLoading(false)
        })
              }}
            src={thumbsup} className='w-6 hover:w-8'/>
            </div>
}
<div
onClick={()=>{
let data = [...blogs]
if(data[index]?.showiconsuggessions == true){
  data[index]["showiconsuggessions"] = false
}else{
  for (const key in data){
    data[key]["showiconsuggessions"] = false
  }
  data[index]["showiconsuggessions"] = true
}

  dispatch({
    type: actionType.STORE_BLOGS,
    payload: data
  })
}}
className='flex flex-row items-center w-[50%] justify-start hover:cursor-pointer'>
  {/* <div className='flex flex-row items-center justify-center z-0'>
            <FaHeart

className="text-red-500 w-[20px] h-[20px]"
/>

</div>
<div className='flex flex-row items-center justify-center ml-[10px] z-10 absolute left-[3px]'>
<BsEmojiSurpriseFill

className="text-yellow-500  w-[20px] h-[20px]"
/>

            </div>
            <div className='flex flex-row items-center justify-center ml-[10px] z-20 absolute left-[15px]'>
<AiFillLike

className="text-blue-500  w-[20px] h-[20px]"
/>

            </div> */}
             {item.like.find((element) => element === user.id) != undefined ?
             <>
            <AiFillLike

className="text-themeColor  w-[20px] "
/>
               <p

className={` PoppinsRegular text-sm text-themeColor  `}>
             Like
            </p>
            </> : item.wow.find((element) => element === user.id) != undefined ?
               <>
               <BsEmojiSurpriseFill

   className="text-yellow-500  w-[20px] "
   />
                  <p

   className={` PoppinsRegular text-sm text-yellow-500  `}>
                wow
               </p>
               </> : item.heart.find((element) => element === user.id) != undefined ?
                 <>
                 <FaHeart

     className="text-red-500  w-[20px] "
     />
                    <p

     className={` PoppinsRegular text-sm text-red-500  `}>
                  Love
                 </p>
                 </>
                 :
                 <>
                 <AiFillLike

     className="text-textcolor/60  w-[20px] "
     />
                    <p

     className={` PoppinsRegular text-sm text-textcolor/60  `}>
                  Like
                 </p>
                 </>}

</div>
         <div
         className='w-[50%] flex justify-end hover:cursor-pointer '
         onClick={()=>{
          let data = [...blogs]
          data[index].viewmore = !data[index].viewmore
          dispatch({
            type: actionType.STORE_BLOGS,
            payload: data
          })
         }}
         >
            <p className=' PoppinsRegular text-sm text-textcolor/60 hover:text-themeColor'>{item.viewmore ? "View Less" : "View More"}</p>
            </div>
            </div>
          </div>
        ))}

        {blogs.length < 1 &&
        <div className='w-full h-screen flex justify-center items-center'>
        <p className='text-20px text-lightgrey'>No Blogs Available</p>
        </div>}
      </div>
    </div>
  );
}

export default Blogs;